import React from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {FormHelperText} from "@material-ui/core";

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		margin: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: '100%',
		marginBottom: 25
	},
});

class AdornmentPasswordField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: '',
			password: '',
			weight: '',
			weightRange: '',
			showPassword: false,
			isErrorDisplayed: this.props.isErrorDisplayed,
			errorMessage: this.props.errorMessage
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.isErrorDisplayed !== this.props.isErrorDisplayed) {
			this.setState({
				isErrorDisplayed: this.props.isErrorDisplayed,
				errorMessage: this.props.errorMessage
			})
		}
	}

	handleChange = (event) => {
		this.setState({
			password: event.target.value
		}, () => {
			this.props.updatePassword(this.state.password)
		})
	};

	handleClickShowPassword = () => {
		this.setState((prevState) => {
			return {
				showPassword: !prevState.showPassword
			}
		});
	};

	handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	render() {
		const {classes} = this.props
		return (
			<FormControl error={this.state.isErrorDisplayed} className={clsx(classes.textField)} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">{this.props.label}</InputLabel>
				<OutlinedInput
					error={this.props?.error}
					helpertext={this.props?.helperText}

					name={this.props.name}
					id="outlined-adornment-password"
					type={this.state.showPassword ? 'text' : 'password'}

					onChange={this.props.onChange}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={this.handleClickShowPassword}
								onMouseDown={this.handleMouseDownPassword}
								edge="end"
							>
								{this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
							</IconButton>
						</InputAdornment>
					}
					labelWidth={70}
				/>
				<FormHelperText>{this.props?.helperText}</FormHelperText>
			</FormControl>
		);
	}
}

export default withStyles(styles)(AdornmentPasswordField);
