import Adapter from '../Adapter'
import { config } from '../../../config';
export function serialize(data)
{
    return Object.keys(data).reduce(function(a,k){a.push(k+'='+encodeURIComponent(data[k]));return a},[]).join('&');
}

export default class HttpAdapter extends Adapter{

    get(url, headers = {}){

        headers["Content-Type"] = "application/json";
        return fetch(config.BACKEND + url, {
           method: 'GET',
           headers: headers
        })
          .then(res => {
            if(res.status === 401) {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('expired_access');
                localStorage.removeItem('expired_refresh');
                localStorage.removeItem('userInfo');
                window.location.reload();
              }
              return new Promise(resolve => {
                  resolve(res)
              })

          }).catch(res => {
                return new Promise(resolve => {
                    resolve(res)
                })
            })
    }

    post(url, data, headers = {}){
        return fetch(config.BACKEND + url, {
            method: 'POST',
            headers: headers,
            body: data
        })
            .then(res => {
                if (res.status === 401) {
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('expired_access');
                    localStorage.removeItem('expired_refresh');
                    localStorage.removeItem('userInfo');
                    window.location.reload();
                }
                return new Promise(resolve => {
                    resolve(res)
                })
            }).catch(res => {
                return new Promise(resolve => {
                    resolve(res)
                })
            })
    }

    serialize(data)
    {
        return serialize(data);
    }
}
