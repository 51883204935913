import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider, Cookies } from "react-cookie";
import { Provider } from 'react-redux'
import type { RootState } from './store/reduxStore'
import { createStore } from "redux"
import allReducers from "./reducers"

import App from './App';
import './index.css';
import {UserContext} from "./context/UserContext";


let store = createStore(allReducers)

ReactDOM.render(
    <CookiesProvider>
        <Provider store={store}>
            <App />
        </Provider>
    </CookiesProvider>,
  document.getElementById('root')
);
