import {GridFilterInputValue} from "@mui/x-data-grid";

export function secondsToHms(d) {
    d = Number(d);
    let dd = Math.floor(d / 86400);


    d -= dd * 86400;

    let h = Math.floor(d / 3600);

    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);

    let dDisplay = dd > 0 ? dd : ":0";
    let hDisplay = h > 0 ? ":" + h : ":0";
    let mDisplay = m > 0 ? ":" + m : ":0";
    let sDisplay = s > 0 ? ":" + s : ":0";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

// convert milliseconds into MM:SS.ms
export function millisecondsToHms(milliseconds) {

    let seconds = Math.floor(milliseconds / 1000);
    let millseconds = Math.floor(milliseconds % 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

   // return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
    return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}.${millseconds}`
}

export const filterSelect = {
    label: '=',
    value: 'equals',
    getApplyFilterFn: (filterItem) => {
        if (filterItem.value == null || filterItem.value === '') {
            return null;
        }

        return ({ value }) => {
            /*if (typeof value === 'object') {
                return filterItem.value === (value as { value: any; label: string }).value;
            }*/
            return filterItem.value === value;
        };
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: { type: 'singleSelect' },
}

export const filterEquals = {
    label: '=',
    value: 'equals',
    getApplyFilterFn: (filterItem) => {
        if (filterItem.value == null || filterItem.value === '') {
            return null;
        }

        return ({ value }) => {
            /*if (typeof value === 'object') {
                return filterItem.value === (value as { value: any; label: string }).value;
            }*/
            return filterItem.value === value;
        };
    },
    InputComponent: GridFilterInputValue,
    InputComponentProps: { type: 'string' },
}