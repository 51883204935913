import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users";
import About from "../pages/About";
import Settings from "../pages/Settings";
import Colleagues from "../pages/Colleagues";
import Profile from "../pages/Profile";
import PublicMain from "../pages/PublicMain";
import AuthLayout from "../pages/_layouts/auth";
import PublicLayout from "../pages/_layouts/public";
import MainLayout from "../pages/_layouts/main";
import Enroll from "../pages/Enroll";
import Verify from "../pages/Verify";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CookiesPolicy from "../pages/CookiesPolicy";
import UserAgreement from "../pages/UserAgreement";

export default function Routes(props) {
    return (
        <Switch>
            <Route path="/" exact component={props.isSignedIn?Users:PublicMain} isSignedIn={props.isSignedIn} layout={props.isSignedIn?MainLayout:PublicLayout} />
            <Route path="/about" component={About} isPrivate isSignedIn={props.isSignedIn} layout={PublicLayout} />
            <Route path="/register" component={SignUp} isSignedIn={props.isSignedIn} layout={AuthLayout} />
            <Route path="/login" component={SignIn} isSignedIn={props.isSignedIn} layout={AuthLayout} />
            <Route path="/dashboard" component={Dashboard} isPrivate isSignedIn={props.isSignedIn} layout={MainLayout} />
            <Route path="/profile" component={Profile} isPrivate isSignedIn={props.isSignedIn} layout={MainLayout} />
            <Route path="/settings" component={Settings} isPrivate isSignedIn={props.isSignedIn} layout={MainLayout} />
            <Route path="/colleagues" component={Colleagues} isPrivate isSignedIn={props.isSignedIn} layout={MainLayout} />
            <Route path="/enroll" component={Enroll} isPrivate isSignedIn={props.isSignedIn} layout={MainLayout} />
            <Route path="/verify" component={Verify} isPrivate isSignedIn={props.isSignedIn} layout={MainLayout} />
            <Route path="/privacy-policy" component={PrivacyPolicy}  isSignedIn={props.isSignedIn} layout={props.isSignedIn?MainLayout:PublicLayout} />
            <Route path="/cookies-policy" component={CookiesPolicy}  isSignedIn={props.isSignedIn} layout={props.isSignedIn?MainLayout:PublicLayout} />
            <Route path="/user-agreement" component={UserAgreement}  isSignedIn={props.isSignedIn} layout={props.isSignedIn?MainLayout:PublicLayout} />

            <Route path='*' component={Colleagues} layout={MainLayout} />
        </Switch>
    );
}
