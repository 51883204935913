import React, {Component} from "react";
import {UserContext} from "../../context/UserContext";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import AdornmentPasswordField from "./AdornmentPasswordField";
import InputAdornment from '@material-ui/core/InputAdornment';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Container from "@material-ui/core/Container";
import {Trans} from "react-i18next";
import Alert from "@material-ui/lab/Alert/Alert";


// Переписываю тему в src/pages/SignIn/index.js при помощи <ThemeProvider> искать там цвет и форму текстовых полей
const styles = (theme) => ({
	root: {
		minHeight: 'calc(100vh - 113px)',
		position: 'relative',
        '& .MuiOutlinedInput-input' : {
		    padding: 14,
        },
        '& button' : {
            height: 48,
            marginTop: 0
        }
	},
	h2: {
		fontSize: '2rem'
	},
	paper: {
		textAlign: 'center',
		backgroundColor: '#fff',
		margin: '10%',
		padding: '15px 32px 20px',
		borderRadius: 6,
		boxShadow: '-7px 8px 16px 0 rgba(55, 70, 95, 0.07)',
	},
	formContainer: {
		boxShadow: 'none',
		backgroundColor: '#0c5891',
	},
	pHeader: {
		borderBottom: '1px solid #E4E4E4',
		marginBottom: 10,
		fontFamily: 'Inter',
		fontSize: 20,
		lineHeight: 2,
		textAlign: 'center',
		color: '#898A9C'
	},
	textField: {
		marginBottom: 30,
		'&:focus': {
			border: '#339ade solid 1px'
		}
	},
	submit: {
		width: '100%',
		height: 64,
		background: '#1565D8',
		borderRadius: 6,
		marginTop: 20,
		marginBottom: 20
	},
	buttonregist: {
		cursor: 'pointer',
		color: '#1565D8'
	}
});

const validationMessages = {
	emptyLogin: 'Введите логин, чтобы авторизоваться!',
	emptyPassword: 'Введите пароль, чтобы авторизоваться!',
	wrongLoginPassword: 'Вы ввели неправильные логин / пароль!'
}

class LoginForm extends Component {

	state = {
		errorUpload: null,
		errorRegistr: [],
		error: {
			isErrorDisplayed: false,
			errorMessage: ''
		},
		userInfo: {
			login: "",
			firstname: "",
			lastname: "",
			middlename: "",
			avatar: "",
		},
	};

	login = '';
	password = '';

	constructor(props) {
		super(props);
		this.state = {
			errorRegistr: [],
			error: {
				isErrorDisplayed: false,
				errorMessage: ''
			},
		}
	}

	componentDidMount() {
		//console.log()
	}

	updateLogin = (e) => {
		this.login = e.target.value;
		this.setState({
			error: {
				isErrorDisplayed: false,
				errorMessage: ''
			}
		})
	}

	updatePassword = (e) => {
		this.password = e.target.value;
		this.setState({
			error: {
				isErrorDisplayed: false,
				errorMessage: ''
			}
		})
	}

	handleSubmit = (e) => {
		e.preventDefault();
	};

	render() {
		const {classes} = this.props;

		const theme = createTheme({
			palette: {
				primary: {
					main: "#00978C",
				},
			},

			overrides: {
				MuiOutlinedInput: {
					root: {

					}
				},
			}
		});

		return (
			<ThemeProvider theme={theme}>
                <Container>
                    <Grid container component="main" className={classes.root}>
                        <CssBaseline/>
                        <Grid item xs={"auto"} md={3} lg={3} />
                        <Grid item xs={12} sm={12} md={6} lg={6} component={Paper} elevation={6} square className={classes.formContainer}>
                            <div className={"loginform " + classes.paper}>
                                <Typography component="h3" variant="h3" className={classes.header} >
									<Trans i18nKey={"SignIn"} >{"SignIn"}</Trans>
                                </Typography>
								<Typography component="p" className={classes.pHeader}>
									<Trans i18nKey={"LoginIntoYourAccount"} >{"Login into your account"}</Trans>
                                </Typography>

								{this.state.errorUpload ? <Alert severity="error" style={{marginBottom: 25}} >{this.state.errorUpload}</Alert>:''}

								<UserContext.Consumer>
									{
										({ user, setAuthUser, signIn, isUserSignedIn, signOut }) => user.isSignIn()
                                        ? <h1>Is Sign In <h2 onClick={signOut}>Exit</h2></h1>
                                        : (<form
                                                className={classes.form}
                                                noValidate
                                                onSubmit={(e) => {
                                                    e.preventDefault();
													const data = signIn(this.login, this.password);
													// console.log("loginFrom, ", data);
													if (data && data.statusCode > 300) {
														this.setState({errorUpload: data.message});
													}
                                                    isUserSignedIn(this.login, this.password)
                                                       /* .then((result) => {
                                                            console.log("Signed, result: ", result);
                                                            if (result.message !== undefined) {
                                                                this.setState({
                                                                    error: {
                                                                        isErrorDisplayed: true,
                                                                        errorMessage: validationMessages.wrongLoginPassword
                                                                    }
                                                                })
                                                            }
                                                        });*/
                                                    if (this.login.length === 0) {
                                                        this.setState((prevState) => {
                                                            return {
                                                                error: {
                                                                    isErrorDisplayed: true,
                                                                    errorMessage: `${prevState.error.errorMessage} ${validationMessages.emptyLogin}\n`
                                                                }
                                                            }
                                                        })
                                                    }
                                                    if (this.password.length === 0) {
                                                        this.setState((prevState) => {
                                                            return {
                                                                error: {
                                                                    isErrorDisplayed: true,
                                                                    errorMessage: `${prevState.error.errorMessage} ${validationMessages.emptyPassword}\n`
                                                                }
                                                            }
                                                        })
                                                    }
                                                }}
                                            >
                                                <TextField
                                                    error={this.state.error.isErrorDisplayed}
													helpertext={"Ваша почта"}
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    id="login"
                                                    label={<Trans i18nKey={"Login"} >{"Login"}</Trans>}
                                                    name="login"
                                                    autoComplete="Ваша почта"
                                                    autoFocus
                                                    className={classes.textField}
                                                    onChange={this.updateLogin}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end"><MailOutlineIcon /></InputAdornment>,
                                                    }}
                                                />
                                                <AdornmentPasswordField
                                                    name="password"
													label={<Trans i18nKey={"Password"} >{"Password"}</Trans>}
                                                    onChange={this.updatePassword}
                                                    isErrorDisplayed={this.state.error.isErrorDisplayed}
                                                    errorMessage={this.state.error.errorMessage}
                                                />
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.submit}
                                                >
													<Trans i18nKey={"SignIn"} >{"SignIn"}</Trans>
                                                </Button>
												<div style={{textAlign: 'center'}}>
													<Link
														type="button"
														variant="contained"
														className={classes.buttonregist}
														to="/register"
													>
														<Trans i18nKey={"LinkRegistr"} >{"Don't have an account? Register now"}</Trans>
													</Link>
												</div>
                                            </form>)
                                    }
                                </UserContext.Consumer>
                            </div>
                        </Grid>
                        <Grid item xs={"auto"} md={3} lg={3} />
                    </Grid>
                </Container>
			</ThemeProvider>
		);
	}
}

export default withStyles(styles, {withTheme: true})(LoginForm);
