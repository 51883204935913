import React, { Component } from 'react';
import differenceInYears from "date-fns/differenceInYears";
import DialogContent from "@material-ui/core/DialogContent";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import TextFieldValidator from "../TextFieldValidator";
import userStore from "../../../store/UserStore";
import {withSnackbar} from "notistack";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker} from '@material-ui/pickers';
import TextField from "@material-ui/core/TextField";
import {createTheme, withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import AdornmentPasswordField from "../../LoginForm/AdornmentPasswordField";
import Grid from "@material-ui/core/Grid";
import DoneIcon from "@material-ui/core/SvgIcon/SvgIcon";
import SaveIcon from "@material-ui/icons/Save";
import Container from "@material-ui/core/Container";
import apiStore from "../../../store/ApiStore";
import {Trans} from "react-i18next";


const styles = (theme) => ({
	root: {
		position: 'relative'
	},
	schoolnumber: {
		display: 'block',
		border: 'solid #c4c4c4 1px',
		borderRadius: 4,
		padding: '18.5px 14px',
		width: '100%',
		'& input:first-child': {
			float: 'left',
			width: '48%',
			borderRight: 'solid #c4c4c4 1px',
		},
		'& input:last-child': {
			float: 'right',
			width: '48%'
		}
	},
	birthday: {
		border: 'solid #c4c4c4 1px',
		borderRadius: 4,
		paddingLeft: 10,
		marginBottom: 25,
		width: '100%',
		'&>div':{
			margin: 0,

		},
		'& input': {
			width: '100%'
		},
		'& .MuiInput-formControl': {
			margin: '9px 0',
			width: '100%'
		},
		'& .MuiInput-underline:after':{
			border:0
		},
		'& .MuiInput-underline:before':{
			border:0
		},
		'& .MuiInput-underline:hover:after':{
			border:0
		}
	},
	paper: {

	},
	formContainer: {
		boxShadow: 'none'
	},
	header: {
		textAlign: 'left',
		marginBottom: 40
	},
	pHeader: {
		borderBottom: '1px solid #E4E4E4',
		marginBottom: 20,
		fontFamily: 'Inter',
		fontSize: 20,
		lineHeight: 4,
		textAlign: 'left',
		color: '#898A9C'
	},
	pFooter:{

	},
	textField: {
		marginBottom: 40
	},
	submit: {
		width: '100%',
		height: 64,
		background: '#1565D8',
		borderRadius: 6,
		marginTop: 40,
		marginBottom: 40
	},
	buttonregist: {
		cursor: 'pointer',
		color: '#1565D8'
	},
	form: {
		marginBottom: 40
	}
});

class SettingsForm extends Component {
	state = {
		errorRegistr: [],
		confirmIsSuccess: null,
		info: {
			firstname: null,
			lastname: null,
			country: null,
			email: null,
			organization: null,
			fieldactivity: null,
			workposition: null,
			password: null,
			passwordrepeat: null,
			login: null,
			phone: null
		},
		errors: {
			firstname: null,
			lastname: null,
			country: null,
			email: null,
			organization: null,
			fieldactivity: null,
			workposition: null,
			password: null,
			passwordrepeat: null,
			login: null,
			phone: null
		}
	}


	handleSubmit = (e) => {
		e.preventDefault();
		let regFormData = {
			data: {
				firstname: this.state.firstname,
				lastname: this.state.lastname,
				country: this.state.country,
				email: this.state.email,
				organization: this.state.organization,
				fieldactivity: this.state.fieldactivity,
				workposition: this.state.workposition,
				phone: this.state.phone,
			}
		}
		if(this.state?.password && this.state?.passwordrepeat && this.state.password === this.state.passwordrepeat){
			regFormData.data.password = this.state.password;
			regFormData.data.passwordrepeat = this.state.passwordrepeat;
		}
		/**
		 *
		 password: this.state.password,
		 passwordrepeat: this.state.passwordrepeat,
		 */
		console.log(regFormData);
		userStore.updateManager(regFormData).then(data => console.log(data));
	}

	textChange = (event) => {
		let val = {};
		val[event.target.name] = event.target.value;
		console.log(val);
		this.setState(val);
	}

	formValid = (event) => {
		let valid = true;
		let checkInput = [
			'fio',
			'phone',
			'email',
			'password',
		];
		for(let key of checkInput){
			if(!this.state[key]){
				valid = false;
			}
		}
		console.log(this.state);
		console.log(valid);
		return valid;
	}

	componentDidMount() {
		let info = userStore.getUserInfo()['manager'];
		info['email'] = userStore.getUserInfo()['email'];
		info['phone'] = userStore.getUserInfo()['phone'];
		//console.log("componentDidMount, info: ", info);
		this.setState(info);
	}

	render() {
		const {classes} = this.props;
		return (
			<form  onSubmit={this.handleSubmit} className={classes.form} >
				<div className={classes.paper} >
					<Container>
						<Grid item xs={12} sm={12} md={12} lg={12} >
							<Typography component="h2" variant="h4" className={classes.header} color={""}>
								<Trans i18nKey={"Settings"} >{"Settings"}</Trans>
							</Typography>
						</Grid>
					</Container>

					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={6} lg={4} >
							<div style={{marginBottom: 15}}><b>Имя*</b></div>
							<TextField
								error={this.state.errorRegistr['firstname']?true:false}
								helperText={this.state.errorRegistr['firstname']}

								required
								onChange={this.textChange}
								value={this.state.firstname}
								style={{marginBottom: 25}}
								variant="outlined"
								id = "firstname"
								name = "firstname"
								label = "Имя"
								type = "text"
								fullWidth
							/>

							<div style={{marginBottom: 15}}><b>Страна</b></div>
							<TextField
								error={this.state.errorRegistr['country']?true:false}
								helperText={this.state.errorRegistr['country']}


								onChange={this.textChange}
								value={this.state.country}
								style={{marginBottom: 25}}
								variant="outlined"
								id = "country"
								name = "country"
								label = "Страна"
								type = "text"
								fullWidth
							/>

							<div style={{marginBottom: 15}}><b>Рабочий Email*</b></div>
							<TextField
								error={this.state.errorRegistr['email']?true:false}
								helperText={this.state.errorRegistr['email']}

								required
								onChange={this.textChange}
								value={this.state.email}
								style={{marginBottom: 25}}
								variant="outlined"
								id = "email"
								name = "email"
								label = "E-mail"
								type = "text"
								fullWidth
							/>


							<div style={{marginBottom: 15}}><b>Сфера деятельности*</b></div>
							<TextField
								error={this.state.errorRegistr['fieldactivity']?true:false}
								helperText={this.state.errorRegistr['fieldactivity']}

								required
								onChange={this.textChange}
								value={this.state.fieldactivity}
								style={{marginBottom: 25}}
								variant="outlined"
								id = "fieldactivity"
								name = "fieldactivity"
								label = " например, банковский сектор"
								type = "text"
								fullWidth
							/>

							<div style={{marginBottom: 15}}><b>Должность*</b></div>
							<TextField
								error={this.state.errorRegistr['workposition']?true:false}
								helperText={this.state.errorRegistr['workposition']}

								required
								onChange={this.textChange}
								value={this.state.workposition}
								style={{marginBottom: 25}}
								variant="outlined"
								id = "workposition"
								name = "workposition"
								label = "Должность"
								type = "text"
								fullWidth
							/>
						</Grid>
						<Grid item xs={"auto"} sm={"auto"} md={2} lg={2} >
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={4} >
							<div style={{marginBottom: 15}}><b>Фамилия*</b></div>
							<TextField
								error={this.state.errorRegistr['lastname']?true:false}
								helperText={this.state.errorRegistr['lastname']}

								required
								onChange={this.textChange}
								value={this.state.lastname}
								style={{marginBottom: 25}}
								variant="outlined"
								id = "lastname"
								name = "lastname"
								label = "Фамилия"
								type = "text"
								fullWidth
							/>

							<div style={{marginBottom: 15}}><b>Телефон*</b></div>
							<TextField
								error={this.state.errorRegistr['phone']?true:false}
								helperText={this.state.errorRegistr['phone']}

								required
								onChange={this.textChange}
								value={this.state.phone}
								style={{marginBottom: 25}}
								variant="outlined"
								id = "phone"
								name = "phone"
								label = "Телефон"
								type = "text"
								fullWidth
							/>

							<div style={{marginBottom: 15}}><b>Место работы*</b></div>
							<TextField
								error={this.state.errorRegistr['organization']?true:false}
								helperText={this.state.errorRegistr['organization']}

								required
								onChange={this.textChange}
								value={this.state.organization}
								style={{marginBottom: 25}}
								variant="outlined"
								id = "organization"
								name = "organization"
								label = "Место работы"
								type = "text"
								fullWidth
							/>


							<div style={{marginBottom: 15}}><b>Пароль*</b></div>
							<AdornmentPasswordField
								error={this.state.errorRegistr['password']?true:false}
								helperText={this.state.errorRegistr['password']}

								required
								name="password"
								onChange={this.textChange}
								style={{width:'100%', marginBottom: 25}}
							/>

							<div style={{marginBottom: 15}}><b>Повторите пароль*</b></div>
							<AdornmentPasswordField
								error={this.state.errorRegistr['passwordrepeat']?true:false}
								helperText={this.state.errorRegistr['passwordrepeat']}

								required
								name="passwordrepeat"
								onChange={this.textChange}
								style={{width:'100%'}}
							/>
						</Grid>
						<Grid item xs={12} sm={12} md={6} lg={12}   >
							<center><Button type="submit" variant="contained" color="default" size="large" endIcon={<SaveIcon />} >Сохранить </Button></center>
						</Grid>
					</Grid>
				</div>
			</form>
		);
	}
}


export default withStyles(styles, {withTheme: true})(SettingsForm);
