import React from "react";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";
import engLocale from "date-fns/locale/en-US";
import { alpha } from '@material-ui/core/styles';

const DatePickerRange = (props) => {
	const [selectedDate, setSelectedDate] = React.useState(new Date(props.defaultValue));
	const [clabel] = React.useState(props.label ? props.label : "");
	const [language] = React.useState(props.language ? props.language : "en");


	const handleDateChange = (date) => {
		setSelectedDate(date);
		//console.log(date)
		if (date) {
			props.updateValue(date);
		}
	};
	// console.log("language", language);

	return (
		<MuiPickersUtilsProvider
			utils={DateFnsUtils}
			locale={ language === 'ru' ? ruLocale : engLocale}
		>
			<DatePicker
				fullWidth
				format="yyyy-MM-dd"
				id="date-picker-inline"
				name="selectDateRange"
				label={clabel}
				value={selectedDate}
				onChange={handleDateChange}
				variant="standard"
			/>
		</MuiPickersUtilsProvider>
	)
}

export default DatePickerRange
