import React, {Component, Fragment} from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y  } from 'swiper';
import 'swiper/swiper.min.css';
import {Button, withStyles} from "@material-ui/core";
import {useStyles} from "./styles";
import {Link} from "react-router-dom";
import {Trans} from "react-i18next";

SwiperCore.use([Navigation]);

class PopupCookies extends Component{


    render() {
        const {classes} = this.props;
        return <div>

            <div className={classes.popupCookies} >
                <Fragment>
                    <div style={{marginLeft:5, marginTop:5, marginRight:5}}>
                        <Trans i18nKey={"CookiesPolicyInfo"} >{"CookiesPolicyInfo"}</Trans>
                        <a href='/cookies-policy'>"<Trans i18nKey={"CookiesPolicy"} >{"CookiesPolicy"}</Trans>"</a>
                    </div>
                </Fragment>
                <form onSubmit={(event) => {
                    event.preventDefault();
                }}>
                    <div style={{textAlign: 'right', marginRight:10}}>
                        <Button type="submit" variant="contained" color="primary" size="large"
                                onClick={(event) => this.props?.handlerCookie && this.props.handlerCookie()} >
                            ACCEPT
                        </Button>
                    </div>
                </form>
            </div>

        </div>;
    };
}


export default withStyles(useStyles, {withTheme: true})(PopupCookies);
