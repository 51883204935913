import React,{Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {useStyles} from "./styles";
import {UserContext} from "../../context/UserContext";
import Rus from "./Rus";
import Eng from "./Eng";




class PublicMain extends Component{
    state = {
        step: 1
    }

    updateStep = (step) => {
        this.setState({step: step});
    }

    renderPage = (lang) => {
        switch (lang) {
            case 'ru':
                return <Rus />;
            case 'en':
                return <Eng />;
            default:
                return <Eng />;
        }
    }

    render() {
        const {classes} = this.props;

        return <UserContext.Consumer>
            {({user, setAuthUser, signOut, showSettings}) => this.renderPage(user.getLang())}
        </UserContext.Consumer>;
    };

}

export default withStyles(useStyles, {withTheme: true})(PublicMain);
