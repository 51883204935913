import React from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
//import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import {FormHelperText} from "@material-ui/core";

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		margin: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: '100%',
		marginBottom: 5,
		'& .MuiOutlinedInput-input' : {
			padding: '10.0px 14px'
		},
	},
});

class SearcherField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: '',
			inputText: this.props.value ? this.props.value : '',
			weight: '',
			weightRange: '',
			isErrorDisplayed: this.props.isErrorDisplayed,
			errorMessage: this.props.errorMessage
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.isErrorDisplayed !== this.props.isErrorDisplayed) {
			this.setState({
				isErrorDisplayed: this.props.isErrorDisplayed,
				errorMessage: this.props.errorMessage
			})
		}
		// At on change
		if (prevProps.value !== this.props.value) {
			this.setState({
				inputText: this.props.value ? this.props.value : ''
			});
		}
	}

	handleOnChange = (event) => {
		this.setState({
			inputText: event.target.value
		}, () => {
			this.props.onChange(event)
		})
	};

	handleReserInputText = (event) => {
		// console.log('handleClickClearText: ', this.state.inputText);
		this.setState({
			inputText: ''
		});
		this.props.onChange(event)
	};

	render() {
		const {classes} = this.props;
		return (
			<FormControl error={this.state.isErrorDisplayed}
						 className={clsx(classes.textField)}
						 variant="outlined"
						 style={this.props?.style}
			>
				<InputLabel htmlFor="outlined-adornment-input">{this.props.label} </InputLabel>
				<OutlinedInput
					error={this.props?.error}
					helpertext={this.props?.helperText}
					value={this.state.inputText}
					name={this.props.name}
					id="outlined-adornment-input"
					type={'text'}
					onChange={this.handleOnChange}
					onKeyDown={this.props.onKeyDown}
					startAdornment={
						<InputAdornment position="start">
							<IconButton
								aria-label="toggle input"
								onClick={this.props.onClick}
								edge="start"
							>
								<SearchIcon/>
							</IconButton>
						</InputAdornment>
					}
					endAdornment={
						this.state.inputText && this.state.inputText.length > 0 ? (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle input"
								onClick={this.handleReserInputText}
								edge="end"
							>
								<ClearIcon/>
							</IconButton>
						</InputAdornment>
						) : (<br/>)
					}
					labelWidth={70}
				/>
				<FormHelperText>{this.props?.helperText}</FormHelperText>
			</FormControl>
		);
	}
}

export default withStyles(styles)(SearcherField);
