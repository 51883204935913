import styled from "styled-components";
import right from '../../../static/img/icons-containers-36-px.png';
import left from '../../../static/img/icons-containers-36-px_2.png';


export const Wrapper = styled.div`
  background: #FFF;
  min-height: 100vh;
`;

export  const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
        height: "calc(100vh - 230px)",
    },
    wrapperSelectLang: {
      textAlign: 'left'
    },
    buttonenter: {
        cursor: 'pointer',
        boxShadow: 'none',
        marginTop: 6,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        backgroundColor:'#2277b7',
        textTransform: 'none',
    },
    selectlanguage: {
        width: 120,
        lineHeight: 1,
        margin: '0 24px 0px 20px',
        padding: '5px 3px',
        borderRadius: 6,
        boxShadow: 'none',
        border: 'solid 1px #dfe3e5',
        '& .MuiInputBase-input': {
            padding: 0
        },
        '&:before' : {
            borderBottom:0
        },
        '&:hover:before' : {
            borderBottom:0
        },
        '& .MuiSelect-root': {
            minHeight: 'auto',
            lineHeight: '25px',
            height: '25px',
            verticalAlign: 'middle'
        },
        '& b': {
        },
        '& :after': {
            border: 'solid 1px #dfe3e5',
        }
    },
    footer:{
        // width: '100%',
        backgroundColor: '#2277b7',
        color: 'white',
        padding: '16px 5px',
        '& a': {
            color: 'white',
            textDecoration: 'underline'
        },
        '& a:hover': {
            textDecoration: 'none'
        }
    },
    topMenu: {
        paddingTop: 0,

        minHeight: '56px',
        fontFamily: 'Roboto',
        fontSize: 16,
        /*borderBottom: 'solid 1px #dfe3e5',*/
        lineHeight: '56px',
        '& a' : {
            fontFamily: 'Roboto',
            fontSize: 15,
            lineHeight: '14px',
            fontWeight: 400,
            padding: '18px 10px'
        },
        '& a:active': {
            borderBottom: 'solid 2px #2277b7'
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#2277b7'
        },
        '& .Mui-selected': {
            color: '#304156',
            paddingBottom: 8,
        }
    },
    activeLink: {
        borderBottom: 'solid 2px #2277b7 !important'
    }
});
