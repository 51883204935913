import React, {Component} from "react";
import { useStyles } from './styles';
import {withStyles} from "@material-ui/core";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {ruRU} from '@material-ui/core/locale';
import { createTheme, ThemeProvider, styled } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import Client from '../../store/Model/Client';
import Audio from '../../components/Audio';
import apiStore from "../../store/ApiStore";
import Grid from "@material-ui/core/Grid";
import AddPhotoDropzoneArea from "../shared/AddPhotoDropzoneArea";
import moment from "moment";
import 'moment/locale/ru';
import {NotificationManager} from "react-notifications";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CreateIcon from '@material-ui/icons/Create';
import Switch from "@material-ui/core/Switch/Switch";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from "@material-ui/core";
import $ from 'jquery';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from "prop-types";
import Select from '@material-ui/core/Select';
import {millisecondsToHms} from '../../lib/helper';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import DialogUpload from '../../components/Dialog/DialogUpload';
import DialogThresholds from '../../components/Dialog/DialogThresholds';
import DialogComment from '../../components/Dialog/DialogComment';
import CommentIcon from '@material-ui/icons/Comment';
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@material-ui/core/TablePagination';
import {Trans} from "react-i18next";
import InputBase from '@material-ui/core/InputBase';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import userStore from "../../store/UserStore";
import i18n from "../../services/i18n";
import {config} from "../../config";
import PrintIcon from '@mui/icons-material/Print';
moment.locale('ru');


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component="div">{children}</Typography>
				</Box>
			)}
		</div>
	);
}
function a11yProps(index) {
	return {
		id: `user-tab-${index}`,
		'aria-controls': `user-tabpanel-${index}`,
	};
}


const columns = [
	{ field: "user_u", hide: true },
	{ field: "status", headerName: <Trans i18nKey={"Status"} >{"Status"}</Trans>, width: 100, GridColType: 'boolean', renderCell: (params) => {
		   // console.log("params_row: ", params.row);
			switch (params.row.finished) {
				case '0':
					return (
						<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
							<Chip index={params.row.id} className={'active'} style={{width: 90}}
								  avatar={<Avatar><ClearRoundedIcon /></Avatar>}
								  variant="outlined" label={<Trans i18nKey={"Accepted"} >{"Accepted"}</Trans>} />
						</div>
					);
				case '1':
					return (
						<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
							<Chip index={params.row.id} className={'deactive'} style={{width: 90}}
								  avatar={<Avatar><ClearRoundedIcon /></Avatar>}
								  variant="outlined" label={<Trans i18nKey={"Error"} >{"Error"}</Trans>} />
						</div>
					);
				default:
					return '';
			}
		},
		filter: false,
		sort: false
	},
	{ field: "link", headerName: <Trans i18nKey={"FileName"} >{"FileName"}</Trans>, width: 230,  renderCell: (params) => {
			return <Audio
				style={{width: 230, position: 'absolute', zIndex: 1, marginLeft: -10}}
				controls
				type={"enroll"}
				file={params.row} />;
		},
		filter: false,
		sort: false
	},
	{ field: "study_time", headerName: <Trans i18nKey={"Date"} >{"Date"}</Trans>, width: 150, renderCell: (params) => {
			return moment(params.row.study_time).format("YYYY-MM-DD HH:mm:ss");
		},
		filter: false,
		sort: true
	},
	{ field: "duration", headerName: <Trans i18nKey={"Duration"} >{"Duration"}</Trans>, width: 110, renderCell: (params) => {
			return millisecondsToHms(params.row.duration);
		},
		filter: false,
		sort: false
	},
	{ field: "eer", headerName: "EER", width: 70, renderCell: (params) => {
			return params.row.eer;
		},
		filter: false,
		sort: false
	},
	{ field: "eerx", headerName: "EERx", width: 70, renderCell: (params) => {
			return params.row.eerx;
		},
		filter: false,
		sort: false
	},
	{ field: "signal_snr", headerName: <Trans i18nKey={"SNR_DB"} >{"SNR_DB"}</Trans>, width: 80, renderCell: (params) => {
			return params.row.signal_snr;
		},
		filter: false,
		sort: false
	}
];



const theme = createTheme(
	{
		palette: {
			primary: { main: '#1976d2' },
		},
		root: {
			border:0
		},
		'& .MuiDataGrid-row:nth-child(odd)': {
			backgroundColor: 'white'
		},
		cell: {
			border:0
		},
		audio: {
			width: '100%',
			position: 'absolute',
			zIndex: 1,
			marginLeft: -10
		}
	},
	ruRU,
);


const BootstrapInput = withStyles((theme) => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
}))(InputBase);

const GreenSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 36,
	height: 16,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 0,
		transitionDuration: '300ms',
		transform: 'translateX(20px)',
		'&.Mui-checked': {
			transform: 'translateX(0px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '1px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 16,
		height: 16,
		borderRadius: 16,
		boxShadow: 'none'
	},
	'& .MuiSwitch-track': {
		borderRadius: 16,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));

class UserProfile extends Component {
	columnsVerify = [
		{ field: "user_u", hide: true },
		{ field: "status", headerName: <Trans i18nKey={"Status"} >{"Status"}</Trans>, width: 100, GridColType: 'boolean', renderCell: (params) => {
				switch (params.row.result) {
					case 'reject':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
								<Chip index={params.row.id} className={'deactive'} style={{width: 90}}
									  avatar={<Avatar><ClearRoundedIcon /></Avatar>}
									  variant="outlined" label={<Trans i18nKey={"Rejected"} >{"Rejected"}</Trans>} />
							</div>
						);
					case 'accept':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
								<Chip index={params.row.id} className={'active'} style={{width: 90}}
									  avatar={<Avatar><ClearRoundedIcon /></Avatar>}
									  variant="outlined" label={<Trans i18nKey={"Accepted"} >{"Accepted"}</Trans>} />
							</div>
						);
					case null:
					case 'error':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
								<Chip index={params.row.id} className={'deactive'} style={{width: 90}}
									  avatar={<Avatar><ClearRoundedIcon /></Avatar>}
									  variant="outlined" label={<Trans i18nKey={"Error"} >{"Error"}</Trans>} />
							</div>
						);
					default:
						return '';
				}
			},
			filter: false,
			sort: false
		},
		{ field: "link", headerName: <Trans i18nKey={"FileName"} >{"FileName"}</Trans>, width: 230,
			renderCell: (params) => {
				return <Audio
					style={{width: 230, position: 'absolute', zIndex: 1, marginLeft: -10}}
					controls
					type={"verify"}
					file={params.row} />;
			},
			filter: false,
			sort: false
		},
        { field: "study_time", headerName: <Trans i18nKey={"Date"} >{"Date"}</Trans>, width: 150, renderCell: (params) => {
                return moment(params.row.study_time).format("YYYY-MM-DD HH:mm:ss");
            },
            filter: false,
            sort: true
        },
		{ field: "duration", headerName: <Trans i18nKey={"Duration"} >{"Duration"}</Trans>, width: 110, renderCell: (params) => {
				return millisecondsToHms(params.row.duration);
			},
			filter: false,
			sort: false
		},
		{ field: "far", headerName: <Trans i18nKey={"FA"} >{"FA"}</Trans>, width: 80,
			filter: false,
			sort: false
        },
		{ field: "frr", headerName: <Trans i18nKey={"FR"} >{"FR"}</Trans>, width: 80,
			filter: false,
			sort: false
        },
		{ field: "signal_snr", headerName: <Trans i18nKey={"SNR_DB"} >{"SNR_DB"}</Trans>, width: 80, renderCell: (params) => {
				return params.row.signal_snr;
			},
			filter: false,
			sort: false
		},
		{ field: "comments", headerName: <Trans i18nKey={"Comment"} >{"Comment"}</Trans>, width: 100, renderCell: (params) => {
				let wav_u = params.row.uid;
				return <>
					<DialogComment isOpen={this.state.isOpenDialogComment} errorUpload={this.state.errorComment} acceptedFiles={[".wav"]} comments={params.row.comments} handleClose={(event) => this.setState({isOpenDialogComment: false})}
								   handleSave = {
									   (data) => {
										   apiStore.updateVerifyComment(wav_u, data.comments).then(
											   data => {
												   if(data.statusCode === 201){
													   this.setState({isOpenDialogComment: false})
													   NotificationManager.success(`Комментарий сохранен!`);
												   } else {
													   let message = data.message.pop();
													   this.setState({errorComment: message});
													   NotificationManager.success(message, <Trans i18nKey={"Error"} >{"Error"}</Trans>);
												   }
											   }
										   )
									   }
								   }
					/>
					<CommentIcon title={params.row.comments ? params.row.comments : ''} onClick={event => this.setState({isOpenDialogComment: true})}/>
				</>;
			},
			filter: false,
			sort: false
		}
	];
	state = {
		tabIndex: 0
	};

	login = '';
	password = '';

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			rows: [],
			selectRow: null,
			totalRows: 10,
			rowsPerPageOptions: [5, 10, 20, 50, 100],
			pageSize: 10,
			page: 1,
			arClients: [],
			user: null,
			tabIndex:0,
			update:null,
			isOpenDialog: false,
			isOpenDialogThresholds: false,
			isOpenDialogStamp: false,
			isOpenDialogComment: false,
			errorUpload: null,
			selectedEnrol: [],
			selectedVerify: [],
			errorComment: null,
			totalRowsEnroll: 2,
			pageSizeEnroll: 10,
			pageEnroll: 1,
			verify: [],
			totalRowsVerify: 0,
			enrol: [],
			enrollLoad: false,
			verifyLoad: false,
		}
	}
	componentWillReceiveProps(props){
		this.setState({user: props.user, thresholds: props.thresholds, enrol: props.enrol, verify: props.verify,totalRowsEnroll: props.totalRowsEnroll, totalRowsVerify: props.totalRowsVerify});
	}
	componentDidMount() {
		this.setState({user: this.props.user, thresholds: this.props.thresholds, enrol: this.props.enrol, verify: this.props.verify,totalRowsEnroll: this.props.totalRowsEnroll, totalRowsVerify: this.props.totalRowsVerify});
	}
	handleChange = (event) => {
		this.setState({update: Date.now()});
		apiStore.changeClientStatus(this.state.user.id, event.target.checked).then(async (data) => {
			let uid = this.state.user.id;
			this.props.reload(uid);
		})
	}

	handleCloseDialog = () => {
		this.setState({isOpenDialog: false});
	}

	handleSaveDialog = (event) => {
		event.preventDefault();
		//console.log($(event.target).serializeArray());
		this.props.update(this.state.user.id,$(event.target).serializeArray());
		this.setState({isOpenDialog: false});
	}

	loadListEnrollment = async () => {
		let enrol = [];
		let totalRowsEnroll = 0;
		this.setState({enrollLoad: true}, () => {
			apiStore.getVoiceStamp(this.state.user.id, (this.state.pageEnroll - (this.state.pageEnroll>0?1:0)), this.state.pageSizeEnroll).then( data => this.setState({enrollLoad : false, enrol : data.data, totalRowsEnroll : data.meta.quantity*1}));
		});
	}

	loadListVerify = async () => {
		let list = [];
		let totalRows = 0;
		this.setState({enrollLoad: true}, () => {
			apiStore.getVoiceVerifys(this.state.user.id, (this.state.page - (this.state.page>0?1:0) ), this.state.pageSize).then( data => this.setState({verifyLoad : false, verify : data.data, totalRowsVerify : data.meta.quantity*1}));
		});
	}

	customPagination = () => {
		return (<>
				<div style={{float: 'left'}} >
					<div  >
						<Pagination
							color="primary"
							variant="outlined"
							shape="rounded"
							count={Math.ceil(this.state.totalRowsEnroll / this.state.pageSizeEnroll)}
							page={this.state.pageEnroll}
							onChange={(event, value) => {
								this.setState({pageEnroll: value }, this.loadListEnrollment)
							}}

						/>
					</div>
					<div style={{marginTop: '10px'}} >
						({(this.state.pageEnroll-1) * this.state.pageSizeEnroll}-{this.state.pageEnroll*this.state.pageSizeEnroll < this.state.totalRowsEnroll?(this.state.pageEnroll*this.state.pageSizeEnroll):this.state.totalRowsEnroll}) / {this.state.totalRowsEnroll}
					</div>
				</div>
				<div>
					<div style={{color: '#828d99', float:'left', marginRight: 10}}><Trans i18nKey={"NumberRecords"} >{"NumberRecords"}</Trans><br/>
						<Trans i18nKey={"OnPage"} >{"OnPage"}</Trans>:</div>
					<Select
						key="NumberRecordsEnroll"
						style={{}}
						value={this.state.pageSizeEnroll}
						onChange={event=>{
							// console.log(event.target.value);
							this.setState({page: 0, pageSizeEnroll: event.target.value}, this.loadListEnrollment)
						}}
						input={<BootstrapInput />}
					>
						{this.state.rowsPerPageOptions.map(val=><MenuItem value={val} key={"NumberRecordsEnroll" + val}>{val}</MenuItem>)}
					</Select>
				</div>
			</>
		);
	}

	customPagination2 = () => {

		return (<>
				<div style={{float: 'left'}} >
					<div  >
						<Pagination
							color="primary"
							variant="outlined"
							shape="rounded"
							count={Math.ceil(this.state.totalRowsVerify / this.state.pageSize)}
							page={this.state.page}
							onChange={(event, value) => {
								this.setState({page: value}, this.loadListVerify)
							}}

						/>
					</div>
					<div style={{marginTop: '10px'}} >
						({(this.state.page-1) * this.state.pageSize}-{this.state.page*this.state.pageSize < this.state.totalRowsVerify?(this.state.page*this.state.pageSize):this.state.totalRowsVerify}) / {this.state.totalRowsVerify}
					</div>
				</div>
				<div>
					<div style={{color: '#828d99', float:'left', marginRight: 10}}><Trans i18nKey={"NumberRecords"} >{"NumberRecords"}</Trans><br/>
						<Trans i18nKey={"OnPage"} >{"OnPage"}</Trans>:</div>
					<Select
						key="NumberRecordsVerify"
						style={{}}
						value={this.state.pageSize}
						onChange={event=>{
							// console.log(event.target.value);
							this.setState({page: 1, pageSize: event.target.value}, this.loadListVerify)
						}}
						input={<BootstrapInput />}
					>
						{this.state.rowsPerPageOptions.map(val=><MenuItem value={val}  key={"NumberRecordsVerify" + val} >{val}</MenuItem>)}
					</Select>
				</div>
			</>
		);
	}

	renderFormUpdate = () => {
		const {classes} = this.props;
		return <Dialog
			open={this.state.isOpenDialog}
			onClose={this.handleCloseDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth={'md'}
		>
			<DialogTitle id="alert-dialog-title">
				<Trans i18nKey={"EditingClient"} >{"EditingClient"}</Trans>
			</DialogTitle>
			<form className={classes.form} noValidate onSubmit={this.handleSaveDialog}>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
						</DialogContentText>
								<Grid container  spacing={2} >
									<Grid item xs={12} md={12} lg={5} >
										<AddPhotoDropzoneArea
											avatar={this.state.user.info.avatarUser}
											acceptedFiles={["image/*"]}
											updateValue={(file) => {
												if (file.length !== 0) {
													apiStore.addAvatar(this.state.user?.id, file[0] )
														.then((res) => {
															if (res.ok) {
																this.props.update(this.state.user?.id);
															} else {
																NotificationManager.error(<Trans i18nKey={"ErrorAddAvatar"} >{"ErrorAddAvatar"}</Trans>, <Trans i18nKey={"Error"} >{"Error"}</Trans>);
															}
														})
												}
											}}
										/>
									</Grid>
									<Grid item xs={12} md={12} lg={7} >
										<Grid container >
											<Grid item xs={12} md={12} lg={12} >
												<FormControl className={classes.formControl}>
													<TextField
														name="lastname"
														label={<Trans i18nKey={"LastName"} >{"LastName"}</Trans>}
														defaultValue={this.state.user.info.client?.lastname}
														variant="outlined"
														className={classes.textField} />
												</FormControl>
											</Grid>
											<Grid item xs={12} md={12} lg={12} >
												<FormControl className={classes.formControl}>
													<TextField name="firstname" label={<Trans i18nKey={"FirstName"} >{"FirstName"}</Trans>} defaultValue={this.state.user.info.client?.firstname}  variant="outlined"  className={classes.textField} />
												</FormControl>
											</Grid>
											<Grid item xs={12} md={12} lg={12} >
												<FormControl className={classes.formControl} >
													<TextField name="middlename" label={<Trans i18nKey={"MiddelName"} >{"MiddelName"}</Trans>} defaultValue={this.state.user.info.client?.middlename}  variant="outlined"  className={classes.textField} />
												</FormControl>
											</Grid>
											<Grid item xs={12} md={12} lg={12} >
												<FormControl className={classes.formControl} >
													{<Trans i18nKey={"Gender_Woman"} >{"Gender_Woman"}</Trans>}
													<Switch
														autoFocus
														checked={this.state.user.info.client?.gender >= 1}
														onChange={(event) => {
															let user = this.state.user;
															user.info.client.gender = !user.info.client?.gender;
															this.setState({user: user});
														}}
														value={1}
														color="primary"
														name="gender"
														inputProps={{ 'aria-label': 'primary checkbox' }}
													/>
													{<Trans i18nKey={"Men"} >{"Men"}</Trans>}
												</FormControl>
											</Grid>
											<Grid item xs={12} md={12} lg={12} >
												<FormControl className={classes.formControl}>
													<TextField name="email" label={<Trans i18nKey={"Email"} >{"Email"}</Trans>} defaultValue={this.state.user.info?.email} variant="outlined" className={classes.textField} />
												</FormControl>
											</Grid>
											<Grid item xs={12} md={12} lg={6} >
												<FormControl className={classes.formControl}>
													<TextField name="phone" label={<Trans i18nKey={"Phone"} >{"Phone"}</Trans>} defaultValue={this.state.user.info?.phone}  variant="outlined"  className={classes.textField} />
												</FormControl>
											</Grid>
											<Grid item xs={12} md={12} lg={6} >
												<FormControl className={classes.formControl} >
													<TextField name="login" label={<Trans i18nKey={"Login"} >{"Login"}</Trans>} fullWidth defaultValue={this.state.user.info?.login}  variant="outlined"  className={classes.textField} />
												</FormControl>
											</Grid>
										</Grid>
									</Grid>

								<Grid item xs={12} md={12} lg={12} >
									<h4><Trans i18nKey={"Passport"} >{"Passport"}</Trans></h4>
								</Grid>
								<Grid item xs={12} md={4} lg={4} >
									<FormControl className={classes.formControl} >
										<TextField name="passport_no" label={<Trans i18nKey={"PassportNo"} >{"PassportNo"}</Trans>} defaultValue={this.state.user.info.client?.passport_no}  variant="outlined"  className={classes.textField} />
									</FormControl>
								</Grid>
								<Grid item xs={12} md={4} lg={4} >
									<FormControl className={classes.formControl}>
										<TextField name="citizenship" label={<Trans i18nKey={"CitizenShip"} >{"CitizenShip"}</Trans>} defaultValue={this.state.user.info.client?.citizenship} variant="outlined" className={classes.textField} />
									</FormControl>
								</Grid>
								<Grid item xs={12} md={4} lg={4} >
									<FormControl className={classes.formControl}>
										<TextField name="birthday"
												   label={<Trans i18nKey={"DateBirth2"} >{"DateBirth2"}</Trans>}
												   defaultValue={this.state.user.info.client?.birthday ? moment(this.state.user.info.client?.birthday).format("YYYY-MM-DD") : ""}
												   variant="outlined"  className={classes.textField} />
									</FormControl>
								</Grid>

								<Grid item xs={12} md={12} lg={12} >
									<FormControl className={classes.formControl} >
										<TextField name="passport_info" label={<Trans i18nKey={"PassportInfo"} >{"PassportInfo"}</Trans>} fullWidth defaultValue={this.state.user.info.client?.passport_info}  variant="outlined"  className={classes.textField} />
									</FormControl>
								</Grid>

								<Grid item xs={12} md={4} lg={4} >
									<FormControl className={classes.formControl} >
										<TextField name="nationality" label={<Trans i18nKey={"Nationality"} >{"Nationality"}</Trans>} fullWidth defaultValue={this.state.user.info.client?.nationality}  variant="outlined"  className={classes.textField} />
									</FormControl>
								</Grid>
								<Grid item xs={12} md={4} lg={4} >
									<FormControl className={classes.formControl} >
										<TextField name="driver_no" label={<Trans i18nKey={"DriverLicense"} >{"DriverLicense"}</Trans>} fullWidth defaultValue={this.state.user.info.client?.driver_no}  variant="outlined"  className={classes.textField} />
									</FormControl>
								</Grid>
								<Grid item xs={12} md={4} lg={4} >
									<FormControl className={classes.formControl} >
										<TextField name="insurance" label={<Trans i18nKey={"Insurance"} >{"Insurance"}</Trans>} fullWidth defaultValue={this.state.user.info.client?.insurance}  variant="outlined"  className={classes.textField} />
									</FormControl>
								</Grid>

								<Grid item xs={12} md={6} lg={6} >
									<FormControl className={classes.formControl} >
										<TextField name="placebirth" label={<Trans i18nKey={"PlaceBirth"} >{"PlaceBirth"}</Trans>} fullWidth defaultValue={this.state.user.info.client?.placebirth}  variant="outlined"  className={classes.textField} />
									</FormControl>
								</Grid>
								<Grid item xs={12} md={6} lg={6} >
									<FormControl className={classes.formControl} >
										<TextField name="location" label={<Trans i18nKey={"PlaceLiving"} >{"PlaceLiving"}</Trans>} fullWidth defaultValue={this.state.user.info.client?.location}  variant="outlined"  className={classes.textField} />
									</FormControl>
								</Grid>

								<Grid item xs={12} md={12} lg={12} >
									<FormControl className={classes.formControl} >
										<TextField name="info"
												   label={<Trans i18nKey={"Info"} >{"Info"}</Trans>}
												   fullWidth
												   defaultValue={this.state.user.info.client?.info}
												   variant="outlined"
												   className={classes.textField}
												   multiline={true}
												   minRows="3"
												   maxRows="11"
												   Rows="3"
										/>
									</FormControl>
								</Grid>
							</Grid>
					</DialogContent>
					<DialogActions>
						<Button variant="outlined" onClick={this.handleCloseDialog} color="secondary" >
							<Trans i18nKey={"Close"} >{"Close"}</Trans>
						</Button>
						<Button variant="outlined" type="submit" color="primary">
							<Trans i18nKey={"Save"} >{"Save"}</Trans>
						</Button>
					</DialogActions>
			</form>
		</Dialog>
	};

	render() {

		const {classes} = this.props;
		//console.log(this.state);
		// console.log(this.state.user);
		if(!this.state.user)
			return <></>;
		else
			return (
				<>
					{this.renderFormUpdate()}
					<Grid container style={{marginBottom: 20}} >
						<Grid item xs={12} md={6} lg={6} >
							<Button variant="outlined"
									disabled={userStore.isReadingManager()}
									startIcon={<CreateIcon />}
									onClick={(event) => {
										this.setState({isOpenDialog: true});
									}}
							>
								<Trans i18nKey={"EditingClient"} >{"EditingClient"}</Trans>
							</Button>
						</Grid>
						<Grid item xs={12} md={3} lg={3} >
							{ this.state.thresholds ?
								<>
									<DialogThresholds
										isOpen={this.state.isOpenDialogThresholds}
										handleClose={event=>this.setState({isOpenDialogThresholds: false})}
										thresholds={this.state.thresholds}
										handleSave={(data)=> apiStore.updateThresholds(this.state.user.id, data.threshold_far * 1, data.threshold_frr * 1, data.comments).then((event) => {
											if(data.statusCode>300){
												NotificationManager.error(data.error, <Trans i18nKey={"ChangesSaved"} >{"ChangesSaved"}</Trans>);
											} else {
												NotificationManager.success(<Trans i18nKey={"ChangesSaved"} >{"ChangesSaved"}</Trans>);
												this.setState({
													isOpenDialogThresholds: false,
													thresholds: {
														threshold_far: data.threshold_far,
														threshold_frr: data.threshold_frr,
														comments: data.comments
													}
												});
											}
										})}
									/>
								<Button variant="outlined"
									disabled={userStore.isReadingManager()}
									title={this.state?.thresholds.comments}
									startIcon={<CreateIcon />}
									onClick={(event) => {
										this.setState({isOpenDialogThresholds: true});
									}}
							>
								FAR: {this.state?.thresholds.threshold_far} FRR: {this.state?.thresholds.threshold_frr}
							</Button>
							</>
								:''
							}
						</Grid>

						<Grid item xs={12} md={3} lg={3}>
							<div style={{float: 'right', marginTop: 16}} >
								{this.state.user.info.statusUser?.name === 'active' ?
									<Trans i18nKey={"Active"} >{"Active"}</Trans> :
									<Trans i18nKey={"Disactive"} >{"Disactive"}</Trans>
								}
								<GreenSwitch {...{ inputProps: { 'aria-label': 'Switch demo' } }}
									disabled={userStore.isReadingManager()}
									checked={(this.state.user.info.statusUser?.name === 'active')}
									onChange={this.handleChange}
								/>
							</div>
						</Grid>
					</Grid>

					<Grid container >
					<Grid item xs={12} md={12} lg={5} >
						<Avatar
							className={classes.profileImage}
							src={
								this.state.user && this.state.user.info.avatarUser && this.state.user.info.avatarUser.link
									? config.BACKEND + this.state.user.info.avatarUser.link.replace("/var/www/files", "") : null}
							variant={'square'}
						/>
					</Grid>
					<Grid item xs={12} md={12} lg={7} >
						<div className={classes.profile} style={{ }}>
							<h3>
								{ i18n.language === 'ru'
									? [this.state.user.info.client.lastname, this.state.user.info.client.firstname, this.state.user.info.client.middlename].join(' ')
									: [this.state.user.info.client.firstname, this.state.user.info.client.middlename, this.state.user.info.client.lastname].join(' ')
								}
							</h3>
							<Trans i18nKey={"DateBirth"} >{"DateBirth"}</Trans>: <span>{this.state.user?.info.client?.birthday ? moment(this.state.user?.info.client?.birthday).format("YYYY-MM-DD"):'-'}</span>
							&nbsp;&nbsp;&nbsp;<Trans i18nKey={"Gender"} >{"Gender"}</Trans>: <span>{this.state.user?.info?.client?.gender?<Trans i18nKey={"M"} >{"M"}</Trans>:<Trans i18nKey={"W"} >{"W"}</Trans>}</span><br/>
							<Trans i18nKey={"PassportNo"} >{"PassportNo"}</Trans>: <span>{this.state.user?.info.client?.passport_no ? this.state.user?.info.client?.passport_no :'-'}</span>
							&nbsp;&nbsp;&nbsp;<Trans i18nKey={"CitizenShip"} >{"CitizenShip"}</Trans>: <span>{this.state.user?.info.client?.citizenship ? this.state.user?.info.client?.citizenship :'-'} </span><br/>
							<Trans i18nKey={"PassportInfo"} >{"PassportInfo"}</Trans>: <span>{this.state.user?.info.client?.passport_info ? this.state.user?.info.client?.passport_info :'-'}</span><br/>
							<Trans i18nKey={"DrivenNo"} >{"DrivenNo"}</Trans>: <span>{this.state.user?.info.client?.driver_no ? this.state.user?.info.client?.driver_no :'-'}</span><br/>
							<Trans i18nKey={"Insurance"} >{"Insurance"}</Trans>: <span>{this.state.user?.info.client?.insurance ? this.state.user?.info.client?.insurance : '-'}</span><br/>
							<Trans i18nKey={"Phone"} >{"Phone"}</Trans>: <span>{this.state.user?.info.phone ? this.state.user?.info.phone :'-'}</span>
							&nbsp;&nbsp;&nbsp;Email: <span>{this.state.user?.info.email?<a href="mailto:{this.state.user?.info.email}">{this.state.user?.info.email}</a>:''}</span><br/>
							<Trans i18nKey={"Login"} >{"Login"}</Trans>: <span>{this.state.user?.info.login ? this.state.user?.info.login : '-'}</span>
						</div>
					</Grid>
					<Grid item xs={12} md={12} lg={12} >
						<div className={classes.profile} style={{marginLeft: -10}}>
							<Trans i18nKey={"PlaceBirth"} >{"PlaceBirth"}</Trans>: <span>{this.state.user?.info.client?.placebirth ? this.state.user?.info.client?.placebirth :'-'}</span><br/>
							<Trans i18nKey={"PlaceLiving"} >{"PlaceLiving"}</Trans>: <span>{this.state.user?.info.client?.location ? this.state.user?.info.client?.location : '-'}</span><br/>
							<Trans i18nKey={"AdditionalInformation"} >{"AdditionalInformation"}</Trans>: <br/>
							<p>{this.state.user?.info.client?.info ? this.state.user?.info.client?.info :'-'}</p>
							<Trans i18nKey={"Updated"} >{"Updated"}</Trans>: <span>{this.state.user?.info.client?.updated ? moment(this.state.user?.info.client?.updated).format("YYYY-MM-DD"):'-'}</span>
							&nbsp;&nbsp;&nbsp;&nbsp;<Trans i18nKey={"Created"} >{"Created"}</Trans>: <span>{this.state.user?.info.client?.created ? moment(this.state.user?.info.client?.created).format("YYYY-MM-DD"):'-'}</span>
						</div>
					</Grid>
					</Grid>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs   className={classes.tabList} value={this.state.tabIndex} onChange={(event, newValue) => this.setState({tabIndex: newValue})} >
							<Tab label={<Trans i18nKey={"UserVoicePrint"} >{"UserVoicePrint"}</Trans>}  {...a11yProps(0)} style={{width: '50%', maxWidth: '50%'}}  />
							<Tab label={<Trans i18nKey={"UserVoiceAuthentication"} >{"UserVoiceAuthentication"}</Trans>}  {...a11yProps(1)} style={{width: '50%', maxWidth: '50%'}} />
						</Tabs>
						<TabPanel value={this.state.tabIndex} index={0}>

								<div style={{textAlign: 'right'}}>

									<Button  variant="contained" color="default"
											 className={classes.buttonTrash}
											 onClick={(event) => {
												 apiStore.updateEnrollUser(this.state.user.id).then((data) => {
													 if(data.statusCode > 300){
														 NotificationManager.error(data.message.join("\r\n"), `Ошибка`);
													 }
												 	this.props.reload(this.state.user.id)
												 });
											 }}
											 disabled={userStore.isReadingManager()}
											 style={{marginRight: 20}}
									>
										<RefreshIcon />
									</Button>
									<Button  variant="contained" color="default"
											 className={classes.buttonTrash}
											 onClick={(event) => {
												 /*for(let uid of this.state.selectedEnrol){
													 apiStore.deleteEnrollFile(uid);
												 }*/
												 apiStore.deleteListEnrollFile(this.state.selectedEnrol);
												 //this.setState({selectedEnrol:[]}, this.loadListEnrollment)
												 this.setState({selectedEnrol:[], errorUpload: null}, this.loadListEnrollment)
												 NotificationManager.success(<Trans i18nKey={"SelectedFilesDeleted"} >{"SelectedFilesDeleted"}</Trans>);
											 }}
											 disabled={userStore.isReadingManager() || !(this.state.selectedEnrol.length > 0)}
											 style={{marginRight: 20}}
									>
										<DeleteIcon />
									</Button>
									<DialogUpload
										isOpen={this.state.isOpenDialogStamp}
										errorUpload={this.state.errorUpload}
										acceptedFiles={[".wav", ".mp3"]}
										handleClose={(event) => this.setState({isOpenDialogStamp: false, errorUpload: null})}
										handleSave = {(data) => {
											// console.log("state: ", this.state);
											data.study_time = moment(data.study_time);
											data.user_u = this.state.user.id;
									  		apiStore.addEnrollUser(data).then(
									  			data => {
													// console.log("enrolment answer, data: ", data);
													// if(data.statusCode === 201){
													if(!data.message){
														this.setState({isOpenDialogStamp: false, errorUpload: null}, this.loadListEnrollment);
														NotificationManager.success(<Trans i18nKey={"AudioFileLoaded"} >{"AudioFileLoaded"}</Trans>);
													} else {
									  					let message = data.message.pop();
									  					this.setState({errorUpload: message}, this.loadListEnrollment);
														NotificationManager.error(message, <Trans i18nKey={"Error"} >{"Error"}</Trans>);
													}
												}
											)
									  	}
									  }
									/>
									<Button  variant="contained" color="default"
											 className={classes.buttonSuccess}
											 disabled={userStore.isReadingManager()}
											 onClick={(event) => this.setState({isOpenDialogStamp: true})}
									>
										<AddIcon />
									</Button>
								</div>
								<div style={{ minHeight: 200,height: 400, width: "100%" }}>
									{ /*Grid1 Enroll*/	}
									<DataGrid
										getRowId={(row) => row.uid}
										loading={this.state.enrollLoad}
										rows={this.state.enrol}
										columns={columns}
										checkboxSelection
										disableSelectionOnClick
										onSelectionModelChange={(model) => this.setState({selectedEnrol: model})}
										components={{
											//Toolbar: GridToolbar,
											Pagination: this.customPagination
										}}
									/>
								</div>

						</TabPanel>
						<TabPanel value={this.state.tabIndex} index={1} >

								<div style={{textAlign: 'right'}}>
									<Button  variant="contained" color="default"
											 className={classes.buttonTrash}
											 onClick={(event) => {
												 apiStore.updateVerifyUser(this.state.user.id).then((data) => {
													 if(data.statusCode > 300){
														 NotificationManager.error(data.message.join("\r\n"), <Trans i18nKey={"Error"} >{"Error"}</Trans>);
													 }
												 	this.props.reload(this.state.user.id);
												 });
											 }}
											 disabled={userStore.isReadingManager()}
											 style={{marginRight: 20}}
									>
										<RefreshIcon />
									</Button>
									<Button  variant="contained" color="default"
											 className={classes.buttonTrash}
											 onClick={(event) => {
												 /*for(let uid of this.state.selectedVerify){
												 	apiStore.deleteVerifyFile(uid);
												 }*/
												 apiStore.deleteListVerifyFiles(this.state.selectedVerify);
												 //this.setState({selectedVerify:[]}, this.loadListVerify)
												 this.setState({selectedVerify:[], errorUpload: null}, this.loadListVerify)
												 NotificationManager.success(<Trans i18nKey={"SelectedFilesDeleted"} >{"SelectedFilesDeleted"}</Trans>);
											 }}
											 disabled={userStore.isReadingManager() || !(this.state.selectedVerify.length > 0)}
											 style={{marginRight: 20}}
									>
										<DeleteIcon />
									</Button>
									<DialogUpload isOpen={this.state.isOpenDialogStamp} errorUpload={this.state.errorUpload} acceptedFiles={[".wav", ".mp3"]} handleClose={(event) => this.setState({isOpenDialogStamp: false, errorUpload: null})}
												  handleSave = {
													  (data) => {
														  // console.log("state: ", this.state);
														  data.study_time = moment(data.study_time);
														  data.threshold_far = this.state.thresholds.threshold_far;
														  data.threshold_frr = this.state.thresholds.threshold_frr;
														  data.user_u = this.state.user.id;
														  apiStore.addVerifyUser(data).then(
															  data => {
																  // console.log("verify answer, data: ", data);
																  // if(data.statusCode === 201){
																  if(!data.message){
																	  this.setState({isOpenDialogStamp: false, errorUpload: null}, this.loadListVerify)
																	  NotificationManager.success(<Trans i18nKey={"AudioFileLoaded"} >{"AudioFileLoaded"}</Trans>);
																  } else {
																	  let message = data.message.pop();
																	  this.setState({errorUpload: message}, this.loadListVerify);
																	  NotificationManager.error(message, <Trans i18nKey={"Error"} >{"Error"}</Trans>);
																  }
															  }
														  )
													  }
												  }
									/>
									<Button  variant="contained" color="default"
											 className={classes.buttonSuccess}
											 disabled={userStore.isReadingManager()}
											 onClick={(event) => this.setState({isOpenDialogStamp: true})}
									>
										<AddIcon />
									</Button>
								</div>
								<div style={{ minHeight: 200,height: 400, width: "100%" }}>
									{ /*Grid2 Verify*/	}
									<DataGrid getRowId={(row) => row.uid}
											  rows={this.state.verify}
											  rowHeight={80}
											  columns={this.columnsVerify}
											  loading={this.state.verifyLoad}
											  checkboxSelection
											  disableSelectionOnClick
											  onSelectionModelChange={(model) => this.setState({selectedVerify: model})}
											  components={{
												  //Toolbar: GridToolbar,
												  Pagination: this.customPagination2
											  }}
									/>
								</div>

						</TabPanel>
					</Box>
				</>

			);
	}
}

export default withStyles(useStyles)(UserProfile);
