import React,{Component} from "react";
import AddRegistrForm from "../../components/shared/AddRegistrForm/AddRegistrForm";
import {Avatar, Dialog} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import {createTheme, ThemeProvider, withStyles} from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import LanguageDetector from 'i18next-browser-languagedetector';
import { UserContext } from "../../context/UserContext";
import Rus from './Rus';
import Eng from './Eng';
import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
import {config} from "../../config";
import ExpandLessIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Popover from "@material-ui/core/Popover/Popover";
import Card from "@material-ui/core/Card/Card";
import {Link} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import UserDialogForm from "../../components/shared/UserDialogForm/UserDialogForm";

export default class UserAgreement extends Component{

    renderPage = (lang) => {
        switch (lang) {
            case 'ru':
                    return <Rus />;
            case 'en':
                    return <Eng />;
            default:
                    return <Eng />;
        }
    }

    render() {
        // console.log(this.userLang);
        const {classes} = this.props;
        return <Container>
            <Grid container component="main" >
                <Grid item xs={12} md={12} lg={12}  >
                    <UserContext.Consumer>
                            {({user, setAuthUser, signOut, showSettings}) => <Box component="div" m={6}>{this.renderPage(user.getLang())}</Box>}
                    </UserContext.Consumer>
                </Grid>
            </Grid>
        </Container>;
    }
}

