import React,{Component} from "react";
import LoginForm from "./../../components/LoginForm"
import userStore from "../../store/UserStore";
import { Route, Redirect } from "react-router-dom";

export default class SignIn extends Component{
    render() {
        if(userStore.isSignIn()) {
            return <Redirect to="/" />;
        }else {
            return <LoginForm updateIsSignedIn={this.props.updateIsSignedIn} />;
        }
    }
}
