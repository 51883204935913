export default class Client {
    old: Client = null;
    id: null;
    info = {
        id: null,
        age: '',
        birthday: '',
        citizenship: '',
        clientStatus: {uid: '', name: ''},
        driver_no: '',
        firstname: '',
        gender: 0,
        height: '',
        info: '',
        insurance: '',
        lastname: '',
        location: '',
        middlename: '',
        nationality: '',
        passport_info: '',
        passport_no: '',
        placebirth: '',
        status: '',
        uid: '',
        updated: '',
        user_u: '',
        weight: '',
        fullName: '',
        quantity: 0,
        email: '',
        active: '',
        count_verify:0
    }
    constructor(info){
        this.info = info.uid_client ? info.client : info;

        this.info.quantity = 0;
        this.id = this.info.user_u?this.info.user_u:this.info.uid;
        this.info.id = this.id;
        this.info.fullName = [this.info.lastname, this.info.firstname, this.info.middlename].join(' ');
        this.info.email = info?.email ? info.email : null;
        this.info.active = this.info.status === 'b525b655-8647-11eb-8b37-8cec4bc83495' ? true : false;
        let client = this;
        this.old = client;
    }

    toArray() {
        return this.info;
    }
}
