import React, {Component} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { DropzoneArea } from 'material-ui-dropzone'
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField/TextField";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import 'moment/locale/ru';
import Alert from '@material-ui/lab/Alert';
import {Trans} from "react-i18next";
moment.locale('ru');

export default class DialogThresholds extends Component {
  constructor(props){
    super(props);
    this.state = {
      comments: null,
      threshold_far: 20,
      threshold_frr: 75
    };
  }

  componentWillReceiveProps(): void{
    // console.log('componentWillReceiveProps');
    this.setState({threshold_far: this.props.thresholds.threshold_far,
      threshold_frr: this.props.thresholds.threshold_frr,
      comments: this.props.thresholds.comments
    });
  }

  render() {
    return (
        <>
          <Dialog
              open={this.props.isOpen}
              onClose={this.props.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <form onSubmit={(event) => {
              event.preventDefault();
              this.props.handleSave(this.state);
            }}>
              <DialogTitle id="alert-dialog-title"><Trans i18nKey={"EditingThresholds"} >{"EditingThresholds"}</Trans></DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.props.subtitle}
                </DialogContentText>
                {this.props.errorUpload ? <Alert severity="error" style={{marginBottom: 25}} >{this.props.errorUpload}</Alert>:''}
                <TextField
                    required
                    onChange={(event) => this.setState({threshold_far: event.target.value}) }
                    value={this.state.threshold_far}
                    style={{marginBottom: 25}}
                    variant="outlined"
                    id = "threshold_far"
                    name = "threshold_far"
                    label={<Trans i18nKey={"FAR_threshold"} >{"FAR_threshold"}</Trans>}
                    type = "number"
                    fullWidth
                />
                <TextField
                    required
                    onChange={(event) => this.setState({threshold_frr: event.target.value}) }
                    value={this.state.threshold_frr}
                    style={{marginBottom: 25}}
                    variant="outlined"
                    id = "threshold_frr"
                    name = "threshold_frr"
                    label={<Trans i18nKey={"FRR_threshold"} >{"FRR_threshold"}</Trans>}
                    type = "number"
                    fullWidth
                />
                <TextField
                    multiline={true}

                    onChange={(event) => this.setState({comments: event.target.value}) }
                    style={{marginBottom: 25}}
                    minRows="5"
                    maxRows="5"
                    Rows="5"
                    variant="outlined"
                    id = "comments"
                    name = "comments"
                    label = {<Trans i18nKey={"Comment"} >{"Comment"}</Trans>}
                    type = "text"
                    fullWidth
                />

              </DialogContent>
              <DialogActions>
                <Button variant="outlined" color="secondary"  onClick={(event) => this.props.handleClose()} >
                  <Trans i18nKey={"Close"} >{"Close"}</Trans>
                </Button>

                <Button variant="outlined" type="submit" color="primary" >
                  <Trans i18nKey={"Save"} >{"Save"}</Trans>
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </>
    );
  }


}

DialogThresholds.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
