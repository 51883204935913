import { GET_COLLEAGUES, ACTION_COLLEAGUES, DELETE_COLLEAGUES, UPDATE_COLLEAGUES } from "./types"
import apiStore from "../store/ApiStore";

export const getColleagues = (start = null, end = null, page = 0, pagesize = 10, search = null, sortBy = null, sortDesc = null) => {
    return {
        type: GET_COLLEAGUES,
        items: apiStore.getColleagues(start, end, page < 0 ? 0 : page, pagesize, search, sortBy, sortDesc),
    }
};

export const addColleague = (profile = null, comment = null) => {
    return {
        type: UPDATE_COLLEAGUES,
        items: apiStore.addColleague(profile, comment),
    }
};

export const updateColleague = (profile = null, comment = null) => {
    return {
        type: UPDATE_COLLEAGUES,
        items: apiStore.updateColleague(profile, comment),
    }
};

export const actionColleagues = (action = null, uidsList = null, comment = null) => {
    return {
        type: ACTION_COLLEAGUES,
        items: apiStore.actionColleagues(action, uidsList, comment),
    }
};

export const deleteColleagues = (uidsList = null, comment = null) => {
    return {
        type: DELETE_COLLEAGUES,
        items: apiStore.deleteColleagues(uidsList, comment),
    }
};
