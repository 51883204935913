import HttpAdapter from './Adapter/Http/HttpAdapter';
import {config} from '../../src/config';
import userStore from './UserStore';
import {NotificationManager} from "react-notifications";
import moment from "moment";

class ApiStore {


    constructor (initialState, adapter) {
        this.state = initialState;
        this.adapter = adapter;
    }

    getUsers(phone = '', email = ''){
        let params = [];

        if(phone)
            params['phone'] = phone;

        if(email)
            params['email'] = email;

        return this.adapter.post('/api/users/get', JSON.stringify(params), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }


    getClients(page = 0, pagesize = 5,  search = null, lastname = null, middlename = null, firstname = null, status = null, was_verification = null, was_enroll = null, orderBy = null, orderDesc = null){
        let params = {};

        if(search)
            params.search = search;

        if(lastname)
            params.lastname = lastname;

        if(middlename)
            params.middlename = middlename;

        if(firstname)
            params.firstname = firstname;
        if(status && status !== -1)
            params.status = status;

        if(was_verification !== null && was_verification !== -1)
            params.was_verification = was_verification;
        else
            params.was_verification = 'any';

        if(was_enroll !== null && was_enroll !== -1)
            params.was_enroll = was_enroll;
        else
            params.was_enroll = 'any';

        if(orderBy !== null)
            params.sortby = orderBy;

        if(orderDesc !== null)
            params.sortdesc = orderDesc;

        params.page = page * pagesize + '';
        params.pagesize = pagesize + '';

        // console.log("getClients, params: ", params);
        return this.adapter.post('/api/user/get/client/profiles', JSON.stringify(params), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json());
    }


    async getClientProfileByUid(uid){
        return this.adapter.get('/api/user/get/client/profile?user_u=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    addAvatarUser(file) {
        // console.log(file);
        const formData = new FormData();
        formData.append("avatar", file);

        return this.adapter.post('/api/user/update/avatar/', formData, { 'access-token' : userStore.getAccessToken() })
            .then((data) => data )
    }

    addAvatar(userUid, file) {
        // console.log(file);
        const formData = new FormData();
        formData.append("avatar", file);
        formData.append("user_u", userUid);

        return this.adapter.post('/api/user/update/client/avatar/', formData, { 'access-token' : userStore.getAccessToken() })
            .then((data) => data )
    }

    async sendSupport(comment, email) {
        return this.adapter.post('/api/user/send/support/mail', JSON.stringify({comment: comment, email: email}), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async changeClientStatus(user, status = true) {
        return this.adapter.post('/api/user/update/status', JSON.stringify({user_u: user, status: status}), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }
    async getVoiceStamp(uid, page = 0, pagesize = 10, orderBy = null, orderDesc = null) {
        return this.getVoiceEnroll(uid, page, pagesize, orderBy, orderDesc)
    }

    async getVoiceEnroll(uid, page = 0, pagesize = 10, orderBy = null, orderDesc = null) {
        let post = {};
        post.user_u = uid;
        post.page = page + '';
        post.pagesize = pagesize + '';
        if(orderBy != null)
            post.sortby = orderBy;
        if(orderDesc != null)
            post.sortdesc = orderDesc === true ? orderDesc : false;
        post.page = page * pagesize + '';
        return this.adapter.post('/api/enroll/get/pages', JSON.stringify(post), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async getVoiceVerifys(uid, page = 0, pagesize = 10, orderBy = null, orderDesc = null) {
        let post = {};
        post.user_u = uid;
        post.page = page + '';
        post.pagesize = pagesize + '';
        if(orderBy != null)
            post.sortby = orderBy;
        if(orderDesc != null)
            post.sortdesc = orderDesc === true ? orderDesc : false;
        post.page = page * pagesize + '';
        return this.adapter.post('/api/verify/get/pages', JSON.stringify(post), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async getQuantityStamps(uid){
        return this.adapter.get('/api/enroll/get/quantity?user_u=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=> {
                return response.status*1 < 300 ? response.json() : {quantity: 0};
            })
            .then((data) => {
                return data;
            });
    }

    async getQuantityVerifys(uid){
        return this.adapter.get('/api/verify/get/quantity?user_u=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=> {
                return response.status*1 < 300 ? response.json() : {quantity: 0};
            })
            .then((data) => {
                return data;
            });
    }

    async getQuantityProfiles(){
        return this.adapter.get('/api/user/get/client/profiles/quantity', { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async getVoiceEnrollAll(start = null, end = null, page = 0, pagesize = 10, search = null, orderBy = null, orderDesc = null) {
        let params = {};

        if(search)
            params.search = search;
        if(start)
            params.start_time = start;
        if(end)
            params.end_time = end;

        params.page = page * pagesize + '';
        params.pagesize = pagesize + '';
        if(orderBy != null)
            params.sortby = orderBy;
        if(orderDesc != null)
            params.sortdesc = orderDesc === true ? orderDesc : false;

        return this.adapter.post('/api/enroll/get/pages', JSON.stringify(params), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async getVoiceVerifysAll(start = null, end = null, page = 0, pagesize = 10, search = null, orderBy = null, orderDesc = null) {
        let params = {};

        if(search)
            params.search = search;
        if(start)
            params.start_time = start;
        if(end)
            params.end_time = end;

        params.page = page * pagesize + '';
        params.pagesize = pagesize + '';
        if(orderBy != null)
            params.sortby = orderBy;
        if(orderDesc != null)
            params.sortdesc = orderDesc === true ? orderDesc : false;

        return this.adapter.post('/api/verify/get/pages', JSON.stringify(params), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async getEnrollFile(uid){
        return this.adapter.get('/api/enroll/get/bin?uid=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then((data) => {
                return data;
            });
    }

    async getVerifyFile(uid){
        return this.adapter.get('/api/verify/get/bin?uid=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then((data) => {
                return data;
            });
    }

    async addClient(comment) {
        return this.adapter.post('/api/user/create/client/', JSON.stringify({
            "client": {
                "firstname": "Сергей",
                "middlename": "Анатольевич",
                "lastname": "Петров"
            },
            "user": {
                "login": "petrov",
                "password": "12345",
                "email": "petrov@gmail.com",
                "phone": "89683251119"
            },
            "relation": {
                "uid_manager":"ca981f1d-856c-11eb-9747-8cec4bc83495"
            }
        }), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async updateClientProfile(profile) {
        profile = JSON.stringify(profile);
        /*let data = {
            "client": {
                 "uid" : profile["client"].uid,
                "firstname": profile["client"].firstname,
                "middlename": profile["client"].middlename,
                "lastname": profile["client"].lastname
            },
            "user": {
                "uid" : profile["user"].uid,
                "login": profile["user"].login,
                "password": profile["user"].login,
                "email": profile["user"].login,
                "phone": profile["user"].login,
            },
            "relation": {
                "uid_manager":"ca981f1d-856c-11eb-9747-8cec4bc83495"
            }
        };*/
        return this.adapter.post('/api/user/update/client/profile', profile, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=> response.json())
            .then((data) => {
                if(data.statusCode>300){
                    try {
                        NotificationManager.error(data.message.join("\r\n"), 'Ошибка');
                    }catch (e) {
                        NotificationManager.error(data.message, 'Ошибка');
                    }
                }else{
                    return data;
                }
            });
    }

    async getVerifyThresholds(uid) {
        return this.adapter.get('/api/verify/get/thresholds?user_u=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=> response.text())
            .then((data) => {
                if(data)
                    return JSON.parse(data);
                else
                    return null;
            });
    }

    async updateEnrollUser(uid) {
        return this.adapter.get('/api/enroll/update?user_u=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async updateVerifyUser(uid) {
        return this.adapter.get('/api/verify/update?user_u=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    addEnrollUser(data) {
        // console.log(data)
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("study_time", data.study_time);
        formData.append("duration", data.duration);
        formData.append("comments", data.comments);
        formData.append("user_u", data.user_u);

        return this.adapter.post('/api/enroll/upload/', formData, { 'access-token' : userStore.getAccessToken() })
            .then(response=>response.json())
            .then((data) => data )
    }

    /*addEnrollUser(data) {
        console.log(data)
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("study_time", data.study_time);
        formData.append("duration", data.duration);
        formData.append("comments", data.comments);
        formData.append("user_u", data.user_u);

        return this.adapter.post('/api/enroll/upload/', formData, { 'access-token' : userStore.getAccessToken() })
            .then(response=>response.json())
            .then((data) => data )
    }*/

    addVerifyUser(data) {
        // console.log(data)
        const formData = new FormData();
        formData.append("file", data.file);
        formData.append("study_time", data.study_time);
        formData.append("duration", data.duration);
        formData.append("threshold_far", data.threshold_far);
        formData.append("threshold_frr", data.threshold_frr);
        formData.append("comments", data.comments);
        formData.append("user_u", data.user_u);

        return this.adapter.post('/api/verify/upload/', formData, { 'access-token' : userStore.getAccessToken() })
            .then(response=>response.json())
            .then((data) => data )
    }

    async deleteVerifyFile(uid) {
        //console.log("uid: ", uid);
        return this.adapter.post('/api/verify/delete/bin/', JSON.stringify({
            uid: uid
        }), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }
    async deleteListVerifyFiles(arrayuid) {
        // console.log("arrayuid: ", arrayuid);
        return this.adapter.post('/api/verify/delete/listbin/', JSON.stringify({
            arrayuid: arrayuid
        }), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async deleteEnrollFile(uid) {
        // console.log("uid: ", uid);
        return this.adapter.post('/api/enroll/delete/bin/', JSON.stringify({
            uid: uid
        }), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }
    async deleteListEnrollFile(arrayuid) {
        // console.log("arrayuid: ", arrayuid);
        return this.adapter.post('/api/enroll/delete/listbin/', JSON.stringify({
            arrayuid: arrayuid
        }), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async updateThresholds(user_u, far, frr, comments = null) {
        return this.adapter.post('/api/verify/update/thresholds/', JSON.stringify({
                user_u: user_u,
                threshold_far: far,
                threshold_frr: frr,
                comments: comments
        }), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async getManagerProfile(lastname = '', middlename = '', firstname = '') {
        let filter = {};
        if(lastname)
            filter['lastname'] = lastname;
        if(middlename)
            filter['middlename'] = middlename;
        if(firstname.length>0)
            filter['firstname'] = firstname;

        return this.adapter.post('/api/user/get/manager/profiles', JSON.stringify(filter), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async getColleagues(start = null, end = null, page = 0, pagesize = 10, search = null, sortBy = null, sortDesc = null) {
        let params = {};

        if(search)
            params.search = search;
        if(start)
            params.start_time = start;
        if(end)
            params.end_time = end;

        params.page = page * pagesize + '';
        params.pagesize = pagesize + '';
        if(sortBy != null)
            params.sortby = sortBy;
        if(sortDesc != null)
            params.sortdesc = sortDesc === true ? sortDesc : false;

        return this.adapter.post('/api/user/get/manager/colleagues', JSON.stringify(params), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async deleteColleagues(uidsList = null, comment) {
        let params = {};
            params.action = 'delete';
        if(uidsList)
            params.managerUIDs = uidsList;
        if(comment)
            params.comment = comment;

        params.user = {};
        params.manager = {};

        return this.adapter.post('/api/user/delete/manager/colleague', JSON.stringify(params), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async actionColleagues(action = null, uidsList = null, comment) {
        let params = {};
        if(action)
            params.action = action;
        if(uidsList)
            params.managerUIDs = uidsList;
        if(comment)
            params.comment = comment;

        params.user = {};
        params.manager = {};

        return this.adapter.post('/api/user/update/manager/colleague', JSON.stringify(params), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async addColleague(profile = null, comment) {
        let params = {};
        // params.action = null;
        // params.managerUIDs = null;
        if(comment)
            params.comment = comment;
        if(profile) {
            if(profile.user)
                params.user = profile.user;
            if(profile.manager)
                params.manager = profile.manager;
        }
        return this.adapter.post('/api/user/create/manager/colleague', JSON.stringify(params), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async updateColleague(profile = null, comment) {
        let params = {};
            // params.action = null;
            // params.managerUIDs = null;
        if(comment)
            params.comment = comment;

        if(profile) {
            if(profile.user)
                params.user = profile.user;
            if(profile.manager)
                params.manager = profile.manager;
        }

        return this.adapter.post('/api/user/update/manager/colleague', JSON.stringify(params), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    getUserAvatar(uid) {
        return this.adapter.get('/api/user/get/avatar?uid=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then((data) => {
                return data;
            });
    }

    refreshToken(uid) {
        return this.adapter.get('/api/login/refresh/', { 'refresh-token' : userStore.getRefreshToken(), 'Content-Type' : 'application/json'})
            .then((data) => {
                return data;
            });
    }


    getEnrollByDatePeriod(start, finish) {
        return this.adapter.get('/api/enroll/get/many?start_time=' + start + '&end_time=' + finish, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json());
    }

    getVerifyByDatePeriod(start, finish) {
        return this.adapter.get('/api/verify/get/many?start_time=' + start + '&end_time=' + finish, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then((data) => {
                return data;
            });
    }

    getVerifyParamsd(uid) {
        return this.adapter.get('/api/verify/get/parameters?uid=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then((data) => {
                return data;
            });
    }

    getEnrollParamsd(uid) {
        return this.adapter.get('/api/enroll/get/parameters?uid=' + uid, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then((data) => {
                return data;
            });
    }

    updateVerifyComment(wav_u, comments) {
        return this.adapter.post('/api/verify/update/comment/', JSON.stringify({
            wav_u: wav_u,
            comments: comments
        }), { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }


    async getUsersAnalytics(from_time = null){
        let params = '';
        // console.log("users from_time: ", from_time);
        if(from_time){
            from_time = moment().subtract(from_time, "days").unix();
            params = '?from_time=' + from_time;
            // console.log("params: ", params);
        }

        return this.adapter.get('/api/users/get/quantity/analytics' + params, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async getEnrollAnalytics(from_time = null){
        let params = '';
        // console.log("enroll from_time: ", from_time);
        if(from_time){
            from_time = moment().subtract(from_time, "days").unix();
            params = '?from_time=' + from_time;
            // console.log("params: ", params);
        }

        return this.adapter.get('/api/enroll/get/quantity/analytics' + params, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }

    async getVerifyAnalytics(from_time = null){
        let params = '';
        // console.log("verify from_time: ", from_time);
        if(from_time){
            from_time = moment().subtract(from_time, "days").unix();
            params = '?from_time=' + from_time;
            // console.log("params: ", params);
        }

        return this.adapter.get('/api/verify/get/quantity/analytics' + params, { 'access-token' : userStore.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    }
}

const apiStore = new ApiStore({}, new HttpAdapter());
export default apiStore;
