import { combineReducers } from "redux"
import userReducers from "./userReducers"
import verifyReducers from "./verifyReducers"
import enrollReducers from "./enrollReducers"
import clientsReducers from "./clientsReducers"

const allReducers = combineReducers({
    user: userReducers,
    clients: clientsReducers,
    verify: verifyReducers,
    enroll: enrollReducers
})

export default allReducers
