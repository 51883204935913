import styled from "styled-components";
import right from '../../../static/img/icons-containers-36-px.png';
import left from '../../../static/img/icons-containers-36-px_2.png';


export const Wrapper = styled.div`
  background: #FFF;
  height: 100%;
`;

export  const useStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    buttonenter: {
        cursor: 'pointer',
        boxShadow: 'none',
        marginTop: 7,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.7,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        backgroundColor:'#2277b7',
        textTransform: 'none',
        float: 'right',
        marginRight: 10
    },
    selectlanguage: {
        width: 120,
        lineHeight: 1,
        margin: '0 24px 0px 20px',
        padding: '5px 3px',
        borderRadius: 6,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.07)',
        border: 'solid 1px #dfe3e5',
        '& .MuiInputBase-input': {
            padding: 0
        },
        '&:before' : {
            borderBottom:0
        },
        '&:hover:before' : {
            borderBottom:0
        }
    },
    footer:{
        backgroundColor: '#2277b7',
        color: 'white',
        padding: '16px 5px',
        '& a': {
            color: 'white',
            textDecoration: 'underline'
        },
        '& a:hover': {
            textDecoration: 'none'
        }
    }
});
