import React,{Component} from "react";
import Box from "@material-ui/core/Box";


export default class Eng extends Component{


    render() {
        const {classes} = this.props;
        return <>
            <h1>PRIVACY POLICY</h1>
            <Box component="p" m={3}>PLEASE READ THE FOLLOWING PRIVACY POLICY CAREFULLY BEFORE USING THE APPLICATION PROVIDED BY US. BY ACCESSING OR USING OUR APPLICATION (OTHER THAN TO READ THIS PRIVACY POLICY FOR THE FIRST TIME),
                YOU AGREE TO THE TERMS OF THIS PRIVACY POLICY.</Box>
            <Box component="p" m={3}>The personal data provided by you by any of the means provided for this purpose through our Application or the website speaker-id.com  will be processed by Speaker-ID service in accordance with the following privacy policy.</Box>
            <h2>Collecting User Information</h2>
            <Box component="p" m={3}>We collect personally identifiable information from users to operate our business and provide you with our solutions.
                You are required to register to use our Application of   the Speaker-ID service. We do not collect precise information about the location of your device.
                Our Application may collect certain non-personal information automatically, including, but not limited to, the type of mobile device you use, your mobile operating system, your network service provider,
                the IP address of your mobile device and information about the way you use the Application.</Box>
            <h2>Use of Collected User Information</h2>
            <Box component="p" m={3}>We use the collected information solely for the purpose of tracking bugs, improving our Application or the Speaker-ID service functionality or enhancing user experience.</Box>
            <h2>Sharing of User Information</h2>
            <Box component="p" m={3}>We can use Google Firebase Analytics to collect non-personal information in the form of various usage and user metrics when you use the Application.
                Otherwise, we does not sell, rent, share, or disclose user information to other third parties, except in some limited cases: we are required to do so by law, or that doing so
                is reasonably necessary to comply with legal process, respond to any claims, or to protect the rights, property, or personal safety of Application, our customers, or the public.
                Information about our customers may be disclosed as part of, or during negotiations of, any merger, sale of company assets or acquisition.</Box>
            <h2>Opt-out Option</h2>
            <Box component="p" m={3}>You can stop using all collection of information by the Speaker-ID service easily by uninstalling our Application of the Speaker-ID service.
                You may use the standard uninstall processes as may be available as part of your mobile device or via request into the Speaker-ID service.</Box>
            <h2>Children</h2>
            <Box component="p" m={3}>While the Speaker-ID service may be used by children, it is not intended to be used by children, without involvement, supervision,
                and approval of a parent or legal guardian. The Speaker-ID service does not knowingly collect information from children under the age of 13 without parental approval.
                In the event you become aware that an individual under the age of 13 has used the Speaker-ID service without parental permission, please advise us immediately.</Box>
            <h2>Security</h2>
            <Box component="p" m={3}>All collected information is stored on our own restricted database servers or on Google Firebase Analytics server.
                We limit access to this information to authorized employees who need to know that information in order to operate, develop or improve our Application.
                Please be aware that, although we endeavor provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.</Box>
            <h2>Links to Other Sites</h2>
            <Box component="p" m={3}>Our Application of the Speaker-ID service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us.
                Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
                Link to privacy policy of third party service providers may be used by the our Application: Google Play Services, Google Firebase Analytics.</Box>

            <h2>Location of Personal Information</h2>
            <Box component="p" m={3}>The Speaker-ID service is located in the Russia Federation. Your personal information will be stored in the Russia Federation.
                Whenever we transfer personal information by request to other jurisdictions, we will ensure that the information is transferred in accordance with this Privacy Notice and as permitted by applicable data protection laws.
            </Box>
        </>;
    }
}

