const useStyles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#f7f9fb",
        minHeight: "calc(100vh - 117px)",
        marginTop: 0,
        paddingTop: 20
    },
    buttonenter: {
        cursor: 'pointer',
        boxShadow: 'none',
        height: 24,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        backgroundColor:'#2277b7'
    }
});

export default useStyles;
