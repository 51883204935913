import React,{Component} from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./styles";
import {Link} from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {createTheme, withStyles} from "@material-ui/core/styles";
import {useStyles} from "./styles";
import SelectLang from '../../../components/shared/SelectLang/SelectLang';
import Tab from '@material-ui/core/Tab';
import UserPanelShort from '../../../components/UserPanelShort';
import DehazeIcon from '@material-ui/icons/Dehaze';
import logo from '../../../static/img/logos-color.svg';
import {Trans} from "react-i18next";

function LinkTab(props) {
    return (<Link to={props.to}><Tab component="a" {...props} /></Link>);
}

class MainLayout extends Component  {
    render() {
        const {classes} = this.props;
        //console.log(window.location.pathname);
        return <Wrapper  className={"usercabinet"} >
            <AppBar position="static" color={"transparent"} style={{boxShadow: 'none', borderBottom: 'solid #dfe3e5 1px'}}>
                <Toolbar>
                    <Container>
                        <Grid container spacing={0} >
                            <Grid item xs={6} md={2} lg={2} style={{ /*borderBottom: 'solid #dfe3e5 1px'*/}} >
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    style={{marginTop: 12}}
                                    sx={{ display: { xs: 'none', sm: 'block' } }}
                                >
                                    <Link to="/" ><img src={logo} alt={"logo"}/></Link>
                                </Typography>
                            </Grid>
                            <Grid item xs={"auto"} md={6} lg={8} className={"wrapperTopMenu"} >
                                <div  className={classes.topMenu} >
                                    <Link to="/" className={window.location.pathname === '/'?classes.activeLink:''} ><Trans i18nKey={"Users"} >{"Users"}</Trans></Link>
                                    <Link to="/enroll" className={window.location.pathname === '/enroll'?classes.activeLink:''} ><Trans i18nKey={"VoicePrints"} >{"VoicePrints"}</Trans></Link>
                                    <Link to="/verify" className={window.location.pathname === '/verify'?classes.activeLink:''} ><Trans i18nKey={"VoiceAuthentication"} >{"VoiceAuthentication"}</Trans></Link>
                                    <Link to="/dashboard" className={window.location.pathname === '/dashboard'?classes.activeLink:''} ><Trans i18nKey={"Analytics"} >{"Analytics"}</Trans></Link>
                                </div>
                            </Grid>
                            <Grid item xs={3} md={2} lg={1} className={"wrapperUserPanelShort pull-right"}  >
                                <UserPanelShort   />
                            </Grid>
                            <Grid item xs={3} md={1} lg={1} className={"wrapperSelectLang"}  >
                                <SelectLang />
                            </Grid>
                        </Grid>
                        <Grid container spacing={0} >
                            <Grid item xs={12} md={"auto"} lg={"auto"} className={"wrapperTopMobileMenu"} >
                                <nav>
                                    <input type="checkbox" id="hamburger1"/>
                                    <label htmlFor="hamburger1"><DehazeIcon />
                                        <ul className="nav-links">
                                            <li><Link to="/"  ><Trans i18nKey={"Users"} >{"Users"}</Trans></Link></li>
                                            <li><Link to="/enroll"  ><Trans i18nKey={"VoicePrints"} >{"VoicePrints"}</Trans></Link></li>
                                            <li><Link to="/verify"  ><Trans i18nKey={"VoiceAuthentication"} >{"VoiceAuthentication"}</Trans></Link></li>
                                            <li><Link to="/dashboard"  ><Trans i18nKey={"Analytics"} >{"Analytics"}</Trans></Link></li>
                                        </ul>
                                    </label>
                                </nav>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
        {this.props.children}
        <footer className={classes.footer}>
            <Container>
                <Grid container spacing={2} >
                    <Grid item xs={2} md={"auto"} lg={"auto"} ><Trans i18nKey={"Copyright"} >{"Copyright"}</Trans></Grid>
                    <Grid item xs={2} lg={2} ></Grid>
                    <Grid item xs={2} md={"auto"} lg={"auto"} style={{textAlign:'right'}} >
                        <Link to="/privacy-policy" style={{marginRight:10}} ><Trans i18nKey={"Privacypolicy"} >{"Privacypolicy"}</Trans></Link>
                        <Link to="/user-agreement" style={{marginRight:10}} ><Trans i18nKey={"Useragreement"} >{"Useragreement"}</Trans></Link>
                        <Link to="/cookies-policy"><Trans i18nKey={"CookiesPolicy"} >{"CookiesPolicy"}</Trans></Link>
                    </Grid>
                </Grid>
            </Container>
        </footer>
        </Wrapper>;
    };
}

MainLayout.propTypes = {
    children: PropTypes.element.isRequired
};

export default withStyles(useStyles)(MainLayout)
