import React, {Component} from "react";
import { useStyles } from './styles';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, withStyles} from "@material-ui/core";
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {ruRU,enUS } from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Client from '../../store/Model/Client';
import apiStore from "../../store/ApiStore";
import moment from "moment";
import {millisecondsToHms, filterEquals} from '../../lib/helper';
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@material-ui/core/TablePagination';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputBase from "@material-ui/core/InputBase/InputBase";
import { useTranslation } from 'react-i18next';
import {Trans, Translation} from "react-i18next";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { getColleagues, actionColleagues, deleteColleagues, addColleague, updateColleague} from '../../actions/colleagueActions'
import { connect } from "react-redux";
import {getVerifys} from "../../actions/verifyActions";
import SearcherField from "../shared/SearcherField/SearcherField";
import Button from "@material-ui/core/Button";
import DateRangeIcon from '@material-ui/icons/DateRange';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import i18n from "../../services/i18n";
import Typography from "@material-ui/core/Typography";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import EditIcon from '@mui/icons-material/Edit';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';

import {NotificationManager} from "react-notifications";
import AdornmentPasswordField from "../LoginForm/AdornmentPasswordField";
import Alert from "@material-ui/lab/Alert/Alert";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const theme = createTheme(
	{
		palette: {
			primary: { main: '#fff' },
		},
		root: {

		},

		cell: {
			border:0
		}
	},
	ruRU,
);

const BootstrapInput = withStyles((theme) => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
}))(InputBase);

class ColleagueList extends Component {

	state = {
		errorRegistr: [],
		loading: false,
		rows: [],
		selectRow: null,
		totalRowCount: 40,
		rowsPerPageOptions: [10, 20, 50, 100],
		pageSize: 10,
		page: 1,
        selectedRows: [],
        arList: [],
		arUsers: [],
		select: null,
		search: null,
		//filter: {
		//	fio: null,
		//	finished: -1,
		//},
		sortBy: 'lastname',
		sortDesc: false,
		error: {
			isErrorDisplayed: false,
			errorMessage: ''
		},
		isOpenAddColleagueDialog: false,
        isOpenEditColleagueDialog: false,
		isOpenDeleteColleagueDialog: false,
		isOpenActivatedColleagueDialog: false,
		isOpenBlockedColleagueDialog: false,
		isOpenReadModeColleagueDialog: false,
        totalColleagueDialog: null,
        textColleagueDialog: null,
		toDateDefault: moment(new Date()).format("YYYY-MM-DD") , //"2024-05-21"
		fromDateDefault: moment(new Date()).format("YYYY-MM-DD"),//"2024-05-21"

		login: null,
		password: null,
		repeatpassword: null,
		status: 'read mode',
		email: null,
		phone: null,

		uid: null,
		user_u: null,
		firstname: null,
		middlename: null,
		lastname: null,
		birthday: null,
		age: null,
		gender: null,
		country: null,
		organization: null,
		workposition: null,
		info: null,
	};

	login = '';
	password = '';

	handleSearchChange = (event) => {
		let { name, value } = event.target;
		// console.log("handleSearchChange: ", value);
		this.setState({search:value, page: 0, sortBy: 'lastname', sortdesc: true});
		this.loadList(value, this.state.fromDate, this.state.toDate);
	};

	handleSearchKeyDown = (event) => {
		let { name, value } = event.target;
		// console.log('handleSearchKeyDown: ', value);
		if (event.key  === "Enter") {
			// console.log('handleSearchKeyDown: Enter');
			// request api with search
			this.setState({search:value});
			this.loadList(value, this.state.fromDate, this.state.toDate);
		}
	};

	handleSearchClick = () => {
		// console.log('handleSearchClick: ', this.state.search);
		// request api with search
		this.loadList(this.state.search, this.state.fromDate, this.state.toDate);
	};

	// add or edit colleague
	handleSubmitAddColleague = (e) => {
		e.preventDefault();
		// console.log("handleSubmitAddColleague, e: ", e);
		const user = {
			uid: this.state.user_u,
			status: this.state.status,
			login: this.state.login,
			password: (this.state.password != null && this.state.password.length > 0) ? this.state.password : null,
			phone: this.state.phone,
			email: this.state.email
		};
		const manager = {
			uid: this.state.uid,
			user_u: this.state.user_u,
			firstname: this.state.firstname,
			middlename: this.state.middlename,
			lastname: this.state.lastname,
			birthday: this.state.birthday,
			age: this.state.age,
			gender: this.state.gender,
			country: this.state.country,
			organization: this.state.organization,
			workposition: this.state.workposition,
			info: this.state.info
		};
		const profile = {user: user, manager: manager};

		if (this.state.isOpenEditColleagueDialog) {
			// console.log("password, repeatpassword: ", this.state.password, this.state.repeatpassword);
			const needCompare = (this.state.password != null && this.state.password.length > 0)
				|| (this.state.repeatpassword != null && this.state.repeatpassword.length > 0);
			const comparing = this.state.password.localeCompare(this.state.repeatpassword);
			// console.log("comparing, needCompare: ", comparing, needCompare);
			if (needCompare && comparing) {
				NotificationManager.error(<Trans i18nKey={"ErrorWithComparePassword"} >{"ErrorWithComparePassword"}</Trans>);
			} else {
				this.update_Colleague(profile, 'add').then(data => {
					// console.log(data);
					if(data.statusCode>300){
						NotificationManager.error(data.message, <Trans i18nKey={"SentMessageError"} >{"SentMessageError"}</Trans>);
					} else {
						NotificationManager.success(<Trans i18nKey={"EditedItem"} >{"EditedItem"}</Trans>);
						this.setState({
							isOpenEditColleagueDialog: false,
							login: null,
							password: null,
							repeatpassword: null,
							status: 'read mode',
							email: null,
							phone: null,

							uid: null,
							user_u: null,
							firstname: null,
							middlename: null,
							lastname: null,
							birthday: null,
							age: null,
							gender: null,
							country: null,
							organization: null,
							workposition: null,
							info: null,
						}, () => {
							this.loadList(this.state.search, this.state.fromDate, this.state.toDate);
						})
					}
				});
			}
		}

		if (this.state.isOpenAddColleagueDialog) {
			const comparing = this.state.password.localeCompare(this.state.repeatpassword);
			// console.log("comparing: ", comparing);
			if (comparing) {
				NotificationManager.error(<Trans i18nKey={"ErrorWithComparePassword"} >{"ErrorWithComparePassword"}</Trans>);
			} else {
				this.add_Colleague(profile, 'add').then(data => {
					//console.log(data);
					if(data.statusCode>300){
						NotificationManager.error(data.message, <Trans i18nKey={"SentMessageError"} >{"SentMessageError"}</Trans>);
					} else {
						this.setState({
							isOpenAddColleagueDialog: false,
							login: null,
							password: null,
							repeatpassword: null,
							status: 'read mode',
							email: null,
							phone: null,

							uid: null,
							user_u: null,
							firstname: null,
							middlename: null,
							lastname: null,
							birthday: null,
							age: null,
							gender: null,
							country: null,
							organization: null,
							workposition: null,
							info: null,
						}, () => {
							this.loadList(this.state.search, this.state.fromDate, this.state.toDate);
							NotificationManager.success(<Trans i18nKey={"AddedNewItem"} >{"AddedNewItem"}</Trans>);
						})
					}
				});
			}
		}
	};

	handleSaveActionDialog = () => {
		// console.log("handleSaveActionDialog, selectedRows ", this.state.selectedRows);
		if (this.state.isOpenDeleteColleagueDialog) {
			this.action_Colleagues('delete', this.state.selectedRows, 'delete').then(data => {
				this.setState({
					selectedRows: [],
					isOpenDeleteColleagueDialog: false,
				}, () => {
					this.loadList(this.state.search, this.state.fromDate, this.state.toDate);
					NotificationManager.success(<Trans i18nKey={"SelectedItemssDeleted"} >{"SelectedItemssDeleted"}</Trans>);
				})
			});
        }
		if (this.state.isOpenReadModeColleagueDialog) {
			this.action_Colleagues('read mode', this.state.selectedRows, 'read mode').then(data => {
				this.setState({
					selectedRows: [],
					isOpenReadModeColleagueDialog: false,
				}, () => {
					this.loadList(this.state.search, this.state.fromDate, this.state.toDate);
					NotificationManager.success(<Trans i18nKey={"SelectedItemssActivated"} >{"SelectedItemssActivated"}</Trans>);
				})
			});
		}
        if (this.state.isOpenBlockedColleagueDialog) {
			this.action_Colleagues('block', this.state.selectedRows, 'has been blocked').then(data => {
				this.setState({
					selectedRows: [],
					isOpenBlockedColleagueDialog: false,
				}, () => {
					this.loadList(this.state.search, this.state.fromDate, this.state.toDate);
					NotificationManager.success(<Trans i18nKey={"SelectedItemssBlocked"} >{"SelectedItemssBlocked"}</Trans>);
				})
			});
        }
        if (this.state.isOpenActivatedColleagueDialog) {
			this.action_Colleagues('activate', this.state.selectedRows, 'has been activated').then(data => {
				this.setState({
					selectedRows: [],
					isOpenActivatedColleagueDialog: false,
				}, () => {
					this.loadList(this.state.search, this.state.fromDate, this.state.toDate);
					NotificationManager.success(<Trans i18nKey={"SelectedItemssActivated"} >{"SelectedItemssActivated"}</Trans>);
				})
			});
        }
	};
	handleCloseDialog = () => {
		// console.log("handleCloseDialog: ");
		this.setState({
			isOpenAddColleagueDialog: false,
			isOpenDeleteColleagueDialog: false,
			isOpenBlockedColleagueDialog: false,
			isOpenActivatedColleagueDialog: false,
            isOpenEditColleagueDialog: false
		});
	};
	handleClickAddColleagueDialog = () => {
        // console.log("handleClickAddColleagueDialog: ");
		this.setState({
			isOpenAddColleagueDialog: true,
			totalColleagueDialog: <Trans i18nKey={"ColleagueAdd"} >{"ColleagueAdd"}</Trans>,
			textColleagueDialog: <Trans i18nKey={"ColleagueAdd"} >{"ColleagueAdd"}</Trans>
		});
	};
	handleClickDeleteColleagueDialog = () => {
		// console.log("handleClickDeleteColleagueDialog: ", this.state.selectedRows);
		this.setState({
			isOpenDeleteColleagueDialog: true,
            totalColleagueDialog: <Trans i18nKey={"ColleaguesDeleting"} >{"ColleaguesDeleting"}</Trans>,
            textColleagueDialog: <Trans i18nKey={"ColleaguesDeletingText"} >{"ColleaguesDeletingText"}</Trans>
		});
	};
	handleClickBlockColleagueDialog = () => {
		// console.log("handleClickBlockColleagueDialog: ", this.state.selectedRows);
		this.setState({
            isOpenBlockedColleagueDialog: true,
            totalColleagueDialog: <Trans i18nKey={"ColleaguesBlocking"} >{"ColleaguesBlocking"}</Trans>,
            textColleagueDialog: <Trans i18nKey={"ColleaguesBlockingText"} >{"ColleaguesBlockingText"}</Trans>
        });
	};
	handleClickActivateColleagueDialog = () => {
		// console.log("handleClickActivateColleagueDialog: ", this.state.selectedRows);
		this.setState({
            isOpenActivatedColleagueDialog: true,
            totalColleagueDialog: <Trans i18nKey={"ColleaguesActivate"} >{"ColleaguesActivate"}</Trans>,
            textColleagueDialog: <Trans i18nKey={"ColleaguesActivateText"} >{"ColleaguesActivateText"}</Trans>
        });
	};
	handleClickReadModeColleagueDialog = () => {
		// console.log("handleClickReadModeColleagueDialog: ", this.state.selectedRows);
		this.setState({
			isOpenReadModeColleagueDialog: true,
			totalColleagueDialog: <Trans i18nKey={"ColleaguesReadMode"} >{"ColleaguesReadMode"}</Trans>,
			textColleagueDialog: <Trans i18nKey={"ColleaguesReadModeText"} >{"ColleaguesReadModeText"}</Trans>
		});
	};

	handleTextChange = (event) => {
		let val = {};
		val[event.target.name] = event.target.value;
		// console.log('handleTextChange: ', val);
		this.setState(val);
	};
/*
	handleSetDateFrom = (value) => {
		// console.log("handleSetDateFrom, value: ", value);
		this.setState({fromDate: value});
		if(new Date(value).getTime() > new Date(this.state.toDate).getTime())  {
			this.setState({toDate: value});
		}
	};
	handleSetDateTo = (value) => {
		// console.log("handleSetDateTo, value: ", value);
		this.setState({toDate: value});
		if(new Date(value).getTime() < new Date(this.state.fromDate).getTime())  {
			this.setState({fromDate: value});
		}
	};*/

	columns = [
        { field: "edit", hide: false,
			headerName: <Trans i18nKey={"tEdit"} >{"tEdit"}</Trans>, width: 70, renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center" style={{ width: 70, cursor: "pointer"}}>
						<Chip index={params.row.id}
							  className={'active'}
							  icon={<EditIcon />}
							  style={{width: 50}}
							  variant="outlined"
							  onClick={ () => {
								  // console.log("handleClicEditColleagueDialog, row: ", params.row);
								  this.setState({
									  isOpenEditColleagueDialog: true,
									  totalColleagueDialog: <Trans i18nKey={"ColleagueUpdated"} >{"ColleagueUpdated"}</Trans>,
									  textColleagueDialog: <Trans i18nKey={"ColleagueUpdated"} >{"ColleagueUpdated"}</Trans>,
									  login: params.row.login,
									  password: null,// params.row.password,
									  repeatpassword: null,// params.row.password,
									  phone: params.row.phone,
									  email: params.row.email,
									  status: params.row.status,
									  status_u: params.row.status_u,

									  uid: params.row.uid,
									  user_u: params.row.user_u,
									  firstname: params.row.firstname,
									  middlename: params.row.middlename,
									  lastname: params.row.lastname,
									  birthday: params.row.birthday,
									  age: params.row.age,
									  gender: params.row.gender,
									  country: params.row.country,
									  organization: params.row.organization,
									  workposition: params.row.workposition,
									  info: params.row.info
								  });
							  }}
						/>
						{/*
							<Button
								id={params.row.id}
								variant={"text"}
								startIcon = {<EditIcon/>}
								onClick={ () => {
									console.log("handleClicEditColleagueDialog, row: ", params.row.uid);
									this.setState({
										isOpenEditColleagueDialog: true,
										EditUid: params.row.uid
									});
								}}
							>
							</Button>
						*/}
                    </div>
                );
            },
            renderHeader: (props) => {
                return  <>
					<span>
                        <Trans i18nKey={"tEdit"} >{"tEdit"}</Trans>
					</span>
                </>
            }
        },
		{ field: "status", headerName: <Trans i18nKey={"Status"} >{"Status"}</Trans>, width: 150, GridColType: 'boolean', renderCell: (params) => {
				switch (params.row.status) {
					case 'active':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
								<Chip index={params.row.id}
									  className={'active'}
									  avatar={<Avatar><ClearRoundedIcon /></Avatar>}
									  variant="outlined"
									  label={<Trans i18nKey={"Enabled"} >{"Enabled"}</Trans>}
								/>
							</div>
						);
					case 'not active':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
								<Chip index={params.row.id}
									  className={'deactive'}
									  avatar={<Avatar><ClearRoundedIcon /></Avatar>}
									  variant="outlined"
									  label={<Trans i18nKey={"Disabled"} >{"Disabled"}</Trans>}
								/>
							</div>
						);
					case 'read mode':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
									<Chip index={params.row.id}
									  className={'active'}
									  avatar={<Avatar><ClearRoundedIcon /></Avatar>}
									  variant="outlined"
									  label={<Trans i18nKey={"ReadMode"} >{"ReadMode"}</Trans>}
								/>
							</div>
						);
					case 'wait approved':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
								<Chip index={params.row.id}
									  className={'active'}
									  avatar={<Avatar><ClearRoundedIcon /></Avatar>}
									  variant="outlined"
									  label={<Trans i18nKey={"Waiting"} >{"Waiting"}</Trans>}
								/>
							</div>
						);
					case 'undefined':
						return (
							<div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
								<Chip index={params.row.id}
									  className={'deactive'}
									  avatar={<Avatar><ClearRoundedIcon /></Avatar>}
									  variant="outlined"
									  label={<Trans i18nKey={"Undefined"} >{"Undefined"}</Trans>}
								/>
							</div>
						);
					default:
						return '';
				}
			},
			renderHeader: (props) => {
				return  <>
					<span  onClick={(event) => this.setState({sortBy: 'status_u', sortDesc: (this.state.sortBy == null ? true : !this.state.sortDesc)}, this.loadList)} >
						<Trans i18nKey={"Status"} >{"Status"}</Trans>
						{this.state.sortBy === 'status_u' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
                </>
			},
			sortable: false
		},
		{ field: "user_u", headerName: <Trans i18nKey={"FIO"} >{"FIO"}</Trans>, width: 300, renderCell: (params) => {
				return [params.row.lastname, params.row.firstname, params.row.middlename]
							.filter(val => val ? val.length>0 : "")
							.join(' ');
			},
			renderHeader: (props) => {
				return  <>
				<span onClick={(event) => this.setState({sortBy: 'lastname', sortDesc: (this.state.sortBy === null ? true : !this.state.sortDesc)}, this.loadList)} >
					<Trans i18nKey={"FIO"} >{"FIO"}</Trans>
					{this.state.sortBy === 'lastname' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
				</span>
				</>
			},
			sortable: false
		},
        { field: "login", headerName: <Trans i18nKey={"Login"} >{"Login"}</Trans>, width: 150, renderCell: (params) => {
                return params.row.login;
            }, sortable: false,
            renderHeader: (props) => {
                return  <>
					<span onClick={(event) => this.setState({sortBy: 'login', sortDesc: (this.state.sortBy === null ? true : !this.state.sortDesc)}, this.loadList)} >
                        <Trans i18nKey={"Login"} >{"Login"}</Trans>
                        {this.state.sortBy === 'login' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
                </>
            }
        },
        { field: "email", headerName: <Trans i18nKey={"Email"} >{"Email"}</Trans>, width: 150, renderCell: (params) => {
                return params.row.email;
            }, sortable: false,
            renderHeader: (props) => {
                return  <>
					<span onClick={(event) => this.setState({sortBy: 'email', sortDesc: (this.state.sortBy === null ? true : !this.state.sortDesc)}, this.loadList)} >
                        <Trans i18nKey={"Email"} >{"Email"}</Trans>
                        {this.state.sortBy === 'email' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
                </>
            }
        },
        { field: "phone", headerName: <Trans i18nKey={"Phone"} >{"Phone"}</Trans>, width: 150, renderCell: (params) => {
                return params.row.phone;
            }, sortable: false,
            renderHeader: (props) => {
                return  <>
					<span onClick={(event) => this.setState({sortBy: 'phone', sortDesc: (this.state.sortBy === null ? true : !this.state.sortDesc)}, this.loadList)} >
                        <Trans i18nKey={"Phone"} >{"Phone"}</Trans>
                        {this.state.sortBy === 'phone' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
                </>
            }
        },
        { field: "date_updated", headerName: <Trans i18nKey={"DateUpdated"} >{"DateUpdated"}</Trans>, width: 150, renderCell: (params) => {
                return moment(params.row.study_time).format("YYYY-MM-DD HH:mm:ss");
            }, sortable: false,
            renderHeader: (props) => {
                return  <>
					<span onClick={(event) => this.setState({sortBy: 'date_updated', sortDesc: (this.state.sortBy === null ? true : !this.state.sortDesc)}, this.loadList)} >
                        <Trans i18nKey={"DateUpdated"} >{"DateUpdated"}</Trans>
                        {this.state.sortBy === 'date_updated' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
                </>
            }
        },
		{ field: "date_created", headerName: <Trans i18nKey={"DateCreated"} >{"DateCreated"}</Trans>, width: 150, renderCell: (params) => {
				return moment(params.row.study_time).format("YYYY-MM-DD HH:mm:ss");
			}, sortable: false,
			renderHeader: (props) => {
				return  <>
					<span onClick={(event) => this.setState({sortBy: 'date_created', sortDesc: (this.state.sortBy === null ? true : !this.state.sortDesc)}, this.loadList)} >
                        <Trans i18nKey={"DateCreated"} >{"DateCreated"}</Trans>
						{this.state.sortBy === 'date_created' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
				</>
			}
		}
	];


	 loadList = async (search = null, fromDate = null, toDate = null) => {
		 // search = search ?? this.state.search;
		await this.props.getColleagues(fromDate, toDate, this.state.page * 1 - 1, this.state.pageSize, search, this.state.sortBy, this.state.sortDesc).items
			 .then(data => this.setState({arList: data.data.map(val=>{ val["id"] = val.uid; return val;}), totalRowCount: data.meta.quantity*1}));
	};

	delete_Colleagues = async (uidsList = null, comment) => {
		return await this.props.deleteColleagues(uidsList, comment).items;
	};

	action_Colleagues = async (action = null, uidsList = null, comment) => {
		//console.log("action_Colleagues, uidsList: ", uidsList);
		return await this.props.actionColleagues(action, uidsList, comment).items;
	};

	update_Colleague = async (profile = null, comment) => {
		return await this.props.updateColleague(profile, comment).items;
	};

	add_Colleague = async (profile = null, comment) => {
		return await this.props.addColleague(profile, comment).items;
	};

	customPagination = () => {
		return (<>
				<div style={{float: 'left'}} >
					<div  >
						<Pagination
							color="primary"
							variant="outlined"
							shape="rounded"
							count={Math.ceil(this.state.totalRowCount / this.state.pageSize)}
							page={this.state.page}
							onChange={(event, value) => {
								this.setState({page: value * 1}, () => this.loadList(this.state.search))
							}}

						/>
					</div>
					<div style={{marginTop: '10px'}} >
                        ({(this.state.page-1) * this.state.pageSize} - {this.state.page * this.state.pageSize < this.state.totalRowCount?(this.state.page * this.state.pageSize): this.state.totalRowCount}) / {this.state.totalRowCount}
					</div>
				</div>
				<div>
					<div style={{color: '#828d99', float:'left', marginRight: 10}}><Trans i18nKey={"NumberRecords"} >{"NumberRecords"}</Trans><br/>
						<Trans i18nKey={"OnPage"} >{"OnPage"}</Trans>:</div>
					<Select
						style={{}}
						value={this.state.pageSize}
						onChange={event=>{
							//console.log("onChange, pageSize: ", event.target.value);
							this.setState({page: 0, pageSize: event.target.value}, () => this.loadList(this.state.search))}
						}
						input={<BootstrapInput />}
					>
						{this.state.rowsPerPageOptions.map(val=><MenuItem value={val} key={"NumberRecords " + val} >{val}</MenuItem>)}
					</Select>
				</div>
			</>
		);
	}

	onFilterModelChange = (model) => {
	//	for(let item of model.items){
	//		console.log(item);
	//	}
	}

	componentDidMount() {
		this.loadList();
	}

	renderAddColleagueDialog = () => {
		const {classes} = this.props;
		return <Dialog
			open={this.state.isOpenAddColleagueDialog || this.state.isOpenEditColleagueDialog}
			onClose={this.handleCloseDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<form onSubmit={this.handleSubmitAddColleague}>
				<DialogTitle id="alert-dialog-title">
					{/*<Trans i18nKey={"ColleagueAdd"} >{"ColleagueAdd"}</Trans> */}
					{this.state.totalColleagueDialog}
				</DialogTitle>
				<DialogContent style={{width:600}}>
					{this.props.errorUpload ? <Alert severity="error" style={{marginBottom: 25}} >{this.props.errorUpload}</Alert>:''}
					<Grid container  spacing={2} >
						<Grid item xs={12} md={4} lg={4} >
							<FormControl className={classes.formControl}>
								<div style={{marginBottom: 10}}>
									<b><Trans i18nKey={"Login"} >Login</Trans>*</b>
								</div>
								<TextField
									required
									error={this.state.errorRegistr['login']}
									helperText={this.state.errorRegistr['login']}
									onChange={this.handleTextChange}
									value={this.state.login}
									style={{marginBottom: 25}}
									variant="outlined"
									id = "login"
									name = "login"
									type = "text"
									fullWidth
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4} lg={4} >
							<FormControl className={classes.formControl}>
								<div style={{marginBottom: 10}}>
									<b><Trans i18nKey={"Password"} >Password</Trans>*</b>
								</div>
								<AdornmentPasswordField
									error={this.state.errorRegistr['password']}
									helperText={this.state.errorRegistr['password']}
									required
									name="password"
									onChange={this.handleTextChange}
									value={this.state.password}
									style={{width:'100%', marginBottom: 25}}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4} lg={4} >
							<FormControl className={classes.formControl}>
								<div style={{marginBottom: 10}}>
									<b><Trans i18nKey={"RepeatPassword"} >RepeatPassword</Trans>*</b>
								</div>
								<AdornmentPasswordField
									error={this.state.errorRegistr['repeatpassword']}
									helperText={this.state.errorRegistr['repeatpassword']}
									required
									name="repeatpassword"
									onChange={this.handleTextChange}
									value={this.state.repeatpassword}
									style={{width:'100%', marginBottom: 25}}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={4} lg={4} >
							<FormControl className={classes.formControl}>
								<div style={{marginBottom: 10}}>
									<b><Trans i18nKey={"LastName"} >LastName</Trans>*</b>
								</div>
								<TextField
									required
									error={this.state.errorRegistr['lastname']}
									helperText={this.state.errorRegistr['lastname']}
									onChange={this.handleTextChange}
									value={this.state.lastname}
									style={{marginBottom: 25}}
									variant="outlined"
									id = "lastname"
									name = "lastname"
									type = "text"
									fullWidth
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4} lg={4} >
							<FormControl className={classes.formControl}>
								<div style={{marginBottom: 10}}>
									<b><Trans i18nKey={"FirstName"} >FirstName</Trans>*</b>
								</div>
								<TextField
									required
									error={this.state.errorRegistr['firstname']}
									helperText={this.state.errorRegistr['firstname']}
									onChange={this.handleTextChange}
									value={this.state.firstname}
									style={{marginBottom: 25}}
									variant="outlined"
									id = "firstname"
									name = "firstname"
									type = "text"
									fullWidth
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4} lg={4} >
							<FormControl className={classes.formControl}>
								<div style={{marginBottom: 10}}>
									<b><Trans i18nKey={"MiddelName"} >MiddelName</Trans></b>
								</div>
								<TextField
									error={this.state.errorRegistr['middlename']}
									helperText={this.state.errorRegistr['middlename']}
									onChange={this.handleTextChange}
									value={this.state.middlename}
									style={{marginBottom: 25}}
									variant="outlined"
									id = "middlename"
									name = "middlename"
									type = "text"
									fullWidth
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={4} lg={4} >
							<FormControl className={classes.formControl}>
								<div style={{marginBottom: 10}}>
									<b><Trans i18nKey={"Status"} >Status</Trans>*</b>
								</div>
								<Select
									key="status"
									id = "status"
									variant={"standard"}
									displayEmpty
									value={this.state.status}
									IconComponent={ExpandMoreIcon}
									style={{marginBottom: 25, width: 165}}
									onChange={event=>{
										this.setState({status: event.target.value});
									}}
									input={<BootstrapInput />}
								>
									<MenuItem value={'active'} key="active" ><Trans i18nKey={"selectActive"}>{"selectActive"}</Trans></MenuItem>
									<MenuItem value={'read mode'} key="read mode" ><Trans i18nKey={"selectReadMode"} >{"selectReadMode"}</Trans></MenuItem>
									<MenuItem value={'not active'} key="not active" ><Trans i18nKey={"selectDisable"} >{"selectDisable"}</Trans></MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4} lg={4} >
							<FormControl className={classes.formControl}>
								<div style={{marginBottom: 10}}>
									<b><Trans i18nKey={"Email"} >Email</Trans>*</b>
								</div>
								<TextField
									required
									error={this.state.errorRegistr['email']}
									helperText={this.state.errorRegistr['email']}
									onChange={this.handleTextChange}
									value={this.state.email}
									style={{marginBottom: 25}}
									variant="outlined"
									id = "email"
									name = "email"
									type = "text"
									fullWidth
								/>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4} lg={4} >
							<FormControl className={classes.formControl}>
								<div style={{marginBottom: 10}}>
									<b><Trans i18nKey={"Phone"} >Phone</Trans>*</b>
								</div>
								<TextField
									required
									error={this.state.errorRegistr['phone']}
									helperText={this.state.errorRegistr['phone']}
									onChange={this.handleTextChange}
									value={this.state.phone}
									style={{marginBottom: 25}}
									variant="outlined"
									id = "phone"
									name = "phone"
									type = "text"
									fullWidth
								/>
							</FormControl>
						</Grid>
					</Grid>
						<div style={{marginBottom: 10}}>
							<b><Trans i18nKey={"Comment"} >Comment</Trans></b>
						</div>
						<TextField
							multiline={true}
							onChange={this.handleTextChange}
							value={this.state.info}
							style={{marginBottom: 25}}
							minRows="3"
							maxRows="3"
							Rows="3"
							variant="outlined"
							id = "info"
							name = "info"
							type = "text"
							fullWidth
						/>
				</DialogContent>
				<DialogActions>
						<Button variant="outlined" onClick={this.handleCloseDialog} color="secondary" >
							<Trans i18nKey={"Close"} >{"Close"}</Trans>
						</Button>
						<Button variant="outlined" type="submit" color="primary" autoFocus>
							<Trans i18nKey={"Save"} >{"Save"}</Trans>
						</Button>
				</DialogActions>
			</form>
		</Dialog>
	};

	renderActionDialog = () => {
		const {classes} = this.props;
		return <Dialog
			open={this.state.isOpenDeleteColleagueDialog || this.state.isOpenActivatedColleagueDialog || this.state.isOpenBlockedColleagueDialog || this.state.isOpenReadModeColleagueDialog}
			onClose={this.handleCloseDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth={'md'}
		>
			<DialogTitle id="alert-dialog-title">
                {/*<Trans i18nKey={"AddColleague"} >{"AddColleague"}</Trans>*/}
                {this.state.totalColleagueDialog}
            </DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
                    {this.state.textColleagueDialog ? this.state.textColleagueDialog : "-"}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" onClick={this.handleCloseDialog} color="secondary" >
					<Trans i18nKey={"Close"} >{"Close"}</Trans>
				</Button>
				<Button variant="outlined" onClick={this.handleSaveActionDialog} color="primary" autoFocus>
					<Trans i18nKey={"Save"} >{"Save"}</Trans>
				</Button>
			</DialogActions>
		</Dialog>
	};

	render() {
		//this.state.rows.slice(0, this.state.pageSize)
		const {classes} = this.props;

		return (
			<>
				{this.renderAddColleagueDialog()}
				{this.renderActionDialog()}

				<h2 style={{marginTop: -30, marginBottom: 20, marginLeft: 20}}>
					<Trans i18nKey={"Colleagues"} >{"Colleagues"}</Trans>
				</h2>
				<SearcherField
					name="searcher"
					label={<Trans i18nKey={"Search"} >{"Search"}</Trans>}
					style={{marginTop: 0, marginLeft: 10, marginBottom: 24, width: '35%'}}
					value={this.state.search}
					onChange={this.handleSearchChange}
					onKeyDown={this.handleSearchKeyDown}
					onClick={this.handleSearchClick}
					isErrorDisplayed={this.state.error.isErrorDisplayed}
					errorMessage={this.state.error.errorMessage}
				/>

				<Button variant="outlined"
						startIcon = {<PersonAddIcon/>}
						style={{marginTop: 0, marginLeft: 40, marginBottom: 24,
							backgroundColor: "#1fb345",
							color: 'white'
						}}
						onClick={this.handleClickAddColleagueDialog} color="primary" >
					<Trans i18nKey={"Add"} >{"Add"}</Trans>
				</Button>
				<Button variant="outlined"
						startIcon = {<PersonRemoveIcon/>}
                        disabled={!(this.state.selectedRows.length > 0)}
                        style={{marginTop: 0, marginLeft: 20, marginBottom: 24,
                            backgroundColor: this.state.selectedRows.length > 0 ? "#a73e3e" : '#fafbfb',
                            color: this.state.selectedRows.length > 0 ? 'white' : '#828d99'
                        }}
						onClick={this.handleClickDeleteColleagueDialog} color="primary" >
					<Trans i18nKey={"Delete"} >{"Delete"}</Trans>
				</Button>
				<Button variant="outlined"
						startIcon = {<PersonOffIcon/>}
                        disabled={!(this.state.selectedRows.length > 0)}
                        style={{marginTop: 0, marginLeft: 10, marginBottom: 24,
							backgroundColor: this.state.selectedRows.length > 0 ? "#3a7ecc" : '#fafbfb',
							color: this.state.selectedRows.length > 0 ? 'white' : '#828d99'
                        }}
						onClick={this.handleClickBlockColleagueDialog} color="primary" >
                    <Trans i18nKey={"Block"} >{"Block"}</Trans>
				</Button>
				<Button variant="outlined"
						startIcon = {<ChromeReaderModeIcon/>}
						disabled={!(this.state.selectedRows.length > 0)}
						style={{marginTop: 0, marginLeft: 10, marginBottom: 24,
							backgroundColor: this.state.selectedRows.length > 0 ? "#81b346" : '#fafbfb',
							color: this.state.selectedRows.length > 0 ? 'white' : '#828d99'
						}}
						onClick={this.handleClickReadModeColleagueDialog} color="primary" >
					<Trans i18nKey={"ReadMode"} >{"ReadMode"}</Trans>
				</Button>
				<Button variant="outlined"
						startIcon = {<SettingsBackupRestoreIcon/>}
                        disabled={!(this.state.selectedRows.length > 0)}
                        style={{marginTop: 0, marginLeft: 10, marginBottom: 24,
							backgroundColor: this.state.selectedRows.length > 0 ? "#75b36c" : '#fafbfb',
							color: this.state.selectedRows.length > 0 ? 'white' : '#828d99'
						}}
						onClick={this.handleClickActivateColleagueDialog} color="primary" >
					<Trans i18nKey={"Activate"} >{"Activate"}</Trans>
				</Button>

				<ThemeProvider
					theme={theme}
				>
					<div className={classes.root} >
						<DataGrid getRowId={(row) => row.uid}
								  loading={this.state.loading}
								  rows={this.state.arList}
								  columns={this.columns}
								  checkboxSelection
								  disableSelectionOnClick
								  components={{
									// FilterPanel: null,
									Pagination: this.customPagination
									}}
								  rowHeight={50}
								  pagination
								  paginationMode="server"
								  onSelectionModelChange={(data) => {
                                		// console.log("onSelectionModelChange, data: ", data);
                                		this.setState({selectedRows: data})
								  }}
								  onPageChange={(data) => {
										this.setState({page: data * 1 }, () => this.loadList(this.state.search));
								  }}
								  onPageSizeChange={(data) => {
									//console.log("pageSize: ", data);
									this.setState({page: 1, pageSize: data}, () => this.loadList(this.state.search));
								  }}
						/>
					</div>
				</ThemeProvider>
			</>
		);
	}
}


const mapStateToProps = state => {
	return state;
}
const mapDispatchToProps = {
	getColleagues,
	actionColleagues,
	deleteColleagues,
	updateColleague,
	addColleague
}

export default withStyles(useStyles)(connect(mapStateToProps, mapDispatchToProps)(ColleagueList));
