import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { user } from "../context/UserContext";
import PublicLayout from "../pages/_layouts/public";
import MainLayout from "../pages/_layouts/main";

export default function RouteWrapper({ component: Component, isPrivate, layout, ...rest }) {
    const isSigned = user.isSignIn(); // True пользователь прошел аунтификацию
    const Layout = layout;//isSigned ? MainLayout : PublicLayout; //MainLayout - основной макет с аунтификацией PublicLayout - публичный макет

    /**
     * Перенаправить пользователя на страницу входа, если он пытается получить доступ к частному маршруту
     */
    if (isPrivate && !isSigned) {
        return <Redirect to="/" />;
    }


    /**
     * Перенаправляем пользователя на желаемый маршрут.
     */
    return (
        <Route
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    //component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
    isPrivate: false
};
