import React,{Component} from "react";
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import bitmap from "../../../static/img/bitmap.png";
import smartphone from "../../../static/img/smartphone.png";
import googleplay from "../../../static/img/google_play.png";
import appstore from "../../../static/img/app_store.png";
import rustore from "../../../static/img/rustore.png";
import rectangle from "../../../static/img/rectangle.png";
import banner_analytic from "../../../static/img/analytics_eng.png";
import banner_voiceprint from "../../../static/img/voiceprint_eng.png";
import banner_voiceverify from "../../../static/img/voiceverify_eng.png";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y  } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import {withStyles} from "@material-ui/core";
import {useStyles} from "../styles";
import {Trans} from "react-i18next";
import {Link} from "react-router-dom";
import banner_userlist from "../../../static/img/userlist_eng.png";
import banner_phones from "../../../static/img/phones.png";
import banner_account from "../../../static/img/account.png";

SwiperCore.use([Navigation]);

class Eng extends Component{


    render() {
        const {classes} = this.props;

        return <div>

            <section className={classes.sectionstartfree}>
                <Container >
                    <Grid container spacing={5} >
                        <Grid item xs={12} md={5} lg={5} >
                            <h1>Voice Authentication</h1>
                            <p>
                                > Forget passwords, just speak<br/>
                                > Text-independent<br/>
                                > Language-independent<br/>
                                > Confirming a person's by voice identity<br/>
                                > Access to confidential information by voice<br/>
                            </p>
                            <br/><br/>
                            <Link to="/register" >
                                <Button  variant="contained" className={classes.startbutton} >
                                    <Trans i18nKey={"SignUp"} >{"SignUp"}</Trans>
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7} style={{textAlign:'right', position: 'relative'}} >
                            <div className={classes.circle4}></div>
                            <div className={classes.circle3}></div>
                            <div className={classes.circle2}></div>
                            <div className={classes.circle1}></div>
                            <img src={bitmap} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider1}>
                <Container >
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                modules={[Navigation, Pagination]}
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation
                                pagination={{ clickable: true }}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle} alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>What is Speaker-ID ?</h1>
                                                <p>
                                                    Speaker-ID is cloud service which it allows to compare two audio records and determine if they correspond to the same person to verify their identity.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle} alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>How does it work ?</h1>
                                                <p>
                                                    Speaker-ID engine is based on duel comparison spectra features of unknown voice with the spectra features of target voice.
                                                    Spectra features are calculated with provision of dynamic determinations of channel distortion level and external hindrances and noises.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={6} lg={6} style={{textAlign:'left', position: 'relative'}} >
                                                <img src={rectangle} alt="" />
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <h1>General Features</h1>
                                                <p>
                                                    > Text-independent and Language-independent<br/>
                                                    > Operation with low level of sinal-noise ratio (SNR)<br/>
                                                    > Fast adaptation to changing of channel distortion and external noises<br/>
                                                    > Not less 15 seconds of voice example for building of voiceprint<br/>
                                                    > Not less 7 seconds of voice example for voice verification<br/>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider3}>
                <Container >
                    <h1>Monitoring of service</h1>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h2>Users monitoring</h2>
                                                <br/>
                                                <p>
                                                    > Monitoring of user list.<br/>
                                                    > Viewing and editing of user profiles.<br/>
                                                    > Blocking and activating of user profiles.<br/>
                                                    > Individual configuration of authentication parameters for each user (setting of thresholds level for FAR, FRR).<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_userlist}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/*
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1}  alt="" />
                                                <h1>Мониторинг регистрации голосовых отпечатков</h1>
                                                <p>
                                                    Мониторинг регистрации голосовых отпечатков зарегистрированных пользователей в системе.
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2}  alt="" />
                                                <h1>Мониторинг регистрации голосовых отпечатков</h1>
                                                <p>
                                                    Прослушивание аудио записи, используемые для построения голосового отпечатка
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h2>Voice prints monitoring</h2>
                                                <br/>
                                                <p>
                                                    > Voiceprints monitoring of users list.<br/>
                                                    > Status monitoring of voiceprints.<br/>
                                                    > Listening to audio records of voiceprints.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_voiceprint}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/*
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1}  alt="" />
                                                <h1>Мониторинг голосовых аутенфикаций</h1>
                                                <p>
                                                    Мониторинг голосовых аутенфикаций зарегистрированных пользователей в системе.
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2}  alt="" />
                                                <h1>Мониторинг голосовых аутенфикаций</h1>
                                                <p>
                                                    Прослушивание аудио записи, используемая для голосовой аутенфикации.
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h2>Voice authentications monitoring</h2>
                                                <br/>
                                                <p>
                                                    > Voice authentications monitoring for registered users.<br/>
                                                    > Listening to audio records of voice authentications.<br/>
                                                    > Blocking a user if phishing is suspected.<br/>
                                                    > Regulated level of threshold for acceptance (FAR) and rejection (FRR) errors.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_voiceverify}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    {/*
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5}  style={{textAlign:'left', position: 'relative'}} >
                                                <img src={banner1}  alt="" />
                                                <h1>Голосовая аналитика</h1>
                                                <p>
                                                    > Стастика количества зарегистрированных пользователей за выбранный период времени.<br/>
                                                    > Стастика регистрации голосовых отпечатков по всем зарегистрированных пользователей за выбранный период времени.<br/>
                                                    > Стастика голосовых аутенфикаций (принятий и отказов) по всем зарегистрированных пользователей за выбранный период времени.
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={5} lg={5} >
                                                <img src={banner2}  alt="" />
                                                <h1>Голосовая аналитика</h1>
                                                <p>
                                                    So you have your new digital camera and clicking away to glory anything and everything in sight. Now you want to print them and you need the best photo
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container> */}
                                    <Container >
                                        <Grid container spacing={4} >
                                            <Grid item xs={"auto"} md={1} lg={1}></Grid>
                                            <Grid item xs={12} md={5} lg={5} style={{textAlign:'left', position: 'relative'}}  >
                                                <h2>Voice analytics</h2>
                                                <br/>
                                                <p>
                                                    > Quantity of registered users for the selected of time period.<br/>
                                                    > Quantity of voiceprints for the selected of time period.<br/>
                                                    > Quantity of voice authentications (acceptances, rejections) for the selected of time period.<br/>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={6} >
                                                <img src={banner_analytic}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                ...
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider2}>
                <Container >
                    <h1>Functional features</h1>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>General Features</h2>
                                                <br/>
                                                <p>
                                                    <li> > Text-independent and language-independent;</li>
                                                    <li> > Not less 15 seconds of voice example for building of voiceprint;</li>
                                                    <li> > Not less 7 seconds of voice example for voice verification;</li>
                                                    <li> > Fast adaptation to changing of channel distortion and external noises;</li>
                                                    <li> > Operation with low level of signal-noise ratio (SNR);</li>
                                                    <li> > Easy integration in customer's mobile app (iOS/Android);</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} md={8} lg={8} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>SDK with Test Projects for Android/iOS platforms</h2>
                                                <br/>
                                                <p>
                                                    <li> > Support for installing the application on Android ver.7.x+, free disk space of at least 100 Mb;</li>
                                                    <li> > Support for installing the application on iOS ver.12.x+, free disk space of at least 100 Mb;</li>
                                                    <li> > Support for network connection with the server within the REST API protocol (with encryption);</li>
                                                    <li> > Registering a new user in the Speaker-ID cloud service;</li>
                                                    <li> > User authorization in the Speaker-ID cloud service using login/password;</li>
                                                    <li> > User authorization in the Speaker-ID cloud service using voice authentication;</li>
                                                    <li> > Demonstration of voice enrollment: recording of audio data with the target user voice;</li>
                                                    <li> > Demonstration of voice authentication: recording of audio data with the user voice;</li>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={4} >
                                                <img src={banner_phones}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} md={11} lg={11} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Web interface of the personal account of the owner of the Speaker-ID service</h2>
                                                <br/>
                                                <p>
                                                    <li> > Supporting of functionality in browsers: Google Chrome, Opera, Fire Fox, Safary;</li>
                                                    <li> > Web interface support in Russian and English languages, possibility to add a new language;</li>
                                                    <li> > Registration of a new owner via the Web interface in the Speaker-ID cloud service;</li>
                                                    <li> > Owner authorization via Web interface in the Speaker-ID cloud service using login/password;</li>
                                                    <li> > View and edit of owner profile;</li>
                                                    <li> > Adding colleagues to owner of the Speaker-ID service with differentiation of access rights (full access, read access, block access);</li>
                                                    <li> > View a list of user profiles registered under owner account of the Speaker-ID service;</li>
                                                    <li> > View and edit user profile, activate, block account of the selected user;</li>
                                                    <li> > View and listen to the list of user's audio recordings used for creation of voiceprint;</li>
                                                    <li> > View and listen to the list of user's audio recordings used for voice authentication;</li>
                                                    <li> > Adding an audio recording with the target user's voice, used for creation of voiceprint;</li>
                                                    <li> > Adding an audio recording with user's voice, used for voice authentication;</li>
                                                    <li> > Monitoring of voiceprints and voice authentications of users;</li>
                                                    <li> > Individual configuration of authentication parameters for each user (setting of thresholds level for FAR, FRR);</li>
                                                    <li> > Analytics of quantity of registered users for the selected of time period;</li>
                                                    <li> > Analytics of quantity of voiceprints for the selected of time period;</li>
                                                    <li> > Analytics of quantity of voice authentications (acceptances, rejections) for the selected of time period;</li>
                                                </p>
                                            </Grid>
                                            <Grid item xs={12} md={1} lg={1} >
                                                <img src={banner_account}  alt="" />
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider2}>
                <Container >
                    <h1>Requirements</h1>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Requirements for setting of mobile app</h2>
                                                <br/>
                                                <p>
                                                    <li> > Android ver.7.x+;</li>
                                                    <li> > iOS ver.12.x+;</li>
                                                    <li> > Availability of free disk space at least 100 Mb;</li>
                                                    <li> > Permission to access the Internet;</li>
                                                    <li> > Permission to access Audio recording;</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Requirements for launching of the Web interface of the personal account of the Speaker-ID service</h2>
                                                <br/>
                                                <p>
                                                    <li> > Minimum PC configuration: (Intel Core, CPU 2, RAM 2Gb, Free disk > 500 Mb);</li>
                                                    <li> > Availability of one of the browsers: Google Chrome, Opera, Yandex, FireFox, Safary;</li>
                                                    <li> > Access to the Internet;</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Requirements for setting of the Speaker-ID service on customer's servers</h2>
                                                <br/>
                                                <p>
                                                    <li> > Availability one or two servers for frontEnd/backEnd API and for the PostgreSQL database;</li>
                                                    <li> > ОS: Debian, Ubinta, Rocky Linux (Centos 8);</li>
                                                    <li> > Minimum configuration: (Core: Intel Core/Xeon 2.1GHz, CPU: 8, RAM: 8Gb, SWAP: 3Gb, DISK: 500Mb);</li>
                                                    <li> > Access to the Internet;</li>
                                                    <li> > Network speed from 100 Mbps;</li>
                                                    <li> > Access to the server to ssh, sftp protocols;</li>
                                                    <li> > Availability ports: 22, 80, 443, 3000, 3001, 5432;</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Requirements for operation</h2>
                                                <br/>
                                                <p>
                                                    <li> > Experience with mobile applications;</li>
                                                    <li> > Experience with web applications;</li>
                                                    <li> > Experience in developing of native applications on iOS (swift), Android (kotlin);</li>
                                                    <li> > Experience in deploying and administation of Linux-based OS;</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionslider2}>
                <Container >
                    <h1>Availability</h1>
                    <Grid container spacing={2} >
                        <Grid item xs={12} md={12} lg={12} >
                            <Swiper
                                className={classes.swiper}
                                spaceBetween={50}
                                slidesPerView={1}
                                navigation={true}
                            >
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Delivery options</h2>
                                                <br/>
                                                <p>
                                                    <li> > Option 1. By registering in the Speaker-ID cloud service;</li>
                                                    <li> > Option 2. Through full installation and deployment of the Speaker-ID service on the customer's servers;</li>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Container >
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12} lg={12} style={{textAlign:'left', paddingLeft: 30, position: 'relative'}}  >
                                                <h2>Licensing options</h2>
                                                <br/>
                                                <p>
                                                    <table style={{border: 'solid 1px #2277b7', width: '100%'}}>
                                                        <tr style={{backgroundColor: '#e8e9e9', height: 20}}>
                                                            <td style={{width: '10%', textAlign: 'center' }}> Option </td>
                                                            <td style={{width: '15%', textAlign: 'center' }}> Availability </td>
                                                            <td style={{width: '30%', textAlign: 'center' }}> Cost </td>
                                                            <td style={{width: '22%', textAlign: 'center' }}> Delivery </td>
                                                            <td style={{width: '23%', textAlign: 'center' }}> Conditions </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{textAlign: 'center' }}> 1 </td>
                                                            <td> No restrictions </td>
                                                            <td>0.60 rub. - for each created of voiceprint.<br/>
                                                                0.30 rub. - for each voice verification.
                                                            </td>
                                                            <td>Packet of Speaker-ID Mobile SDK(Android/iOS):<br/>
                                                                - Test projects: iOS(swift) under xCode IDE, Android(kotlin) under IDE Android Studio,<br/>
                                                                - API description under iOS/Android test projects.</td>
                                                            <td> Positive balance in your personal account of the Speaker-ID cloud service </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{textAlign: 'center' }}> 2 </td>
                                                            <td> No restrictions </td>
                                                            <td> By requirement </td>
                                                            <td>Packet of Speaker-ID Mobile SDK(Android/iOS):<br/>
                                                                - Test projects: iOS(swift) under xCode IDE, Android(kotlin) under IDE Android Studio,<br/>
                                                                - API description under iOS/Android test projects.<br/>
                                                                Installation and deployment of the Speaker-ID cloud service on the customer's servers.
                                                            </td>
                                                            <td> Full prepayment</td>
                                                        </tr>
                                                    </table>

                                                </p>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </SwiperSlide>
                            </Swiper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <section className={classes.sectionmarket}>
                <Container >
                    <Grid container spacing={5} >
                        <Grid item xs={12} md={5} lg={5} >
                            <h2>To test now "Speaker-id check" app</h2>
                            <br/><br/>
                            <a href="https://play.google.com/store/apps/details?id=com.grittec.speakerid" target="_blank" rel="noreferrer" >
                                <img src={googleplay} alt="" />
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <a href="https://apps.rustore.ru/app/com.grittec.speakerid" target="_blank" rel="noreferrer" >
                                <img src={rustore} alt="" />
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <img src={appstore} alt="" />
                            <br/><br/>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7} style={{textAlign:'right', position: 'relative'}} >
                            <img src={smartphone} alt="" />
                        </Grid>
                    </Grid>
                </Container>
            </section>
        </div>
    };
}

export default withStyles(useStyles, {withTheme: true})(Eng);