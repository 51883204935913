import React,{Component} from "react";
import Grid from '@material-ui/core/Grid';
import { NotificationManager } from 'react-notifications';
import { withStyles } from '@material-ui/core/styles';
import {UserContext} from "../../context/UserContext";
import Typography from "@material-ui/core/Typography";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Container from '@material-ui/core/Container';
import InfoIcon from '@material-ui/icons/Info';
import UserList from '../../components/UserList';
import UserProfile from '../../components/UserProfile';
import {StyledTableCell, StyledTableRow, useStyles} from './styles';
import Switch from '@material-ui/core/Switch';
import AddPhotoDropzoneArea from '../../components/shared/AddPhotoDropzoneArea';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';

import Box from '@material-ui/core/Box';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import {ruRU} from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import CheckRoundedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import DoneIcon from '@material-ui/icons/Done';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import apiStore from '../../store/ApiStore';
import Client from '../../store/Model/Client';
import moment from 'moment';
import 'moment/locale/ru';
import TablePagination from '@material-ui/core/TablePagination';
import {Trans} from "react-i18next";
import TextField from "@material-ui/core/TextField/TextField";
import SearchIcon from '@material-ui/icons/Search';
moment.locale('ru');



const columns = [
    { field: "id", hide: true },
    { field: "col1", headerName: "ФИО", width: 200 },
    { field: "col2", headerName: "Количество аутенфикаций", width: 150, align: 'center' },
    { field: "col3", headerName: "Статус", width: 150, GridColType: 'boolean', renderCell: (params) => {
            return (
                <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                    <Chip index={params.row.id}
                          className={params.row.col3?'active':'deactive'}
                          avatar={<Avatar>{params.row.col3?
                              <CheckRoundedIcon />:<ClearRoundedIcon />}
                          </Avatar>}
                          variant="outlined" label={params.row.col3?
                        <Trans i18nKey={"Active"} >{"Active"}</Trans>:
                        <Trans i18nKey={"Block"} >{"Block"}</Trans>}
                    />
                </div>
            );
        } }
];
const theme = createTheme(
    {
        palette: {
            primary: { main: '#1976d2' },
        },
        root: {
            border:0
        },
        '& .MuiDataGrid-row:nth-child(odd)': {
            backgroundColor: 'white'
        },
        cell: {
            border:0
        }
    },
    ruRU,
);


class Users extends Component{

    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
            arClients: [],
            selectUser: null,
            page: 0,
            pageSize: 10,
            enroll: [],
            thresholds: null,
            reload: false,
        };
    }


    render() {
        let self = this;
        return <UserContext.Consumer>
            {
                ({user}) => self._render()
            }
        </UserContext.Consumer>;
    }
    reloadUser = async (data) => {
        this.setState({selectUser: null, enrol: [], totalRows: 0}, async () => {
            let uid = data.pop();
            let client = [];
            let enrol = [];
            let verify = [];
            let totalRows = 0;
            let totalRowsVerify = 0;
            let thresholds = null;
            //apiStore.getUserAvatar(uid).then(data=>console.log(data));
            await apiStore.getClientProfileByUid(uid).then(data => {
                client = data;
                apiStore.getVoiceEnroll(uid).then( data => {
                    enrol = data.data; totalRows = data.meta?.quantity;
                    apiStore.getVoiceVerifys(uid).then( data => {
                        verify = data.data; totalRowsVerify = data.meta?.quantity;
                        apiStore.getVerifyThresholds(uid).then(data => {
                            thresholds = data;
                            this.setState({
                                selectUser: new Client(client),
                                enrol: enrol,
                                totalRows: totalRows,
                                verify: verify,
                                totalRowsVerify: totalRowsVerify,
                                thresholds: thresholds,
                                reload: new Date()
                            });
                        });
                    });
                });
            });
        });
    };

    filterUser = (filter) => {

    };

    _render() {
        const { classes } = this.props;
        return (<div className={classes.root} style={{minHeight: "calc(100vh - 117px)"}}>
            <Container>
                     <Grid container spacing={0}  >

                         <Grid item xs={12} md={6} lg={6} style={{backgroundColor: '#f7f9fb',
                             paddingTop: 24,
                             paddingBottom: 54,
                             paddingLeft: 12,
                             totalRows: 0,
                             paddingRight: 12}}
                         >
                            <UserList
                                onSelectUser={this.reloadUser}
                                onFilterUser={this.filterUser}
                                reload={this.state.reload}
                            />
                         </Grid>

                         <Grid item xs={12} md={6} lg={6} style={{padding: 20,  boxShadow: ' -2px 0px 10px 0px rgba(0, 0, 0, 0.1)'}}>
                             {this.state.selectUser ?
                                 <UserProfile
                                     thresholds={this.state.thresholds}
                                     enrol={this.state.enrol}
                                     verify={this.state.verify}
                                     totalRowsVerify={this.state.totalRowsVerify}
                                     totalRowsEnroll={this.state.totalRows}
                                     reload={(uid) => {
                                        this.reloadUser([uid]);
                                    }}
                                    user={this.state.selectUser}
                                    update={(uid, data) => {
                                        let usetTmp = {};
                                        let oldUser = this.state.selectUser.old;
                                        //console.log("update client profile: ", data);
                                        if(data) {
                                     data.map((val, index) => {
                                         if(val['value'] != null && val['value'] !== ''){
                                             usetTmp[val['name']] = val['value'];
                                             return val;
                                         }
                                         if(val['name'] !== "uid" && oldUser.hasOwnProperty(val['name']) && oldUser[val['name']] !== val['value']) {
                                             usetTmp[val['name']] = val['value'];
                                         }
                                         if(val['name'] !== "uid" && oldUser?.info.hasOwnProperty(val['name']) && oldUser.info[val['name']] !== val['value']) {
                                             usetTmp[val['name']] = val['value'];
                                         }
                                         if(val['name'] !== "uid" && oldUser?.info?.client && oldUser?.info?.client.hasOwnProperty(val['name']) && oldUser.info.client[val['name']] == null) {
                                             oldUser.info.client[val['name']] = '';
                                         }
                                         if(val['name'] !== "uid" && oldUser?.info?.client.hasOwnProperty(val['name']) && (oldUser.info.client[val['name']] !== val['value'])) {
                                             usetTmp[val['name']] = val['value'];
                                         }
                                         /*if(val['name'] != "uid" && oldUser?.info.client.hasOwnProperty(val['name'])) {
                                              console.log(val['name']);
                                              console.log(val['value']);
                                              console.log(oldUser.info.client[val['name']]);
                                          }
                                          console.log(val);*/
                                         return val;
                                     });
                                 }
                                        // TODO: Attention to the format data !!!
                                        // Необходмиомо передавать только те поля, которые заполенны
                                        let user = {
                                     "client": {
                                         "uid" : oldUser.info.client.uid,
                                         /*"firstname": usetTmp['firstname'],
                                         "middlename": usetTmp['middlename'],
                                         "lastname": usetTmp['lastname'],
                                         "birthday": Number(usetTmp['birthday']),
                                         "age": Number(usetTmp['age']),
                                         "gender": Number(usetTmp['gender']),
                                         "height": Number(usetTmp['height']),
                                         "weight": Number(usetTmp['weight']),
                                         "nationality": usetTmp['nationality'],
                                         "placebirth": usetTmp['placebirth'],
                                         "citizenship": usetTmp['citizenship'],
                                         "location": usetTmp['location'],
                                         "passport_no": usetTmp['passport_no'],
                                         "passport_info": usetTmp['passport_info'],
                                         "driver_no": usetTmp['driver_no'],
                                         "insurance": usetTmp['insurance'],
                                         "info": usetTmp['info'],*/
                                     },
                                     "user": {
                                         "uid" : uid,
                                         //"password": usetTmp['password'],
                                     //    "pushtoken": usetTmp['pushtoken']
                                     }
                                 };
                                        /*if(typeof usetTmp['login'] != "undefined"){
                                     user["user"]['login'] = usetTmp['login'];
                                 }*/
                                        if(typeof usetTmp['email'] != "undefined"){
                                     user["user"]['email'] = usetTmp['email'];
                                 }
                                        if(typeof usetTmp['phone'] != "undefined"){
                                     user["user"]['phone'] = usetTmp['phone'];
                                 }
                                        if(typeof usetTmp['password'] != "undefined"){
                                     user["user"]['password'] = usetTmp['password'];
                                 }
                                        if(typeof usetTmp['firstname'] != "undefined"){
                                     user["client"]['firstname'] = usetTmp['firstname'];
                                 }
                                        if(typeof usetTmp['middlename'] != "undefined"){
                                     user["client"]['middlename'] = usetTmp['middlename'];
                                 }
                                        if(typeof usetTmp['lastname'] != "undefined"){
                                     user["client"]['lastname'] = usetTmp['lastname'];
                                 }
                                        if(typeof usetTmp['birthday'] != "undefined"){
                                     const st = usetTmp['birthday'].toString();// "2013-04-13";
                                     user["client"]['birthday'] = new Date(st);
                                     // console.log("birthday: ", user["client"]['birthday']);
                                 }
                                        if(typeof usetTmp['age'] != "undefined"){
                                     user["client"]['age'] = Number(usetTmp['age']);
                                 }
                                        if(typeof usetTmp['gender'] != "undefined"){
                                     user["client"]['gender'] = Number(usetTmp['gender']);
                                 } else {
                                            user["client"]['gender'] = 0;
                                        }
                                        if(typeof usetTmp['height'] != "undefined"){
                                     user["client"]['height'] = Number(usetTmp['height']);
                                 }
                                        if(typeof usetTmp['weight'] != "undefined"){
                                     user["client"]['weight'] = Number(usetTmp['weight']);
                                 }
                                        if(typeof usetTmp['nationality'] != "undefined"){
                                     user["client"]['nationality'] = usetTmp['nationality'];
                                 }
                                        if(typeof usetTmp['placebirth'] != "undefined"){
                                     user["client"]['placebirth'] = usetTmp['placebirth'];
                                 }
                                        if(typeof usetTmp['citizenship'] != "undefined"){
                                     user["client"]['citizenship'] = usetTmp['citizenship'];
                                 }
                                        if(typeof usetTmp['location'] != "undefined"){
                                     user["client"]['location'] = usetTmp['location'];
                                 }
                                        if(typeof usetTmp['passport_no'] != "undefined"){
                                     user["client"]['passport_no'] = usetTmp['passport_no'];
                                 }
                                        if(typeof usetTmp['passport_info'] != "undefined"){
                                     user["client"]['passport_info'] = usetTmp['passport_info'];
                                 }
                                        if(typeof usetTmp['driver_no'] != "undefined"){
                                     user["client"]['driver_no'] = usetTmp['driver_no'];
                                 }
                                        if(typeof usetTmp['insurance'] != "undefined"){
                                     user["client"]['insurance'] = usetTmp['insurance'];
                                 }
                                        if(typeof usetTmp['info'] != "undefined"){
                                     user["client"]['info'] = usetTmp['info'];
                                 }
                                        if(typeof usetTmp['created'] != "undefined"){
                                     user["client"]['created'] = usetTmp['created'];
                                 }
                                        if(typeof usetTmp['updated'] != "undefined"){
                                     user["client"]['updated'] = usetTmp['updated'];
                                 }
                                        // console.log(user["user"]);
                                        // console.log(user["client"]);
                                        if(Object.keys(user["user"]).length>1 || Object.keys(user["client"]).length>1) {
                                     apiStore.updateClientProfile(user).then(async (data) => {
                                         const userData = Object.assign({}, data?.client, data?.user);
                                         await apiStore.getClientProfileByUid(uid).then(data => {
                                             let client = new Client(data);
                                             this.setState({selectUser: client});
                                         });

                                     });
                                 }
                             }}
                                 />
                             :''}
                         </Grid>
                     </Grid>
            </Container>
        </div>);
    }
}

export default withStyles(useStyles)(Users);
