import HttpAdapter from './Adapter/Http/HttpAdapter';
import {config} from '../../src/config';
import {NotificationManager} from "react-notifications";
import {Trans} from "react-i18next";
import React from "react";
//import {user} from "../context/UserContext";


class UserStore {
    _isSignIn = false;
    userInfo = {};
    firstname = null;
    secondName = null;
    lastName = null;
    login = null;
    email = null;
    phone = null;
    type = '';
    pict = null;
    access_token = null;
    refresh_token = null;
    expired_access = null;
    expired_refresh = null;
    showProfile = false;
    _showSettings = false;
    lang = localStorage.getItem("lang");
    intervalRefreshRef = null;

    constructor (initialState, adapter) {
        this.state = initialState;
        this.adapter = adapter;
        this.access_token = localStorage.getItem('access_token');
        this.refresh_token = localStorage.getItem('refresh_token');
        this.expired_access = localStorage.getItem('expired_access');
        this.expired_refresh = localStorage.getItem('expired_refresh');

        if(localStorage.getItem('userInfo')) {
            const user_info = JSON.parse(localStorage.getItem('userInfo'));
            // console.log("UserStore, localStorage.getItem(userInfo): ", user_info);
            this.setUserInfo(user_info);
        }

        this._isSignIn = this.access_token ? true : false;
        this.lang = (navigator.language || navigator.userLanguage).split('-').shift();
        //console.log(this.lang);
        //console.log(localStorage.getItem("lang"));
        this.lang = localStorage.getItem("lang") || this.lang;
    };


    registrUser(user){
        let data = {
            manager: {
                firstname: user.firstname,
                middlename: user.middlename,
                lastname: user.lastname,
                country: user.country,
                organization: user.organization,
                workposition: user.workposition,
                fieldactivity: user.fieldactivity,
                info: user.info,
            },
            user: {
                login: user.login,
                password: user.password,
                passwordrepeat: user.passwordrepeat,
                email: user.email,
                phone: user.phone,
            }
        };
        return this.adapter.post('/api/user/create/manager/', JSON.stringify(data), { 'access-token' : this.getAccessToken(), 'Content-Type' : 'application/json'}).then(response=>response.json());
    };

    updateUser(user){
        let data = {
            manager: {
                firstname: user.firstname,
                middlename: user.middlename,
                lastname: user.lastname,
                birthday: user.birthday,
                age: user.age,
                gender: user.gender,
                country: user.country,
                organization: user.organization,
                workposition: user.workposition,
                fieldactivity: user.fieldactivity,
                info: user.info,
            },
            user: {
                login: user.login,
                password: user.password,
                email: user.email,
                phone: user.phone,
            }
        };
        // console.log("updateUser, data: ", data);
        return this.adapter.post('/api/user/update/manager/', JSON.stringify(data), { 'access-token' : this.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((result) => {
                return result;
            });
    };

    userActivate(code){
        return this.adapter.get('/api/user/activate?token=' + code, { 'access-token' : this.getAccessToken(), 'Content-Type' : 'application/json'});
    };

    getLogin(){
        return this.login;
    };

    setState (state) {
        this.state = state;
    };

    getState () {
        return this.state;
    };

    isSignIn(){
        this.setupRefreshToken();
        return this.access_token ? true : false;
    };

    getRefreshToken = () => {
        return this.refresh_token;
    };

    getAccessToken(){
        return this.access_token;
    };

    getErrorMessage(){
        return this.errorMessage
    };

    setLang = (lang) => {
        this.lang  = lang;
        localStorage.setItem("lang", lang);
        return this;
    };

    getLang = () => {
        return this.lang;
    };

    signIn(login, password) {
        return this.adapter.post('/api/login/manager/', JSON.stringify({login: login, password: password}), { 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                // console.log("signIn, data: ", data);
                if(data.statusCode>300) {
                    NotificationManager.error(data.message, <Trans i18nKey={"SentMessageError"}>{"SentMessageError"}</Trans>);
                } else {
                    this.access_token = data.access_token ? data.access_token : null;
                    this.expired_access = data.expired_access ? data.expired_access : null;
                    this.expired_refresh = data.expired_refresh ? data.expired_refresh : null;
                    this.login = data.login ? data.login : null;
                    this.refresh_token = data.refresh_token ? data.refresh_token : null;
                    if(this.access_token)
                        localStorage.setItem('access_token', this.access_token);
                    if(this.refresh_token)
                        localStorage.setItem('refresh_token', this.refresh_token);
                    localStorage.setItem('expired_access', this.expired_access);
                    localStorage.setItem('expired_refresh', this.expired_refresh);
                    this._isSignIn = this.access_token ? true : false;
                    this.setupRefreshToken();
                }
                return data;
            });
    }

    setUserInfo(userInfo){
        // console.log("userStore, setUserInfo, userInfo: ", userInfo);
        if(!userInfo)
            return true;
        if(userInfo) {
            if (userInfo.manager) {
                this.firstname = userInfo.manager.firstname;
                this.secondName = userInfo.manager.middlename;
                this.lastName = userInfo.manager.lastName;
                this.birthday = userInfo.manager.birthday;
                this.age = userInfo.manager.age;
                this.gender = userInfo.manager.gender;
                this.country = userInfo.manager.country;
                this.organization = userInfo.manager.organization;
                this.workposition = userInfo.manager.workposition;
                this.fieldactivity = userInfo.manager.fieldactivity;
                this.info = userInfo.manager.info;
                //this.parent_uid = userInfo.manager.parent_uid;
            }

            if (userInfo.user) {
                this.login = userInfo.user.login;
                this.email = userInfo.user.email;
                this.phone = userInfo.user.phone;
            } else {
                this.login = userInfo.login;
                this.email = userInfo.email;
                this.phone = userInfo.phone;
            }
        }
        // more info
        this.userInfo = userInfo;
        return true;
    }

    getUserInfo(){
        return this.userInfo;
    };

    isParentManager() {
        // console.log("userStore, isParentManager, userInfo: ", this.userInfo);
        return this.userInfo.manager && this.userInfo.manager.parent_uid;
    }

    isReadingManager() {
        return this.userInfo.statusUser && this.userInfo.statusUser.name.localeCompare("active") !== 0;
    }

    saveUserInfo(userInfo){
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
    };

    showUserSettings = () => {
        this._showSettings = true;
    };

    hideUserSettings = () => {
        this._showSettings = false;
    };

    signOut(){
       // console.log("signOut, refresh-token: " + this.getRefreshToken());

        clearInterval(this.intervalRefreshRef);
        return this.adapter.get('/api/login/out/', { 'refresh-token' : this.getRefreshToken()}).then((data) => {
            localStorage.setItem('userInfo', '');
            localStorage.setItem('access_token', '');
            localStorage.setItem('refresh_token', '');
            this.refresh_token = null;
            this.access_token = null;
        });
    };

    refreshToken = () => {
        // console.log("signOut, refresh-token: " + this.getRefreshToken());

        this.adapter.get('/api/login/refresh/', { 'refresh-token' : this.getRefreshToken()}).then(data => data.json())
        .then((data) => {
            if(data) {
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                this.access_token = data.access_token;
                this.refresh_token = data.refresh_token;
            }else {
                console.warn('refresh empty');
            }
        });

    };

    getRefreshTime = () => {
        let time = this.expired_access * 0.9;
        return time === 0 ? 3600 * 1000 : time * 1000;
    };

    setupRefreshToken = () => {
        if(this.intervalRefreshRef === null)
            this.intervalRefreshRef = setInterval(()=>{this.refreshToken()}, this.getRefreshTime());
    };

    getInfo(uid = null){
        if(uid)
            uid = '?user_u=' + uid;
        else
            uid = '';

        return this.adapter.get('/api/user/get' + uid, { 'access-token' : this.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json())
            .then((data) => {
                return data;
            });
    };

    updateManager(userInfo){
        return this.adapter.post('/api/user/get', JSON.stringify(userInfo), { 'access-token' : this.getAccessToken(), 'Content-Type' : 'application/json'})
            .then(response=>response.json());
    };


    addUser(user){
        return this.adapter.post('/api/user/create/manager/', JSON.stringify(user), { 'access-token' : this.getAccessToken(), 'Content-Type' : 'application/json'}).then(response=>response.json()).then((data) => {
            return data;
        });
    };


    forceSignOut(){
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('access_token');
        localStorage.removeItem('userInfo');
        this.access_token = null;
        this.refresh_token = null;
        this.userInfo = null;
    };


}

const userStore = new UserStore({}, new HttpAdapter());
export default userStore;
