import styled from "styled-components";
import right from '../../static/img/icons-containers-36-px.png';
import left from '../../static/img/icons-containers-36-px_2.png';


export  const useStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    buttonenter: {
        cursor: 'pointer',
        boxShadow: 'none',

        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        backgroundColor:'#2277b7',
        textTransform: 'none',
    },
    selectlanguage: {
        width: 120,
        height: 40,
        margin: '0 24px 7px',
        //padding: '9px 8px',
        borderRadius: 6,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.07)',
        border: 'solid 1px #dfe3e5',
        '&:before' : {
            borderBottom:0
        },
        '&:hover:before' : {
            borderBottom:0
        }
    },
    startbutton: {
        fontFamily: 'Roboto',
        fontSize: 18,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.33,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        backgroundColor:'#f18c47',
        border:0,
        padding: '10px 20px',
        boxShadow: 0
    },
    sectionstartfree: {
        backgroundColor:'#fafbfb',
        paddingTop:160,
        paddingBottom:80,
        position: 'relative',
        overflow: 'hidden'
    },
    sectionmarket: {
        backgroundColor: 'rgba(80, 144, 221, 0.04)',
        paddingTop:40,
        paddingBottom:40,
        position: 'relative',
        overflow: 'hidden'
    },
    circle4: {
        position: 'absolute',
        top: -350,
        right: -300,
        width: 1400,
        height: 1400,

        backgroundColor: 'rgba(80, 144, 221, 0.04)',
        borderRadius: 700
    },
    circle3: {
        position: 'absolute',
        top: -150,
        right: -100,
        width: 1000,
        height: 1000,

        backgroundColor: 'rgba(80, 144, 221, 0.04)',
        borderRadius: 500
    },
    circle2: {
        position: 'absolute',
        top: 40,
        right: 100,
        width: 600,
        height: 600,
        backgroundColor: 'rgba(80, 144, 221, 0.04)',
        borderRadius: 300
    },
    circle1: {
        position: 'absolute',
        top: 130,
        right: 180,
        width: 400,
        height: 400,

        backgroundColor: 'rgba(80, 144, 221, 0.04)',
        borderRadius: 200
    },
    swiper: {
        '& .swiper-button-next':{
            width: 36,
            height: 36
        },
        '& .swiper-button-next:after':{
            content: "' '",
            background: 'transparent url(' + right + ') right center no-repeat;',
            width: 36,
            height: 36
        },
        '& .swiper-button-prev':{
            width: 36,
            height: 36
        },
        '& .swiper-button-prev:after':{
            content: "' '",
            background: 'transparent url(' + left + ') left center no-repeat;',
            width: 36,
            height: 36
        }

    },
    sectionslider1: {
        backgroundColor:'#fff',
        paddingTop:80,
        paddingBottom:30,
        position: 'relative',
        overflow: 'hidden',
        borderBottom: 'solid 1px #dfe3e5'
    },
    sectionslider2: {
        backgroundColor:'#fff',
        paddingTop:30,
        paddingBottom:20,
        position: 'relative',
        overflow: 'hidden',
        borderBottom: 'solid 1px #dfe3e5'
    },
    sectionslider3: {
        backgroundColor:'#fff',
        paddingTop:30,
        paddingBottom:50,
        position: 'relative',
        overflow: 'hidden',
        borderBottom: 'solid 1px #dfe3e5'
    },
});
