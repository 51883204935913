import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import apiStore from "../../store/ApiStore";

const useStyles = {
  root: {
    border:0,
  },
  icon: {
      cursor: 'pointer',
      zIndex: 10
  },
  progressBarWrapper: {
      backgroundColor: '#e3eaf0',
      height: 39,
      width: '100%',
      borderRadius: 5
  },
  progressBar: {
      height: 39,
      backgroundColor: '#b4d0e6',// '#c2e692',
      width: '0%',
      borderRadius: 5
  },
  name:{
      zIndex: 10,
      marginLeft: 10
  }
};

class Audio extends Component {

  state = {
    play: false
  };


  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      file: [],
      play: false,
      stop: false,
      data: null,
      progress:0,
      isErrorDisplayed: this.props.isErrorDisplayed,
      errorMessage: this.props.errorMessage
    };
    this.ref = React.createRef();
    this.refProgress = React.createRef();
  }

    blobToBase64Async = async (blob) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onerror = (e) => reject(fileReader.error);
            fileReader.onloadend = (e) => {
                const dataUrl = fileReader.result;
                // remove "data:mime/type;base64," prefix from data url
                const base64 = "data:audio/wav;base64," + dataUrl.substring(dataUrl.indexOf(',') + 1);
                resolve(base64);
            };
            fileReader.readAsDataURL(blob);
        });
    };

  load = async () => {
      if(!this.state.data) {
          if(this.props.type === 'enroll') {
              await apiStore.getEnrollFile(this.props.file.uid).then(data => data.blob()).then(data => {
                  // console.log(data.blob);
                  return this.blobToBase64Async(data);
              }).then(data => this.setState({data: data}));
          }
          else {
              await apiStore.getVerifyFile(this.props.file.uid).then(data => data.blob()).then(data => {
                  // console.log(data.blob);
                  return this.blobToBase64Async(data);
              }).then(data => this.setState({data: data}));
          }
      }
  };

  componentDidMount(): void {
    //this.load();
  }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isErrorDisplayed !== this.props.isErrorDisplayed) {
            this.setState({
                isErrorDisplayed: this.props.isErrorDisplayed,
                errorMessage: this.props.errorMessage
            })
        }
        // At on change
        if (prevProps.file.uid !== this.props.file.uid || prevProps.file.link !== this.props.file.link) {
            // reset setting
            this.setState({
                data: null,
                play: false,
                loading: false
            });
        }
    }

  _renderAudio = () => {
      //var audio = document.getElementById('audio');
      //audio.src = this.state.data;
      //audio.play();
      const {classes} = this.props;
      let self = this;
      let audioEl = this.ref.current;
      let progressBar = this.refProgress.current;
      if(this.state.data) {
          let canvasWidth = 100;
          //let audioEl = document.getElementById("audio");
          //let canvas = document.getElementById("progress").getContext('2d');
          //let ctrl = document.getElementById('audioControl');

          if(audioEl){
              audioEl.addEventListener('timeupdate', function() {
                  let duration = audioEl.duration;
                  let currentTime = audioEl.currentTime;
                  let percentage = currentTime / duration;
                  let progress = Math.ceil(canvasWidth * percentage);
                  progressBar.style.width = progress + "%";

                  // console.log("progressBar: ", percentage);
                  // if playing has been finished then to set stoped a audio file
                  if(percentage === 1) {
                      audioEl.currentTime = 0;
                      audioEl.pause();
                      self.setState({play: false, stop: true});
                  }
              });

              if(this.state.play) {
                  audioEl.play();
              } else {
                  audioEl.pause();
              }

              if(this.state.stop) {
                  audioEl.currentTime = 0;
                  audioEl.pause();
                  this.setState({loading: false, data: null});
              }
          }

      }
      let link = this.props.file.link ? this.props.file.link.split('/').pop() : '';

      return this.state.data?
          <div {...this.props}>
              <div  className={classes.progressBarWrapper}  >
                  <div id="progressBar" ref={this.refProgress}
                       className={classes.progressBar}
                  >
                  </div>
              </div>
              <audio id="audio"
                     src={this.state.data}
                     ref={this.ref}
              >
              </audio>
          </div>
          : link;
  };


  render() {
    //this.state.rows.slice(0, this.state.pageSize)
      if(this.state.play)  {
          this.load();
      }
    const {classes} = this.props;
      let link = this.props.file.link ? this.props.file.link.split('/').pop() : '';
    //console.log(this.state.data);
    return (
        <>  {this.state.play ?
            <>
                <PauseCircleFilledIcon className={classes.icon} onClick={(event) => {
                        this.setState({play: false});
                    }
                }/>
            </>
                :
            <>
                <PlayCircleFilledWhiteIcon className={classes.icon} onClick={(event) => {
                        this.load();
                        this.setState({loading: true, play: true, stop: false});
                    }
                } />
            </>
        }
        { this.state.loading ?
            <>
                <StopCircleIcon className={classes.icon} onClick={(event) => {
                        this.setState({loading: false, play: false, stop: true});
                    }
                }/>
            </>
                :
            <></>
        }
        <span className={classes.name} >
            {link}
        </span>{this.state.data?this._renderAudio():""}
        </>
    );
  }
}

export default withStyles(useStyles)(Audio);
