import {withStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";


export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#fff',
        color: "#00827c",
        borderBottom: 0,
    },
    body: {
        borderBottom: 0,
        fontSize: 14,
    },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
    root: {
        height: "36px",
        backgroundColor: '#fff',
        color: "#313436",
        borderRadius: "12px",
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: "rgba(244,250,250,0.6)"
        }
    },

    selected: {
        backgroundColor: "#eeaadd"
    }
}))(TableRow);

export const useStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    icon: {
        cursor: 'pointer',
        zIndex: 10,
        marginTop: -10,
    },
    fileinfo: {
        paddingRight: 10,
        '& label' : {
            fontFamily: 'Roboto',
            fontSize: 14,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.6,
            letterSpacing: 1,
            color: '#828d99',
            display: 'block',
            width: '100%',
            borderBottom: 'solid 1px #a1b3c6',
            paddingBottom: 5,
            marginBottom: 10
        }
    },
    find: {
        padding: '30px 0px 0px 10px'
    },
    findInput: {
        width: '100%',
        marginTop: 10,
        '& input' : {
            padding: 18
        }
    },
    findButton: {
        width: '90%',
        marginLeft: '5%',
        backgroundColor: '#81b346',
        marginTop: 10,
        color: 'white',
        lineHeight: '40px'
    },
    profileImage: {
        border: "1px solid #00978c",
        backgroundColor: "#fff",
        color: "#00978C",
        width: "100%",
        height: "100%",
        fontSize: 24,
        borderRadius: 5
    },
    profile: {
        backgroundColor: '#fff',
        color: '#828d99',
        padding: 0,
        width: "100%",
        // marginLeft: 5,
        lineHeight: '2em',
        fontSize: 14,
        '& h3': {
            fontSize: 18,
            color: '#304156'
        },
        '& span': {
            fontSize: 16,
            color: '#304156'
        },
        '& p': {
            fontSize: 16,
        },
        '& a': {
            color: '#3a7ecc'
        },
        '& a:hover': {
            textDecoration: 'underline'
        }
    },
    griditem: {
        padding: 0,
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    formControl: {
        margin: 0,
        minWidth: '90%',
        backgroudColor: 'white',
        fontFamily: '"Roboto", "Helvetica"'
    },
    formControlLast: {
        float: "right",
        minWidth: '90%',
    },
    formSort: {
        margin: "0",
        padding: "8px 22px 16px 16px",
        borderBottom: "solid #439bbb 1px",
        backgroundColor: "rgba(68, 156, 187, 0.04)",
        width: "100%"
    },
    selectSort: {
        backgroundColor: '#FFF',
        color: '#304156',
    },
    label: {
        color: "#828d99"
    },
    addpatientlink: {
        color: "#00827c",
        textTransform: "uppercase",
        fontWeight: 600,
        float: "right"
    },
    patientList: {
        position: "relative",
        height: "100%",
        backgroundColor: "#fff"
    },
    formControlSearch: {
        margin: 0,
        minWidth: '90%',
        backgroudColor: 'white',
        fontFamily: '"Roboto", "Helvetica"'
    },
    labelSearch:{
        margin: "8px 0",
        lineHeight: 1.71,
        letterSpacing: "normal",
        color: "#828d99",
        marginRight: "20px"
    },
    textSearch: {
        height: 40,
        margin: 0,
        padding: "9px 424px 7px 15px",
        borderRadius: 4,
        boxShadow: "inset 0 0 4px 0 rgba(0, 0, 0, 0.2)",
        backgroundImage: "linear-gradient(to bottom, #ffffff 5%, #e5e4e4 96%)",
        width: "70%"
    },
    buttonSearch: {
        width: "40px",
        height: "40px",
        margin: "0 0 0 16px",
        padding: "8px",
        borderRadius: "4px",
        backgroundImage: "linear-gradient(to bottom, #009691, #00827c 2%)",
        color: "#FFF",
        minWidth: 40
    },
    table: {
        boxShadow: 'none !important',
        backgroundColor: "#FFF"
    },
    cell:{
        borderColor: "#B7E8E4",
        padding: 10,
    },
    badge:{
        marginRight: "10px",
        '& span' : {
            height: 14,
            padding: '0 4px',
            fontSize: '0.55rem',
            minWidth: 14,
            borderRadius: 7,
            top: '-5px',
            right: '-5px'
        }
    },

    patientListPaper: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        height: '100%',
        color: theme.palette.text.primary,
        backgroundColor: "#ffffff",
        boxShadow: '8px 8px 16px rgba(0, 0, 0, 0.25)',
        borderRadius: '24px',
    },

    patientListButton: {
        minWidth: "216px",
        height: "56px",
        marginLeft: "16px",
        alignSelf: "baseline",
        borderRadius: "28px",
        boxShadow: "3px 3px 8px rgba(0, 151, 140, 0.25)",
        backgroundColor: "#00978C",
        color: "#fff",
        zIndex: "10",
        '&:hover': {
            backgroundColor: "#fff",
            color: "#00978C",
        },
    },

    patListSearchContainer: {
        display: "flex",
        marginBottom: "10px"
    },

    patientListSearch: {
        display: "flex",
        position: "relative",
        borderRadius: "28px",
        backgroundColor: "#fff",
        color: "#717d87",
        width: "100%",
        height: "56px",
        boxShadow: "0 1px 14px 0 rgba(0, 0, 0, 0.16)",
    },

    patientListSearchIcon: {
        color: "#717d87",
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    patientListBcspsIcon: {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        '&:hover': {
            cursor: "pointer",
            color: "#97000d",
        },
    },

    backspaceIconButton: {
        width: "56px",
        backgroundColor: "#eee",
        color: "#00978C",
        zIndex: "10",
        borderRadius: "28px",
        '&:hover': {
            color: "#fff",
            backgroundColor: "#015f5b",
        },
    },

    inputRoot: {
        borderRadius: "28px",
        color: "inherit"
    },


    inputFocus: {
        color: "#000"
    },

    inputInput: {
        height: "40px",
        alignSelf: "flex-start",
        padding: theme.spacing(1, 1, 1, 0),
        borderRadius:theme.shape.borderRadius,
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
    },

    inputAttach: {
        alignSelf: "flex-start",
        padding: theme.spacing(1, 1, 1, 2),
        borderRadius:theme.shape.borderRadius,
        transition: theme.transitions.create("width"),
        width: "100%",
    },

    tableRow: {
        backgroundColor: '#fff',
        borderRadius: "28px",
        cursor: "pointer",
    },

    tableRowSelected: {
        backgroundColor: "#f0f7f8",
        "&:hover": {
            backgroundColor: "#f0f7f8",
        },
    },

    patientListIcon: {
        backgroundColor: "rgba(0, 151, 140, 0.1)",
        padding: 0,
        marginLeft:"10px",
        color:"#00978C",
        '&:hover': {
            backgroundColor: "#00978C",
            color: "#fff"
        },
    },
    buttonenter: {
        cursor: 'pointer',
        boxShadow: 'none',
        height: 24,
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.5,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff',
        backgroundColor:'#2277b7'
    },
    tabList: {
        '& .PrivateTabIndicator-colorSecondary-100': {
            backgroundColor: '#117dce'
        }
    },
});

