import React, {Component} from "react";
import { useStyles } from './styles';
import {withStyles} from "@material-ui/core";
import { DataGrid, GridRowsProp, GridColDef, GridToolbar, GridFilterItem, GridFilterInputValue,useGridApiContext, useGridState } from '@mui/x-data-grid';
import {ruRU} from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import Client from '../../store/Model/Client';
import apiStore from "../../store/ApiStore";
import Waveform from '../../components/Waveform';
import Pagination from '@material-ui/lab/Pagination';
import TablePagination from '@material-ui/core/TablePagination';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {Trans} from "react-i18next";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { getClients } from '../../actions/clientActions'
import {loginUser} from "../../actions/userActions";
import {connect} from "react-redux";
import { UserContext } from "../../context/UserContext";
import SearcherField from "../shared/SearcherField/SearcherField";

const theme = createTheme(
	{
		palette: {
			primary: { main: '#1976d2' },
		},
		root: {
			border:0
		},
		'& .MuiDataGrid-row:nth-child(odd)': {
			backgroundColor: 'white'
		},
		cell: {
			border:0
		}
	},
	ruRU,
);
const filterEquals = {
	value: 'equals',
	getApplyFilterFn: (filterItem) => {
		if (filterItem.value === null || filterItem.value === '') {
			return null;
		}

		return ({ value }) => {
			/*if (typeof value === 'object') {
                return filterItem.value === (value as { value: any; label: string }).value;
            }*/
			return filterItem.value === value;
		};
	},
	InputComponent: GridFilterInputValue,
	InputComponentProps: { type: 'string' },
};
const filterSelect = {
	value: 'equals',
	getApplyFilterFn: (filterItem) => {
		if (filterItem.value === null || filterItem.value === '') {
			return null;
		}

		return ({ value }) => {
			/*if (typeof value === 'object') {
                return filterItem.value === (value as { value: any; label: string }).value;
            }*/
			return filterItem.value === value;
		};
	},
	InputComponent: GridFilterInputValue,
	InputComponentProps: { type: 'singleSelect' },
};

const BootstrapInput = withStyles((theme) => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
}))(InputBase);

class UserList extends Component {
	client = [];
	loading: false;

	state = {
		loading: true,
		rows: [],
		selectRow: null,
		totalRows: 0,
		rowsPerPageOptions: [10, 20, 50, 100],
		pageSize: 10,
		page: 1,
		arClients: [],
		selectUser: null,
		search: null,
		filter: {
			fio: null,
			count_verify: "any",
			count_enroll: "any",
			status: -1,
		},
		orderBy: null,
		sortDesc: true,
        error: {
            isErrorDisplayed: false,
            errorMessage: ''
        }
	};

    renderQuantityVerifyCell = (params) => {
      //  console.log("count_verify: ", params.row.count_verify);
      //  console.log("row: ", params.row);
    };
    renderQuantityEnrollCell = (params) => {
      //  console.log("count_enroll: ", params.row.count_enroll);
    };

	handleSearchChange = (event) => {
		let { name, value } = event.target;
		/* this.setState(  {
			value: value,
		})
		this.validate(name, value) */
		// console.log("handleSearchChange: ", value);
        this.setState({search:value, page: 0, orderBy: null, sortdesc: true});
		this.reloadList(value, null, null, null, null, null, null);
		// show first profile
		if (this.state.selectRow === null && this.state.arClients.length>0) {
			// console.log([this.state.arClients[0]['id']]);
			this.props.onSelectUser([this.state.arClients[0]['id']]);
		}
	};

    handleSearchKeyDown = (event) => {
        let { name, value } = event.target;
        // console.log('handleSearchKeyDown: ', value);
        if (event.key  === "Enter") {
            // console.log('handleSearchKeyDown: Enter');
            // request api with search
			this.setState({search:value});
			this.reloadList(value, null, null, null, null, null, null);
			// show first profile
			if (this.state.selectRow === null && this.state.arClients.length>0) {
				// console.log([this.state.arClients[0]['id']]);
				this.props.onSelectUser([this.state.arClients[0]['id']]);
			}
        }
    };

    handleSearchClick = () => {
        // console.log('handleSearchClick: ', this.state.search);
        // request api with search
		this.reloadList(this.state.search, null, null, null, null, null, null);
		// show first profile
		if (this.state.selectRow === null && this.state.arClients.length>0) {
			// console.log([this.state.arClients[0]['id']]);
			this.props.onSelectUser([this.state.arClients[0]['id']]);
		}
    };

	columns = [
		{
			field: "status", headerName: <Trans i18nKey={"Status"} >{"Status"}</Trans>, width: 110, GridColType: 'singleSelect', sortable: false, renderCell: (params) => {
				return (
					<div className="d-flex justify-content-between align-items-center" style={{cursor: "pointer"}}>
						<Chip index={params.row.id} className={params.row.active === true ? 'active' : 'deactive'} style={{width: 90}}
							  avatar={<Avatar>{params.row.active === true ? <CheckRoundedIcon/> :
								  <ClearRoundedIcon/>}</Avatar>} variant="outlined"
							  label={params.row.active === true ? <Trans i18nKey={"Active"} >{"Active"}</Trans> : <Trans i18nKey={"Blocked"} >{"Blocked"}</Trans>}/>
					</div>
				);
			},
			valueOptions: [
				'Активен',
				'Блокирован'
			],
			filterOperators: [filterSelect],
			renderHeader: (props) => {
				return  <>
					<span><Trans i18nKey={"Status"} >{"Status"}</Trans></span>
					<FormControl variant="standard" className={"input"}>
						<Select
							key="status"
							variant={"standard"}
							className={"underlineselect"}
							displayEmpty
							value={this.state.filter.status}
							IconComponent={ExpandMoreIcon}
							style={{width: 80}}
							onChange={
								event	=>
								{
									let filter = this.state.filter;
									filter['status'] = event.target.value;
									this.setState({filter:filter}, this.loadList);
								}
							}
							input={<BootstrapInput />}
						>
							<MenuItem value={-1} key="-1" ><Trans i18nKey={"All"}>{"All"}</Trans></MenuItem>
							<MenuItem value={0} key="0" ><Trans i18nKey={"Blocked"} >{"Blocked"}</Trans></MenuItem>
							<MenuItem value={1} key="1" ><Trans i18nKey={"Active"} >{"Active"}</Trans></MenuItem>
						</Select>
					</FormControl>
				</>
			}
		},
		{ field: "firstname", headerName: <Trans i18nKey={"FristName"} >{"FristName"}</Trans>, width: 120, hide: false, filterOperators: [filterEquals], sortable: false,
			renderHeader: (props) => {
				return  <>
					<span onClick={(event) => this.setState({orderBy: 'firstname', sortDesc: (this.state.orderBy === null ? true : !this.state.sortDesc)}, this.loadList)} ><Trans i18nKey={"FristName"} >{"FristName"}</Trans>
						{this.state.orderBy === 'firstname' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}</span>
					<div className={"input"}>
						<input type={"text"} name={"firstname"} id="firstname" placeholder=" " onChange={
							(event) => {
								let filter = this.state.filter;
								filter['firstname'] = event.target.value;
								this.setState({filter:filter}, this.loadList);
							}
						} />
						<label htmlFor="firstname"><Trans i18nKey={"Search"}>{"Search"}</Trans></label>
					</div>
				</>
			}
		},
		{ field: "middlename", headerName: <Trans i18nKey={"MiddelName"} >{"MiddelName"}</Trans>, width: 120, hide: false, filterOperators: [filterEquals], sortable: false,
			renderHeader: (props) => {
				return  <>
					<span  onClick={(event) => this.setState({orderBy: 'middlename', sortDesc: (this.state.orderBy === null ? true : !this.state.sortDesc)}, this.loadList)}  >
						<Trans i18nKey={"MiddelName"} >{"MiddelName"}</Trans>
						{this.state.orderBy === 'middlename' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
					<div className={"input"}>
						<input type={"text"} name={"middlename"} id="middlename" placeholder=" " onChange={
							(event) => {
								let filter = this.state.filter;
								filter['middlename'] = event.target.value;
								this.setState({filter:filter}, this.loadList);
							}
						} />
						<label htmlFor="middlename"><Trans i18nKey={"Search"}>{"Search"}</Trans></label>
					</div>
				</>
			}
		},
		{ field: "lastname", headerName: <Trans i18nKey={"LastName"} >{"LastName"}</Trans>, width: 120, hide: false, filterOperators: [filterEquals], sortable: false,
			renderHeader: (props) => {
				return  <>
					<span   onClick={(event) => this.setState({orderBy: 'lastname', sortDesc: (this.state.orderBy === null ? true : !this.state.sortDesc)}, this.loadList)}  >
						<Trans i18nKey={"LastName"} >{"LastName"}</Trans>
						{this.state.orderBy === 'lastname' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
					<div className={"input"}>
						<input type={"text"} name={"lastname"} id="lastname" placeholder=" " onChange={
							(event) => {
								let filter = this.state.filter;
								filter['lastname'] = event.target.value;
								this.setState({filter:filter}, this.loadList);
							}
						} />
						<label htmlFor="lastname"><Trans i18nKey={"Search"}>{"Search"}</Trans></label>
					</div>
				</>
			} },
		/*{ field: "fullName", headerName: <Trans i18nKey={"FIO"} >{"FIO"}</Trans>, width: 250, filterable: false, sortable: false,
			renderHeader: (props) => {
				return  <>
					<span><Trans i18nKey={"FIO"} >{"FIO"}</Trans></span>
					<div className={"input"}>
						<input type={"text"} name={"fio"} id="fio" placeholder=" " onChange={
							(event) => {
								let filter = this.state.filter;
								filter['fio'] = event.target.value;
								this.setState({filter:filter}, this.loadList);
							}
						} />
						<label htmlFor="fio"><Trans i18nKey={"DoesNotMatter"}>{"DoesNotMatter"}</Trans></label>
					</div>
				</>
			}
		},*/
		{ field: "count_verify", headerName: <Trans i18nKey={"QntAuthentication"} >{"QntAuthentication"}</Trans>, width: 105, align: 'center', renderCell: this.renderQuantityVerifyCell, filterOperators: [filterEquals], sortable: false,
			renderHeader: (props) => {
				return  <>
					<span onClick={(event) => this.setState({orderBy: 'count_verify', sortDesc: (this.state.orderBy == null ? true : !this.state.sortDesc)}, this.loadList)}  >
						<Trans i18nKey={"QntAuthentication"} >{"QntAuthentication"}</Trans>
						{this.state.orderBy === 'count_verify' ? (this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>) : ''}
					</span>
					<FormControl variant="standard" className={"input"}>
						<Select
							key="count_verify"
							variant={"standard"}
							className={"underlineselect"}
							style={{width: 75}}
							displayEmpty
							value={this.state.filter.count_verify}
							IconComponent={ExpandMoreIcon}
							onChange={ event => {
										let filter = this.state.filter;
										filter['count_verify'] = event.target.value;
										this.setState({filter:filter}, this.loadList);
									}
								}
								input={<BootstrapInput />}
								>
								<MenuItem value={"any"}  key="any" ><Trans i18nKey={"NoMatter"}>{"NoMatter"}</Trans></MenuItem>
								<MenuItem value={"no"}  key="no" ><Trans i18nKey={"QntNo"} >{"QntNo"}</Trans></MenuItem>
								<MenuItem value={"yes"}  key="yes" ><Trans i18nKey={"QntYes"} >{"QntYes"}</Trans></MenuItem>
						</Select>
					</FormControl></>
			}
		},
		{ field: "count_enroll",
			headerName: <Trans i18nKey={"QntEnroll"} >{"QntEnroll"}</Trans>, width: 105, align: 'center', renderCell: this.renderQuantityEnrollCell, filterOperators: [filterEquals], sortable: false,
			renderHeader: (props) => {
				return  <>
					<span  onClick={(event) => this.setState({orderBy: 'count_enroll', sortDesc: (this.state.orderBy === null ? true : !this.state.sortDesc)}, this.loadList)} >
						<Trans i18nKey={"QntEnroll"} >{"QntEnroll"}</Trans>
						{this.state.orderBy === 'count_enroll' ? <>{this.state.sortDesc ? <ArrowDownwardIcon/> : <ArrowUpwardIcon/>}</>: ''}
					</span>
					<FormControl variant="standard" className={"input"}>
						<Select
							key="count_enroll"
							variant={"standard"}
							className={"underlineselect"}
							style={{width: 75}}
							displayEmpty
							value={this.state.filter.count_enroll}
							IconComponent={ExpandMoreIcon}
							onChange={ event => {
								let filter = this.state.filter;
								filter['count_enroll'] = event.target.value;
								this.setState({filter:filter}, this.loadList);
							}
							}
							input={<BootstrapInput />}
						>
							<MenuItem value={"any"}  key="any" ><Trans i18nKey={"NoMatter"}>{"NoMatter"}</Trans></MenuItem>
							<MenuItem value={"no"}  key="no" ><Trans i18nKey={"QntNo"} >{"QntNo"}</Trans></MenuItem>
							<MenuItem value={"yes"}  key="yes" ><Trans i18nKey={"QntYes"} >{"QntYes"}</Trans></MenuItem>
						</Select>
					</FormControl>
				</>
			}
		},
		{ field: "user_u", hide: true, filterable: false, sortable: false }
	];

	login = '';
	password = '';


	customPagination = () => {

		return (<>
			<div style={{float: 'left'}} >
				<div  >
					<Pagination
						color="primary"
						variant="outlined"
						shape="rounded"
						count={Math.ceil(this.state.totalRows / this.state.pageSize)}
						page={this.state.page}
						onChange={(event, value) => {
							this.setState({page: value * 1 }, () => this.loadList())
						}}

					/>
				</div>
				<div style={{marginTop: '10px'}} >
					({(this.state.page-1) * this.state.pageSize}-{this.state.page*this.state.pageSize < this.state.totalRows?(this.state.page*this.state.pageSize):this.state.totalRows}) / {this.state.totalRows}
				</div>
			</div>
				<div>
					<div style={{color: '#828d99', float:'left', marginRight: 10}}><Trans i18nKey={"NumberRecords"} >{"NumberRecords"}</Trans><br/>
						<Trans i18nKey={"OnPage"} >{"OnPage"}</Trans>:</div>
					<Select
                        key="NumberRecords"
						style={{}}
						value={this.state.pageSize}
						onChange={event=>{ this.setState({page: 1, pageSize: event.target.value}, () => this.loadList())}}
						input={<BootstrapInput />}
					>
						{this.state.rowsPerPageOptions.map(val=><MenuItem value={val} key={"NumberRecords" + val}>{val}</MenuItem>)}
					</Select>
				</div>
			</>
		);
	};

	reloadList = async (search, lastname = null, middlename = null, firstname = null, status = null, was_verification = null, was_enroll = null) => {
		await apiStore.getQuantityProfiles().then(data => {
			this.setState({rowCount: data * 1, loading: true});
		});

		if(!status && this.state.filter.status !== null){
			// console.log("filter.status: ", this.state.filter.status);
			switch(this.state.filter.status) {
				case -1:// all
					status = '';
					break;
				case 0: // blocked
					status = 'f764ff2c-d7f8-11eb-90bf-e234ff0b86ac';
					break;
				case 1: // active
					status = 'b525b655-8647-11eb-8b37-8cec4bc83495';
					break;
                default:
                    status = '';
			}
		}

		search = search ?? this.state.search;
		lastname = lastname ?? this.state.filter.lastname;
		middlename = middlename ?? this.state.filter.middlename;
		firstname = firstname ?? this.state.filter.firstname;
		was_verification = was_verification ?? this.state.filter.count_verify;
		was_enroll = was_enroll ?? this.state.filter.count_enroll;

		let orderBy = this.state.orderBy ?? null;
		let sortDesc = this.state.sortDesc ?? null;

		let quantity = 0;

		let arClients = await this.props.getClients((this.state.page * 1 - (this.state.page>0?1:0)), this.state.pageSize, search, lastname , middlename , firstname , status, was_verification, was_enroll, orderBy, sortDesc).items.then(data => {
			quantity = data.meta?.quantity;
			return Array.isArray(data.data)?data.data:[];
		}).then(data => data.map((val, index) => { let client = new Client(val); return client.toArray()}));
		this.client = arClients;
		this.setState({arClients: arClients, loading: false, totalRows: quantity});
		// console.log("reloadList - end");
	};

    loadList = async (search = null, lastname = null, middlename = null, firstname = null, status = null, was_verification = null, was_enroll = null) => {
		await apiStore.getQuantityProfiles().then(data => {
			this.setState({rowCount: data * 1, loading: true});
		});

		if(!status && this.state.filter.status !== null){
		    // console.log("filter.status: ", this.state.filter.status);
		    switch(this.state.filter.status) {
                case -1:// all
                    status = '';
                    break;
                case 0: // blocked
                    status = 'f764ff2c-d7f8-11eb-90bf-e234ff0b86ac';
                    break;
                case 1: // active
                    status = 'b525b655-8647-11eb-8b37-8cec4bc83495';
                    break;
                default:
                    status = '';
            }
		}

		search = search ?? this.state.search;
		lastname = lastname ?? this.state.filter.lastname;
		middlename = middlename ?? this.state.filter.middlename;
		firstname = firstname ?? this.state.filter.firstname;
		was_verification = was_verification ?? this.state.filter.count_verify;
		was_enroll = was_enroll ?? this.state.filter.count_enroll;

		let orderBy = this.state.orderBy ?? null;
		let sortDesc = this.state.sortDesc ?? null;

		let quantity = 0;

		let arClients = await this.props.getClients((this.state.page * 1 - (this.state.page>0?1:0)), this.state.pageSize, search, lastname , middlename , firstname , status, was_verification, was_enroll, orderBy, sortDesc).items.then(data => {
			quantity = data.meta?.quantity;
			return Array.isArray(data.data)?data.data:[];
		}).then(data => data.map((val, index) => { let client = new Client(val); return client.toArray()}));
		this.client = arClients;
		 this.setState({arClients: arClients, loading: false, totalRows: quantity}, (state) => {
			 if (this.state.selectRow === null && this.state.arClients.length>0) {
				 //console.log([this.state.arClients[0]['id']]);
				 this.props.onSelectUser([this.state.arClients[0]['id']]);
			 }
		 });
		/* this.setState({arClients: arClients, loading: false, totalRows: quantity}, (state) => {
			 if (this.state.selectRow === null && this.state.arClients.length>0) {
			 	console.log([this.state.arClients[0]['id']]);
				 this.props.onSelectUser([this.state.arClients[0]['id']]);
			 }
		 });*/
	};

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
		if(nextProps.reload !== this.props.reload)
			this.reloadList();
	}

	componentDidMount() {
		this.loadList();
	}

	onFilterModelChange = (model) => {
		let lastname = null , middlename = null , firstname = null , status = null;
		for(let item of model.items){
			switch (item.columnField) {
				case 'firstname':
					firstname = item.value;
				break;
				case 'lastname':
					lastname = item.value;
				break;
				case 'middlename':
					middlename = item.value;
				break;
				case 'status':
					status = item.value === 'Active' || item.value === 'Активен' ? 'b525b655-8647-11eb-8b37-8cec4bc83495' : 'f764ff2c-d7f8-11eb-90bf-e234ff0b86ac';
				break;
				default:
			}
			//console.log("item: ", item);
		}

		this.loadList(lastname , middlename , firstname , status );
	};

	render() {
		const {classes} = this.props;
		let zoomLevel = 300;
		// console.log(this.state.arClients);
		return (
			<UserContext.Consumer>
				{({user, setAuthUser, setLang}) => <>
					<h2 style={{marginBottom: 20, marginLeft: 20}}><Trans i18nKey={"UserList"} >{"UserList"}</Trans></h2>
                    {/*<TextField name="search" label={<Trans i18nKey={"Search"} >{"Search"}</Trans>}
									   variant="outlined"
									   margin="normal"
									   fullWidth
									   className={classes.findInput}
									   style={{marginTop: 0, marginLeft: 10, width: '96%'}}
									   onChange={this.handleSearch}
							/>
					*/}
                    <SearcherField
                        name="searcher"
                        label={<Trans i18nKey={"Search"} >{"Search"}</Trans>}
                        style={{marginTop: 0, marginLeft: 10, width: '96%'}}
                        onChange={this.handleSearchChange}
                        onKeyDown={this.handleSearchKeyDown}
                        onClick={this.handleSearchClick}
                        isErrorDisplayed={this.state.error.isErrorDisplayed}
                        errorMessage={this.state.error.errorMessage}
                    />

						{''&&<Grid container spacing={0}  >
							<Grid item xs={12} md={9} lg={9} >
								<TextField id="outlined-basic" label={<Trans i18nKey={"Search"} >{"Search"}</Trans>} variant="outlined" className={classes.findInput} />
							</Grid>
							<Grid item xs={12} md={3} lg={3} >
								<Button size="large" className={classes.findButton} endIcon={<SearchIcon/>} >
									<Trans i18nKey={"Search"} >{"Search"}</Trans>
								</Button>
							</Grid>
						</Grid>}
						<div className={"woFilterHeader"} style={{ minHeight: 'calc(100vh - 127px)', width: "100%", marginTop: 20 }}>
							<DataGrid
								getRowId={(row) => row.id}
								className={"userList"}
								rows={this.state.arClients}
								disableDensitySelector
								columns={this.columns}
								filterMode={"server"}

								autoHeight

								filterPanelOperators={[]}
								components={{
									//Toolbar: GridToolbar,
									Pagination: this.customPagination
								}}
								pagination
								paginationMode="server"
								loading={this.state.loading}
								rowCount={this.state.rowCount}
								rowsPerPageOptions={this.state.rowsPerPageOptions}

								pageSize={this.state.pageSize}
								onFilterModelChange = {this.onFilterModelChange}
								onSortModelChange = {(model) => {
									console.log("model: ", model);
								}}
								onSelectionModelChange={(data) => {
									this.props.onSelectUser(data);
								}}

								onPageChange={(data) => {
									this.setState({page: data * 1 }, () => this.loadList());
								}}
								onPageSizeChange={(data) => {
									this.setState({page: 1, pageSize: data}, () => this.loadList());
								}}
							/>
						</div>

				</>}
			</UserContext.Consumer>
		);
	}
}

const mapStateToProps = state => {
	return state;
};
const mapDispatchToProps = {
	getClients
};

export default withStyles(useStyles)(connect(mapStateToProps, mapDispatchToProps)(UserList))
