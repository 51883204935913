import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import useStyles from './styles';
import {Trans, Translation} from "react-i18next";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

class BarChart extends Component{
    render() {
        let data = this.props.data;
        let all = 0;
        data = data.filter(val => val.name !== "quantity_allStat" && val.name !== "quantity_all");
        let active = data.filter(val => val.name === "total_active"
            || val.name === "quantity_success"
            || val.name === "quantity_accept"
            || val.name === "quantity_reject"
            || val.name === "quantity_allStat");
        if(active[0])
            active = active[0]["value"];
        let noactive = data.filter(val => val.name === "total_notactive" || val.name === "quantity_error" || val.name === "quantity_allStat");
        if(noactive[0])
            noactive = noactive[0]["value"];
        data.map(val => all += val.value);
        let iter = 0;

        const { classes } = this.props;
        return <div className={classes.root} >
            <h4 className={classes.h4} >{this.props.title}</h4>
            {this.props.selectPeriod ? <FormControl variant="outlined" className={classes.formControl}>
                <Select
                    labelId="Period-outlined-label"
                    id="Period-outlined"
                    value={this.props.period}
                    onChange={this.props.onChangePeriod}
                >
                    <MenuItem value={1}><Trans i18nKey={"_24hour"} >{"_24hour"}</Trans></MenuItem>
                    <MenuItem value={3}><Trans i18nKey={"_3day"} >{"_3day"}</Trans></MenuItem>
                    <MenuItem value={7}><Trans i18nKey={"_1week"} >{"_1week"}</Trans></MenuItem>
                    <MenuItem value={30}><Trans i18nKey={"_1month"} >{"_1month"}</Trans></MenuItem>
                    <MenuItem value={92}><Trans i18nKey={"_3month"} >{"_3month"}</Trans></MenuItem>
                    <MenuItem value={184}><Trans i18nKey={"_6month"} >{"_6month"}</Trans></MenuItem>
                    <MenuItem value={365}><Trans i18nKey={"_1year"} >{"_1year"}</Trans></MenuItem>
                </Select>
            </FormControl> : "" }
            <div className={classes.stat}>
                <div className={classes.statitem}>
                    <div className={classes.item1}><Trans i18nKey={"CommonStat"} >{"CommonStat"}</Trans>:</div>
                    <div className={classes.item2}>{new Intl.NumberFormat('ru-RU').format(all)}</div>
                    <div className={classes.item3}>
                        <span className={classes.bar} style={{width: all > 0 ? "100%" : "   0%"}}/>
                    </div>
                </div>
                {data.map(val => {
                    iter++;
                    //console.log("iter: ", iter);
                    //console.log("val.name: ", val.name);
                    return <div className={classes.statitem}>
                        <div className={classes.item1}><Trans i18nKey={val.name + "Stat"} >{val.name + "Stat"}</Trans>:</div>
                        <div className={classes.item2}>{new Intl.NumberFormat('ru-RU').format(val.value)}</div>
                        <div className={classes.item3}>
                            <span className={
                                val.name === "quantity_error" ? classes.bar_error :
                                val.name === "quantity_reject" ? classes.bar_reject :
                                val.name === "quantity_accept" ? classes.bar_accept :
                                val.name === "total_notactive" ? classes.bar_notactive : classes.bar}
                                  style={{width: (all > 0 ? Math.ceil(val.value / (all / 100)) : 0) + "%"}} />
                        </div>
                    </div>
                })}
            </div>
        </div>;
    }
}

export default withStyles(useStyles)(BarChart)
