import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import {Trans} from "react-i18next";

class TextFieldValidator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorMessage: "",
			isErrorDisplayed: false,
			value: props.initialValue,
		}
	}

	handleChange = (event) => {
		let { name, value } = event.target
		this.setState(  {
			value: value,
		})
		this.validate(name, value)
	}

	validate = (textFieldName, textFieldValue) => {
		let isErrorDisplayed = false, errorMessage = ""

		switch (textFieldName) {
			case "inputLastname":
				if (textFieldValue.length > 1 && textFieldValue.match(/^[а-яёА-ЯЁ]{2,100}$/)) {
					isErrorDisplayed = false; errorMessage = ""
				} else {
					isErrorDisplayed = true; errorMessage = "Фамилия не меньше 2 символов, используйте только буквы кириллицы"
				}
				break
			case "inputFirstname":
				if (textFieldValue.length > 1 && textFieldValue.match(/^[а-яёА-ЯЁ]{2,100}$/)) {
					isErrorDisplayed = false; errorMessage = ""
				} else {
					isErrorDisplayed = true; errorMessage = "Имя не меньше 2 символов, используйте только буквы кириллицы"
				}
				break
			case "inputMiddlename":
				if (textFieldValue.length > 1 && textFieldValue.match(/^[а-яёА-ЯЁ]{2,100}$/)) {
					isErrorDisplayed = false; errorMessage = ""
				} else {
					isErrorDisplayed = true; errorMessage = "Отчество не меньше 2 символов, используйте только буквы кириллицы"
				}
				break
			case "heightInput":
				if (Number(textFieldValue) < 251 && Number(textFieldValue) > -1  && textFieldValue.match(/[0-9]/)) {
					isErrorDisplayed = false; errorMessage = ""
				} else {
					isErrorDisplayed = true; errorMessage = "Введите целое число, Рост от 0 до 250 см"
				}
				break
			case "weightInput":
				if (Number(textFieldValue) < 301 && Number(textFieldValue) > -1  && textFieldValue.match(/[0-9]/)) {
					isErrorDisplayed = false; errorMessage = ""
				} else {
					isErrorDisplayed = true; errorMessage = "Введите целое число, Вес от 0 до 300 кг"
				}
				break
			case "inputLogin":
				if (textFieldValue.length > 3 && textFieldValue.match(/^[a-zA-Z0-9]{4,100}$/)) {
					isErrorDisplayed = false; errorMessage = ""
				} else {
					isErrorDisplayed = true; errorMessage = "Логин не меньше 4 символов, используйте только латинские буквы или цифры"
				}
				break
			case "inputPassword":
				if (textFieldValue.length > 4 && textFieldValue.match(/^[a-zA-Z0-9]{5,100}$/)) {
					isErrorDisplayed = false; errorMessage = ""
				} else {
					isErrorDisplayed = true; errorMessage = "Пароль не меньше 5 символов, используйте только латинские буквы и цифры"
				}
				break
			default:
				break
		}

		if (textFieldValue === "") {
			isErrorDisplayed = false
			errorMessage = ""
		}

		if (this.props.requiredValidation && textFieldValue === "") {
			isErrorDisplayed = true
			errorMessage = <Trans i18nKey={"ErrorMessageRequestField"} >{"ErrorMessageRequestField"}</Trans>
		}

		this.setState({
			isErrorDisplayed,
			errorMessage,
		})

		return this.props.validateChild(textFieldName, textFieldValue, isErrorDisplayed)
	}

	render() {
		if (this.props.requiredValidation) {
			this.validate(this.props.fieldName, this.state.value)
		}
		return (<TextField
					required={this.props.required}
					error={this.state.isErrorDisplayed}
					helperText={this.state.errorMessage}
					onChange={this.handleChange}
					value={this.state.value}
					style={{marginBottom: 14}}
					variant="outlined"
					id={this.props.fieldName}
					name={this.props.fieldName}
					label={this.props.fieldLabel}
					type={this.props.fieldType}
					fullWidth
				/>
		);
	}
}

export default TextFieldValidator;
